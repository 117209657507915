/*** 

====================================================================
						Template Header Style
====================================================================

 ***/

/** main-header **/

.main-header {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  left: 0px;
  top: 0px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/** main-menu **/

.main-menu {
  float: left;
}

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  position: inherit;
  margin: 0px;
}

.main-menu .navigation > li {
  position: inherit;
  float: left;
  z-index: 2;
  padding: 30px 0px 20px 0px;
  margin: 0px 25px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li:last-child {
  margin-right: 0px !important;
}

.main-menu .navigation > li:first-child {
  margin-left: 0px !important;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 17px;
  line-height: 30px;
  font-weight: 700;
  opacity: 1;
  font-family: 'Muli', sans-serif;
  color: #ffffff; /*Menu Color 1d165c*/

  padding: 0px;
  z-index: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation > li > a:after {
  position: absolute;
  /*content: "\f107";*/ /* Disabled the Down array */
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  right: -30px;
  top: 4px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  cursor: pointer;
  z-index: 5;
  transition: all 500ms ease;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a {
}

.main-menu .navigation > li > ul,
.main-menu .navigation > li > .megamenu {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 250px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0px;
  background-color: #fff;
  -moz-transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  padding: 0px 30px;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 13.5px 0px;
  line-height: 24px;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Muli', sans-serif;
  color: #1d165c;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > a i,
.main-menu .navigation > li > ul > li > ul > li > a i {
  margin-left: 0px;
  opacity: 0;
  font-size: 15px;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > a:hover i,
.main-menu .navigation > li > ul > li > ul > li > a:hover i {
  opacity: 1;
  margin-left: 10px;
}

.main-menu .navigation > li > ul > li:last-child > a {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a:hover {
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f105';
  position: absolute;
  right: 0px;
  top: 15px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  right: 100%;
  top: -2px;
  width: 230px;
  z-index: 100;
  display: none;
  padding: 10px 0px;
  background-color: #fff;
  -moz-transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li > ul > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
  padding: 0px 20px;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  font-size: 15px;
  padding: 10px 0px;
  line-height: 24px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Muli', sans-serif;
  text-transform: capitalize;
  color: #1d165c;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child > a {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a {
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation > li.dropdown:hover > ul,
.main-menu .navigation > li.dropdown:hover > .megamenu {
  visibility: visible;
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: -30px;
  top: 35px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  display: none;
  transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn {
}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn {
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.menu-area .mobile-nav-toggler .icon-bar {
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
  margin-bottom: 0px;
}

/** megamenu-style **/

.main-menu .navigation > li.dropdown > .megamenu {
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li {
  position: relative;
  display: block;
  z-index: 2;
  padding: 7px 0px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
  font-weight: 700;
  padding: 3px 0px;
}

.main-menu .navigation li.dropdown .megamenu li a {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 17px;
  line-height: 30px;
  font-weight: 700;
  opacity: 1;
  font-family: 'Muli', sans-serif;
  color: #1d165c;
  padding: 0px;
  z-index: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/** mobile-menu **/

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.7;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #1d165c;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .close-btn:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li ul li > a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li ul li > a i {
  display: none !important;
}

.mobile-menu .navigation li > a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  border-left: 5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.current > a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  background: #fff;
  color: #3b3b3b;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul,
.mobile-menu .navigation > li.dropdown > .megamenu {
  display: none;
}

.mobile-menu .social-links {
  position: relative;
  padding: 30px 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .social-links li a:hover {
}

div#mCSB_1_container {
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 80px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
  color: rgba(255, 255, 255, 0.8);
}

.mobile-menu .contact-info ul li a:hover {
}

.mobile-menu .contact-info ul li:last-child {
  margin-bottom: 0px;
}

/** stricky-header **/

.sticky-header .logo-box {
  position: relative;
  float: left;
  padding: 21px 0px 14px 0px !important;
  border: none !important;
}

.sticky-header .menu-area {
  position: relative;
  float: right;
}

.sticky-header .menu-area .btn-box {
  float: left;
  margin: 15px 0px 0px 50px;
}

.main-header.style-one .sticky-header .main-menu .navigation > li {
  padding: 21px 0px 20px 0px;
}

.main-header.style-two .sticky-header .menu-area .btn-box .theme-btn {
  padding: 7px 28px;
}

.main-header.style-two .sticky-header .main-menu .navigation > li {
  padding-top: 20px;
}

.main-header.style-two
  .sticky-header
  .main-menu
  .navigation
  > li.current
  > a:after,
.main-header.style-two
  .sticky-header
  .main-menu
  .navigation
  > li:hover
  > a:after {
}

.main-header.style-two .sticky-header .main-menu .navigation > li.current > a,
.main-header.style-two .sticky-header .main-menu .navigation > li:hover > a {
}

.main-header.style-three .sticky-header {
}

.main-header.style-three .sticky-header .outer-box {
  display: block;
  width: 100%;
  max-width: 1790px;
  padding: 0px 15px;
  margin: 0 auto;
}

.main-header.style-three .sticky-header .main-menu .navigation > li {
  padding-top: 24px;
}

.main-header.style-three .sticky-header .main-menu .navigation > li a {
  color: #fff;
}

.main-header.style-three
  .sticky-header
  .main-menu
  .navigation
  li.dropdown
  .megamenu
  li
  a {
  color: #1d165c;
}

.main-header.style-three
  .sticky-header
  .main-menu
  .navigation
  > li
  > ul
  > li
  > ul
  > li
  > a {
  color: #1d165c;
}

.main-header.style-three
  .sticky-header
  .main-menu
  .navigation
  > li
  > ul
  > li
  > a {
  color: #1d165c;
}

.main-header.style-three .sticky-header .logo-box {
  margin-right: 50px;
}

.main-header.style-three .sticky-header .search-box-outer {
  padding-top: 27px;
  padding-bottom: 19px;
}

.main-header.style-three .sticky-header .right-column {
  margin-top: 17px;
}

.main-header.style-four .sticky-header .main-menu .navigation > li {
  padding-top: 22px;
}

.main-header.style-four .sticky-header .menu-right-content {
  margin-top: 15px;
}

.main-header.style-four
  .sticky-header
  .main-menu
  .navigation
  > li.current
  > a:after,
.main-header.style-four
  .sticky-header
  .main-menu
  .navigation
  > li:hover
  > a:after {
}

.main-header.style-five .sticky-header .outer-box {
  position: relative;
  max-width: 1770px;
  padding: 0px 15px;
  width: 100%;
  margin: 0 auto;
}

.main-header.style-five .sticky-header .menu-right-content {
  position: relative;
  float: left;
  margin-top: 23px;
  margin-left: 310px;
}

.main-header.style-five .sticky-header .menu-right-content .phone {
  position: relative;
  float: left;
}

.main-header.style-five .sticky-header .menu-right-content .phone h4 {
  position: relative;
  margin: 0px;
  font-weight: 600;
}

.main-header.style-five .sticky-header .menu-right-content .phone h4 i {
  margin-right: 8px;
}

.main-header.style-five .sticky-header .search-box-outer .search-toggler {
  color: #1d165c;
}

.main-header.style-five .sticky-header .search-box-outer .search-btn:before {
  background: #e5e5e5;
}

.main-header.style-five .sticky-header .main-menu .navigation > li {
  padding-top: 22px;
}

/** header-style-one **/

.header-top {
  position: relative;
  display: block;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-top .support,
.header-top .social-links {
  position: relative;
  float: left;
}

.main-header .menu-area {
  position: relative;
}

.header-top .support {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-family: 'Josefin Sans', sans-serif;
  display: block;
}

.header-top .support i {
  margin-right: 25px;
  font-size: 18px;
}

.header-top .support a {
  color: #fff;
  margin-left: 10px;
}

.header-top .social-links {
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

.header-top .social-links:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 19px;
  left: 0px;
  top: 5px;
}

.header-top .social-links li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.header-top .social-links li:last-child {
  margin-right: 0px;
}

.header-top .social-links li a {
  font-size: 14px;
  color: #fff;
}

.header-top .social-links li a:hover {
}

.header-top .support a:hover {
}

.main-header .language {
  position: relative;
  float: left;
  z-index: 5;
  margin-top: 3px;
}

.main-header .language .lang-btn {
  position: relative;
  display: block;
  font-size: 12px;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  transition: all 500ms ease;
}

.main-header .language .lang-btn:hover {
}

.main-header .language .lang-btn .flag {
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

.main-header .language .lang-btn .txt {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.main-header .language .lang-btn .arrow {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
  font-size: 15px;
}

.main-header .language .lang-btn:before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 16px;
}

.main-header .language .lang-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 13px;
  width: 150px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.main-header .language:hover .lang-dropdown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.main-header .language .lang-dropdown li {
  position: relative;
  display: block;
  border-bottom: 1px solid #f0f0f0;
}

.main-header .language .lang-dropdown li:last-child {
  border: none;
}

.main-header .language .lang-dropdown li a {
  position: relative;
  display: block;
  padding: 5px 15px;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  text-align: left;
}

.main-header .language .lang-dropdown li a:hover {
  text-decoration: underline;
}

/** search pop-up style **/

.main-header .search-box-outer {
  position: relative;
  float: left;
}

.main-header .search-box-outer .search-btn {
  position: relative;
  padding-left: 21px;
  margin-left: 21px;
}

.main-header .search-box-outer .search-btn:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 19px;
  left: 0px;
  top: 5px;
}

.main-header .search-box-outer .search-toggler {
  position: relative;
  font-size: 16px;
  color: #fff;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  transition: all 500ms ease;
}

.main-header .search-box-outer .search-toggler:hover {
}

.main-header .search-box-outer .search-toggler i {
  position: relative;
  margin-right: 10px;
}

.search-popup {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.search-popup .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

.search-popup .close-search {
  position: absolute;
  right: 25px;
  top: 25px;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  transition: all 500ms ease;
}

.search-popup .close-search:hover {
}

.search-popup .search-form {
  position: relative;
  padding: 0px 15px 0px;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 100px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.search-popup .search-form fieldset {
  position: relative;
  border: 7px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
}

.search-popup .search-form fieldset input[type='search'] {
  position: relative;
  height: 70px;
  padding: 20px 250px 20px 30px;
  background: #ffffff;
  line-height: 30px;
  font-size: 24px;
  color: #233145;
  border-radius: 7px;
}

.search-popup .search-form fieldset input[type='submit'] {
  position: absolute;
  display: block;
  right: 0px;
  top: 0px;
  text-align: center;
  width: 220px;
  height: 70px;
  padding: 20px 10px 20px 10px;
  color: #ffffff !important;
  line-height: 30px;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 0px 7px 7px 0px;
}

.search-popup .search-form fieldset input[type='submit']:hover {
  transform: translateY(0px);
}

.search-popup h3 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-align: center;
}

.search-popup .recent-searches {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.search-popup .recent-searches li {
  display: inline-block;
  margin: 0px 10px 10px 0px;
}

.search-popup .recent-searches li a {
  display: block;
  line-height: 24px;
  border: 1px solid #ffffff;
  padding: 7px 15px;
  color: #ffffff;
  border-radius: 3px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/** header-upper **/

.header-upper .menu-area .btn-box {
  position: relative;
  float: left;
  margin-top: 25px;
  margin-left: 50px;
}

.header-upper .logo-box .logo {
  position: relative;
  padding-top: 25px;
}

/** header-style-two **/

.main-header.style-two .header-top {
  background: #040535;
  border-bottom: none;
  padding: 11px 0px;
}

.main-header.style-two .header-top .social-links:before {
  left: inherit;
  right: 0px;
}

.main-header.style-two .header-top .social-links {
  padding-right: 20px;
  margin-right: 13px;
  padding-left: 0px;
  margin-left: 0px;
}

.header-top .links {
  position: relative;
  float: left;
}

.header-top .links:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 19px;
  right: -15px;
  top: 5px;
}

.header-top .links li {
  position: relative;
  display: inline-block;
  margin: 0px 7px;
}

.header-top .links li a {
  color: #fff;
  font-family: 'Josefin Sans', sans-serif;
}

.main-header.style-two .header-top .links li a:hover {
}

.header-top .request-btn {
  position: relative;
  float: left;
  margin-left: 35px;
}

.header-top .request-btn i {
  position: relative;
  display: inline-block;
  font-size: 15px;
  margin-right: 10px;
}

.header-top .request-btn a {
  display: inline-block;
  color: #fff;
}

.main-header.style-two .header-top .support i {
  margin-right: 10px;
}

.main-header.style-two .search-box-outer .search-btn {
  padding-right: 20px;
  margin-right: 20px;
}

.main-header.style-two .search-box-outer .search-btn:after {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 19px;
  right: 0px;
  top: 5px;
}

.main-header.style-two .header-upper .menu-area .btn-box .theme-btn {
  padding: 7px 28px;
  border-radius: 5px;
}

.main-header.style-two .main-menu .navigation > li > a,
.main-header.style-two .main-menu .navigation > li > a:after {
  color: #040535;
}

.main-header.style-two .menu-area .mobile-nav-toggler .icon-bar {
  background: #040535;
}

/** header-style-three **/

.main-header.style-three .header-upper {
  display: block;
  width: 100%;
  max-width: 1790px;
  padding: 0px 15px;
  margin: 0 auto;
}

.main-header.style-three .header-upper .logo {
  margin-right: 130px;
  padding-top: 50px;
}

.main-header.style-three .main-menu .navigation > li {
  padding-top: 55px;
}

.main-header.style-three .main-menu .navigation li.dropdown .dropdown-btn {
  top: 60px;
}

.main-header.style-three .search-box-outer {
  padding: 59px 0px 20px 0px;
  margin-left: 18px;
}

.main-header.style-three .search-box-outer .search-btn:before {
  background: rgba(255, 255, 255, 0.4);
}

.main-header.style-three .search-box-outer .search-toggler:hover {
  color: #fff;
}

.main-header.style-three .right-column {
  position: relative;
  margin-top: 50px;
}

.main-header.style-three .right-column .btn-box,
.main-header.style-three .right-column .language,
.main-header.style-three .right-column .social-links {
  position: relative;
  float: left;
}

.main-header.style-three .right-column .btn-box {
  margin-right: 20px;
}

.main-header.style-three .right-column .language {
  margin: 0px;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 10px 16px 7px 17px;
  margin-right: 20px;
}

.main-header.style-three .language .lang-btn:hover {
  color: #fff;
}

.main-header.style-three .right-column .social-links li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
}

.main-header.style-three .right-column .social-links li:last-child {
  margin-right: 0px;
}

.main-header.style-three .right-column .social-links li a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.main-header.style-three .right-column .social-links li a:hover {
  background: #fff;
}

/** header-style-four **/

.main-header.style-four .header-top .request-btn a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #1d165c;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
}

.main-header.style-four .header-top .request-btn a:hover {
}

.main-header.style-four .header-top .request-btn i {
  font-size: 14px;
  font-weight: 400;
}

.main-header.style-four .search-box-outer .search-toggler {
  color: #1d165c;
}

.main-header.style-four .header-top .info-box li {
  position: relative;
  display: inline-block;
  padding: 0px 21px 0px 28px;
  margin-right: 15px;
}

.main-header.style-four .header-top .info-box li:before {
  position: absolute;
  content: '';
  background: #d5d5d5;
  width: 1px;
  height: 20px;
  top: 5px;
  right: 0px;
}

.main-header.style-four .header-top .info-box li:last-child:before {
  display: none;
}

.main-header.style-four .header-top .info-box li:last-child {
  margin-right: 0px;
}

.main-header.style-four .header-top .info-box li i {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 18px;
}

.main-header.style-four .header-top .info-box li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  font-weight: 600;
}

.main-header.style-four .header-top .info-box li a:hover {
}

.main-header.style-four .header-top {
  border-bottom: 1px solid #e5e5e5;
  padding: 11px 0px;
}

.main-header.style-four .search-box-outer .search-btn:before {
  background: #d5d5d5;
}

.main-header.style-four .search-box-outer .search-toggler i {
  color: #8d8d8d;
  transition: all 500ms ease;
}

.main-header.style-four .search-box-outer .search-toggler:hover i {
}

.main-header.style-four .search-box-outer .search-toggler:hover {
}

.main-header.style-four .main-menu .navigation > li > a {
  color: #1d165c;
}

.main-header.style-four .main-menu .navigation > li > a:after,
.main-header.style-five .main-menu .navigation > li > a:after {
  color: #1d165c;
}

.main-header.style-four .menu-right-content {
  position: relative;
  float: left;
  margin-top: 30px;
}

.main-header.style-four .menu-right-content .social-links {
  position: relative;
  float: left;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  margin-right: 20px;
  margin-left: 40px;
}

.main-header.style-four .menu-right-content .social-links li {
  position: relative;
  float: left;
}

.main-header.style-four .menu-right-content .social-links li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #8d8d8d;
  width: 50px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-right: 1px solid #e5e5e5;
}

.main-header.style-four .menu-right-content .social-links li a:hover {
}

.main-header.style-four .menu-right-content .social-links li:last-child a {
  border-right: none;
}

.main-header.style-four .language {
  margin-top: 0px;
}

.main-header.style-four .language .lang-btn {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
}

.main-header.style-four .language .lang-btn .flag {
  padding: 0px;
}

.main-header.style-four .main-menu .navigation > li {
  padding-top: 35px;
}

.main-header.style-four .main-menu .navigation li.dropdown .dropdown-btn {
  color: #1d165c;
  top: 40px;
}

.main-header.style-four .header-upper .logo-box .logo {
  padding-top: 30px;
}

/** header-style-five **/

.main-header.style-five .header-upper {
  position: relative;
  max-width: 1770px;
  padding: 0px 15px;
  width: 100%;
  margin: 0 auto;
}

.main-header.style-five .main-menu .navigation > li > a {
  color: #1d165c;
}

.main-header.style-five .main-menu .navigation li.dropdown .dropdown-btn {
  color: #1d165c;
}

.main-header.style-five .main-menu .navigation > li.current > a,
.main-header.style-five .main-menu .navigation > li:hover > a {
}

.main-header.style-five
  .main-menu
  .navigation
  li.current.dropdown
  .dropdown-btn,
.main-header.style-five .main-menu .navigation li:hover .dropdown-btn {
}

.main-header.style-five .header-upper .menu-right-content {
  position: relative;
  float: left;
  margin-top: 37px;
  margin-left: 310px;
}

.main-header.style-five .header-upper .menu-right-content .phone {
  position: relative;
  float: left;
}

.main-header.style-five .header-upper .menu-right-content .phone h4 {
  position: relative;
  color: #fff;
  margin: 0px;
  font-weight: 600;
}

.main-header.style-five .header-upper .menu-right-content .phone h4 a {
  color: #fff;
}

.main-header.style-five .header-upper .menu-right-content .phone h4 a:hover {
}

.main-header.style-five .header-upper .menu-right-content .phone h4 i {
  margin-right: 8px;
}

.main-header.style-five .search-box-outer .search-btn:before {
  height: 44px;
  top: -9px;
}

.main-header.style-five .search-box-outer .search-toggler {
  font-size: 22px;
}

.main-header.style-five .main-menu .navigation > li {
  padding-top: 35px;
}

.main-header.style-five .main-menu .navigation li.dropdown .dropdown-btn {
  top: 40px;
}

.main-header.style-five .logo-box .logo {
  padding-top: 30px;
}

.main-header.style-one .main-menu .navigation > li {
  margin: 0px 23px;
  padding: 25px 0px 20px 0px;
}

.main-header.style-one .header-upper .menu-area .btn-box {
  margin-left: 45px;
  margin-top: 20px;
}

.main-header.style-one .header-upper .menu-area .btn-box .theme-btn {
  padding: 7px 24px;
}

.main-header.style-one .logo-box {
  position: relative;
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none !important;
  padding: 35px 18.5px;
}

.main-header.style-one .logo-box.style-two {
  border: 1px solid #f6f6f6;
}

.main-header.home-onepage .main-menu .navigation > li > a:after {
  display: none !important;
}

.main-header.home-onepage .main-menu .navigation > li.dropdown > a:after {
  display: inline-block !important;
}
