/*** 

====================================================================
                          Css For Naxly
====================================================================

***/

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two/style-three/style-four/style-five
5. Main Slider/style-one/style-two/style-three/style-four/style-five
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/

/*** 

====================================================================
                          Home-DS
====================================================================

***/

/** banner-section **/

.banner-section {
  position: relative;
  display: block;
  background: #fff;
  padding: 210px 0px 30px 0px;
}

.banner-section .pattern-layer .pattern-1 {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 69%;
  height: 890px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-section .pattern-layer .pattern-2 {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 67%;
  height: 820px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-section .content-box {
  position: relative;
  display: block;
  margin-top: 130px;
}

.banner-section .content-box h1 {
  position: relative;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  margin-bottom: 24px;
}

.banner-section .content-box p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}

.banner-section .content-box .btn-box .theme-btn.style-one {
  padding: 9px 20px;
  border-radius: 3px;
}

.banner-section .content-box .btn-box .theme-btn.style-two {
  padding: 8px 15px 8px 35px;
  border-radius: 3px;
  margin-right: 16px;
  font-weight: 700;
  background: #f6f6f6;
}

.banner-section .content-box .btn-box .theme-btn.style-two:after {
  border-radius: 3px;
}

.slider-image-1 {
  position: relative;
  min-width: 800px;
  min-height: 750px;
}

.slider-image-1 .image {
  position: absolute;
}

.slider-image-1 .image-1 {
  right: 0px;
  top: 0px;
}

.slider-image-1 .image-2 {
  right: 160px;
  bottom: 20px;
}

.slider-image-1 .image-3 {
  right: 37px;
  bottom: -6px;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.slider-image-1 .image-4 {
  left: 230px;
  bottom: 10px;
}

.slider-image-1 .image-5 {
  left: 0px;
  bottom: -7px;
}

.slider-image-1 .image-6 {
  left: 157px;
  bottom: 143px;
  z-index: 1;
}

.slider-image-1 .image-7 {
  left: 65px;
  top: 235px;
}

.slider-image-1 .image-8 {
  left: 34px;
  top: 229px;
}

.slider-image-1 .image-9 {
  left: 83px;
  top: 187px;
}

.slider-image-1 .image-10 {
  left: 130px;
  top: 126px;
}

.slider-image-1 .image-11 {
  left: 185px;
  top: 78px;
}

.slider-image-1 .image-12 {
  left: 184px;
  top: 209px;
}

.slider-image-1 .image-13 {
  left: 66px;
  top: 52px;
}

.banner-section .icon-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.banner-section .icon-layer .icon {
  position: absolute;
}

.banner-section .icon-layer .icon-1 {
  width: 218px;
  height: 212px;
  left: 100px;
  top: 180px;
  background-repeat: no-repeat;
}

.banner-section .icon-layer .icon-2 {
  left: 130px;
  top: 55%;
  width: 78px;
  height: 75px;
  background-repeat: no-repeat;
}

.banner-section .icon-layer .icon-3 {
  left: 380px;
  bottom: 100px;
  width: 123px;
  height: 119px;
  background-repeat: no-repeat;
}

.banner-section .icon-layer .icon-4 {
  left: 45%;
  top: 60%;
  width: 78px;
  height: 75px;
  background-repeat: no-repeat;
}

/** service-section **/

.service-section {
  position: relative;
  padding: 40px 0px 70px 0px;
}

.service-block-one .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  text-align: center;
  padding: 46px 20px 54px 20px;
  border-radius: 5px;
  transition: all 900ms ease;
}

.service-block-one .inner-box:hover {
  box-shadow: 0px 20px 30px rgba(251, 81, 85, 0.23);
}

.service-block-one .inner-box:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 5px;
  transition: all 500ms ease;
}

.service-block-one .inner-box:hover:before {
  height: 100%;
  top: 0px;
}

.service-block-one .inner-box h4 {
  margin-bottom: 30px;
}

.service-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  margin-bottom: 33px;
}

.service-block-one .inner-box .icon-box i {
  position: relative;
  display: inline-block;
  font-size: 50px;
  background: #fff;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}

.service-block-one .inner-box:hover .icon-box i {
  box-shadow: none;
}

.service-block-one .inner-box .icon-box .border-layer {
  position: absolute;
  width: 120px;
  height: 120px;
  left: -10px;
  top: -10px;
  border: 2px solid #fff;
  border-radius: 50%;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%, 0 0);
  transition: all 500ms ease;
  -webkit-animation: service_hexagon 2s infinite linear;
  -moz-animation: service_hexagon 2s infinite linear;
  -o-animation: service_hexagon 2s infinite linear;
  animation: service_hexagon 2s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.service-block-one .inner-box:hover .icon-box .border-layer {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

.service-block-one .inner-box:hover .border-layer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0 0);
}

.service-block-one .inner-box .icon-box .border-layer:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 8px;
  height: 8px;
  left: 15px;
  bottom: 9px;
  border-radius: 50%;
}

.service-block-one .inner-box p {
  color: #666;
}

.service-block-one .inner-box:hover h4,
.service-block-one .inner-box:hover h4 a,
.service-block-one .inner-box:hover p {
  color: #fff;
}

.service-section .sec-title {
  margin-bottom: 10px;
}

.service-section .pattern-layer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 1525px;
  background-repeat: no-repeat;
  background-position: center;
}

/** about-section **/

.about-section {
  position: relative;
  padding-bottom: 120px;
}

#content_block_01 .content-box .text {
  margin-bottom: 25px;
}

#content_block_01 .content-box .list-item li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  padding-left: 20px;
  margin-bottom: 12px;
}

#content_block_01 .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

#content_block_01 .content-box .list-item li span {
  position: absolute;
  left: 0px;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#content_block_01 .content-box .list-item li span:before {
  position: absolute;
  content: '';
  width: 9px;
  height: 9px;
  left: -2px;
  top: -2px;
  border-radius: 50%;
}

#content_block_01 .content-box .list-item li span:after {
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  left: -4px;
  top: -4px;
  border-radius: 50%;
}

#content_block_01 .content-box .sec-title {
  margin-bottom: 33px;
}

.tabs-box .tab {
  position: relative;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
  display: block;
}

.tabs-box .tab {
  transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
  transform: scale(1) translateY(0px);
}

.about-section .inner-box .video-inner {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 158px 0px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-section .inner-box .video-inner a {
  position: relative;
  display: inline-block;
  font-size: 70px;
  color: #fff;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
}

.about-section .inner-box .video-inner a:after,
.about-section .inner-box .video-inner a:before {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.about-section .inner-box .video-inner a:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.about-section .inner-box {
  position: relative;
  margin-left: 30px;
  margin-top: 6px;
}

.about-section .tab-btn-box {
  position: relative;
  display: block;
}

.about-section .tab-btn-box .tab-btns {
  position: absolute;
  right: 35px;
  bottom: 5px;
  max-width: 670px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 20px #ededf1;
}

.about-section .tab-btn-box .tab-btns li {
  position: relative;
  float: left;
  width: 33.333%;
  padding: 30px 20px 30px 80px;
  cursor: pointer;
  transition: all 500ms ease;
}

.about-section .tab-btn-box .tab-btns li:before {
  position: absolute;
  content: '\f137';
  font-family: 'Flaticon';
  font-size: 26px;
  color: #b6b6b6;
  left: 35px;
  top: 32px;
  transition: all 500ms ease;
}

.about-section .tab-btn-box .tab-btns li.active-btn:before,
.about-section .tab-btn-box .tab-btns li:hover:before {
  content: '\f113';
}

.about-section .tab-btn-box .tab-btns li:after {
  position: absolute;
  content: '';
  width: calc(100% - 70px);
  height: 4px;
  left: 35px;
  bottom: 0px;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.about-section .tab-btn-box .tab-btns li.active-btn:after,
.about-section .tab-btn-box .tab-btns li:hover:after {
  transform: scale(1, 1);
}

.about-section .tab-btn-box .tab-btns li h4 {
  position: relative;
  font-weight: 600;
}

.about-section .tab-btn-box .tab-btns li h4:before {
  position: absolute;
  content: '';
  background: #e4e4e4;
  width: 1px;
  height: 45px;
  top: -10px;
  right: -20px;
}

.about-section .tab-btn-box .tab-btns li:last-child h4:before {
  display: none;
}

/** work-process **/

.work-process {
  position: relative;
  padding-bottom: 125px;
}

.work-block-one .inner-box {
  position: relative;
  display: block;
  text-align: center;
  padding: 0px 30px;
}

.work-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 153px;
  line-height: 153px;
  text-align: center;
  font-size: 60px;
  margin-bottom: 24px;
  transition: all 500ms ease;
}

.work-block-one .inner-box:hover .icon-box {
  color: #fff;
}

.work-block-one .inner-box .icon-box i {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.work-block-one .inner-box .icon-box:before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  box-shadow: 0 0px 100px 30px rgba(0, 0, 0, 0.4);
  transition: all 500ms ease;
}

.work-block-one .inner-box:hover .icon-box:before {
  box-shadow: 0 30px 70px 70px #fdb8ba;
}

.work-block-one .inner-box .icon-box .count {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 15px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #e5e5e5;
  transition: all 500ms ease;
}

.work-block-one .inner-box .icon-box .count:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0, 0);
  transition: all 500ms ease;
  z-index: -1;
}

.work-block-one .inner-box:hover .icon-box .count:before {
  transform: scale(1, 1);
}

.work-block-one .inner-box:hover .icon-box .count {
  color: #fff;
}

.work-block-one .inner-box .icon-box .bg-pattern {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 180px;
  height: 153px;
  background-repeat: no-repeat;
}

.work-block-one .inner-box .icon-box .overlay-pattern {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 180px;
  height: 153px;
  opacity: 0;
  transform: translateY(30px);
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.work-block-one .inner-box:hover .icon-box .overlay-pattern {
  opacity: 1;
  transform: translateY(0px);
}

.work-block-one .inner-box h4 {
  margin-bottom: 7px;
}

.work-process .sec-title {
  margin-bottom: 68px;
}

.work-process .work-block {
  position: relative;
}

.work-process .work-block:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  height: 1px;
  width: 150px;
  top: 50%;
  right: -70px;
  margin-top: -55px;
}

.work-process .work-block:last-child:before {
  display: none;
}

/** industries-section **/

.industries-section {
  position: relative;
  padding: 125px 0px 90px 0px;
}

.industries-section .pattern-layer .pattern-1 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.industries-section .pattern-layer .pattern-2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 463px;
  background-size: cover;
  background-repeat: repeat-x;
  animation: slide 100s linear infinite;
  -webkit-animation: slide 100s linear infinite;
}

.industries-section .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 35px 30px 30px 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  transition: all 500ms ease;
}

.industries-section .inner-box:hover {
  box-shadow: 0 20px 30px rgba(250, 59, 103, 0.4);
}

.industries-section .inner-box:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  border-radius: 5px;
  transition: all 500ms ease;
}

.industries-section .inner-box:hover:before {
  height: 100%;
  top: 0px;
}

.industries-section .inner-box .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 137px;
  background-repeat: no-repeat;
  opacity: 0.05;
  transition: all 500ms ease;
}

.industries-section .inner-box:hover .pattern-layer {
  opacity: 1;
}

.industries-section .inner-box span {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.industries-section .inner-box h4 {
  line-height: 28px;
  margin-bottom: 70px;
}

.industries-section .inner-box h4,
.industries-section .inner-box h4 a {
  color: #fff;
}

.industries-section .inner-box .btn-box {
  position: absolute;
  right: 30px;
  bottom: 60px;
}

.industries-section .inner-box .btn-box a {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.industries-section .inner-box:hover .btn-box a {
  background: #fff;
}

.industries-section .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0px;
  transition: all 500ms ease;
}

.industries-section .inner-box:hover .icon-box {
}

.industries-section .content-box .btn-box a {
  color: #fff;
  font-weight: 600;
}

.industries-section .content-box {
  position: relative;
  margin-bottom: 30px;
}

.industries-section .sec-title {
  margin-bottom: 42px;
}

/** skills-section **/

.skills-section {
  position: relative;
  padding: 125px 0px 120px 0px;
}

.skills-section .pattern-layer {
  position: absolute;
  right: 0px;
  bottom: 100px;
  width: 60px;
  height: 380px;
  background-repeat: no-repeat;
}

.skills-section .sec-title {
  margin-bottom: 33px;
}

.skills-section .image-box {
  position: relative;
  display: block;
  margin: 0px 20px 0px -120px;
}

.skills-section .image-box img {
  width: 100%;
}

#content_block_02 .content-box .text {
  margin-bottom: 43px;
}

#content_block_02 .content-box .inner-box .progress-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 53px;
}

#content_block_02 .content-box .inner-box .progress-box:last-child {
  margin-bottom: 0px;
}

#content_block_02 .content-box .inner-box {
  position: relative;
  padding-left: 80px;
  padding-right: 35px;
}

#content_block_02 .content-box .inner-box .progress-box .bar {
  position: relative;
  width: 100%;
  height: 2px;
  margin-left: 35px;
  background: #e5e5e5;
  border-radius: 0px;
  margin-bottom: 20px;
}

#content_block_02 .content-box .inner-box .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 2px;
  border-radius: 3px;
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

#content_block_02 .content-box .inner-box .progress-box .bar-inner:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: -5px;
  right: -5px;
}

#content_block_02 .content-box .inner-box .progress-box .count-text {
  position: absolute;
  left: -115px;
  top: -40px;
  width: 55px;
  height: 55px;
  font-family: 'Josefin Sans', Sans-serif;
  line-height: 55px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  opacity: 0;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

#content_block_02
  .content-box
  .inner-box
  .progress-box
  .bar-inner.counted
  .count-text {
  opacity: 1;
}

#content_block_02 .content-box .inner-box .progress-box .count-text:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 100%;
  border: 5px solid transparent;
}

#content_block_02 .content-box .inner-box .progress-box h5 {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 18px;
}

#content_block_02 .content-box .inner-box .progress-box .icon-box {
  position: absolute;
  left: 0px;
  bottom: -15px;
  font-size: 25px;
  line-height: 30px;
}

/** testimonial-section **/

.testimonial-section {
  position: relative;
  padding-bottom: 115px;
}

.testimonial-section .sec-title {
  max-width: 520px;
  width: 100%;
}

.testimonial-section .upper-box .btn-box {
  margin-top: 60px;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
  display: none !important;
}

.testimonial-section .testimonial-content {
  position: relative;
  padding-left: 360px;
  margin-bottom: 90px;
}

.testimonial-section .testimonial-content .image-box {
  position: absolute;
  left: 0px;
  bottom: -90px;
}

.testimonial-section .testimonial-content .inner-box {
  position: relative;
  padding-left: 140px;
}

.testimonial-section .testimonial-content .inner-box .icon-box {
  position: absolute;
  left: 0px;
  top: 55px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  box-shadow: 0 0 0 15px #fff;
}

.testimonial-section .testimonial-content .inner-box .inner .rating {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.testimonial-section .testimonial-content .inner-box .inner .rating li {
  position: relative;
  float: left;
  font-size: 20px;
  color: #f7d416;
  margin-right: 5px;
}

.testimonial-section
  .testimonial-content
  .inner-box
  .inner
  .rating
  li:last-child {
  margin-right: 0px;
}

.testimonial-section .testimonial-content .inner-box .inner .text p {
  font-size: 24px;
  line-height: 42px;
  font-family: 'Josefin Sans', Sans-serif;
  margin-bottom: 31px;
}

.testimonial-section .testimonial-content .inner-box .inner .author-info h4 {
  margin-bottom: 3px;
}

.testimonial-section
  .testimonial-content
  .inner-box
  .inner
  .author-info
  .designation {
  position: relative;
  display: block;
}

.testimonial-section .owl-nav {
  position: absolute;
  right: -10px;
  bottom: 30px;
}

.testimonial-section .owl-nav .owl-prev,
.testimonial-section .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: #c6c6c6;
  cursor: pointer;
  margin: 0px 10px;
  transition: all 500ms ease;
}

.testimonial-section .sec-title {
  margin-bottom: 0px;
}

.testimonial-section .pattern-layer .pattern-1 {
  position: absolute;
  top: 100px;
  right: 0px;
  width: 565px;
  height: 395px;
  background-repeat: no-repeat;
}

.testimonial-section .pattern-layer .pattern-2 {
  position: absolute;
  left: 0px;
  top: -80px;
  width: 815px;
  height: 1490px;
  background-repeat: no-repeat;
}

/** case-section **/

.case-section {
  position: relative;
}

.case-section .inner-container {
  position: relative;
  padding-bottom: 125px;
  border-bottom: 1px solid #e5e5e5;
}

.case-block-one .inner-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 20px 0px;
  border-radius: 5px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}

.case-block-one .inner-box:hover {
  box-shadow: 0 20px 50px #fdb8ba;
  transform: translateY(-10px);
}

.case-block-one .inner-box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.case-block-one .inner-box:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.case-block-one .inner-box .image-box {
  position: relative;
  display: block;
}

.case-block-one .inner-box .image-box img {
  width: 100%;
}

.case-block-one .inner-box .lower-content {
  padding: 8px 25px 1px 25px;
}

.case-block-one .inner-box .lower-content p {
  display: block;
  padding-bottom: 13px;
  border-bottom: 1px solid #e3e2f2;
  margin-bottom: 17px;
  transition: all 500ms ease;
}

.case-block-one .inner-box:hover .lower-content p {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.3);
}

.case-block-one .inner-box .lower-content h4 {
  line-height: 28px;
  margin: 0px;
}

.case-block-one .inner-box:hover .lower-content h4,
.case-block-one .inner-box:hover .lower-content h4 a {
  color: #fff;
}

.case-section .more-btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 43px;
}

.case-section .sec-title {
  margin-bottom: 60px;
}

/** fun-fact **/

.fun-fact {
  position: relative;
  padding: 105px 0px 125px 0px;
}

.counter-block-one .inner-box {
  position: relative;
  padding: 0px 0px 0px 85px;
}

.counter-block-one .inner-box .icon-box {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 60px;
  line-height: 60px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.counter-block-one .inner-box .count-outer {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 50px;
  font-family: 'Josefin Sans', Sans-serif;
  margin-bottom: 3px;
}

.counter-block-one .inner-box h4 {
  margin-bottom: 12px;
}

/** news-section **/

.news-section {
  position: relative;
  padding-bottom: 125px;
}

.news-section .title-inner {
  position: relative;
  padding: 125px 0px 163px 0px;
}

.news-block-one .inner-box {
  position: relative;
  display: block;
}

.news-block-one .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
}

.news-block-one .inner-box .image-box:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  top: 0px;
  right: 0px;
  opacity: 0.9;
  z-index: 1;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .image-box:before {
  width: 100%;
  left: 0px;
}

.news-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.news-block-one .inner-box .lower-content {
  position: relative;
  padding-top: 22px;
}

.news-block-one .inner-box .lower-content .file-box {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.news-block-one .inner-box .lower-content .file-box i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  margin-right: 10px;
}

.news-block-one .inner-box .lower-content .file-box p {
  position: relative;
  display: inline-block;
}

.news-block-one .inner-box .lower-content .title-box {
  position: relative;
  display: block;
  padding-left: 75px;
  margin-bottom: 21px;
  min-height: 55px;
}

.news-block-one .inner-box .lower-content .title-box .post-date {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 55px;
  height: 55px;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0px;
}

.news-block-one .inner-box .lower-content .title-box .post-date p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}

.news-block-one .inner-box .lower-content .title-box .post-date span {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
}

.news-block-one .inner-box .lower-content .title-box h4 {
  line-height: 28px;
}

.news-block-one .inner-box .lower-content .text {
  position: relative;
  margin-bottom: 21px;
}

.news-section .lower-content .inner-content {
  position: relative;
  margin-top: -125px;
}

.news-section .title-inner .pattern-layer .pattern-1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1335px;
  height: 100%;
  background-repeat: no-repeat;
}

.news-section .title-inner .pattern-layer .pattern-2 {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 1350px;
  height: 100%;
  background-repeat: no-repeat;
}

/** clients-section **/

.clients-section {
  position: relative;
  background: #23014f;
  padding: 125px 0px 190px 0px;
}

.clients-section .image-layer .image-1 {
  position: absolute;
  left: 50px;
  bottom: 70px;
}

.clients-section .image-layer .image-2 {
  position: absolute;
  right: 30px;
  top: -50px;
}

.clients-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.clients-section .title-inner h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}

.clients-section .title-inner p {
  color: rgba(255, 255, 255, 0.7);
}

.clients-section .title-inner {
  position: relative;
  margin-bottom: 50px;
}

.clients-section .clients-carousel {
  position: relative;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: none;
}

.clients-section .clients-carousel .logo-box {
  position: relative;
  border-right: none;
  height: 120px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  text-align: center;
  vertical-align: center;
  align-items: center;
  justify-content: center;
}

.clients-section .clients-carousel .logo-box img {
  display: block;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.clients-section .clients-carousel .logo-box img:hover {
  opacity: 0.5;
}

.clients-section .clients-carousel .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -73px;
}

.clients-section .owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  margin: 0px 8px;
  transition: all 500ms ease;
}

.clients-section .owl-theme .owl-dots .owl-dot.active span,
.clients-section .owl-theme .owl-dots .owl-dot span:hover {
  border-color: #fff;
}

/** main-footer **/

.main-footer {
  position: relative;
}

.main-footer .footer-top {
  position: relative;
  background: #18003a;
  padding: 100px 0px 95px 0px;
}

.main-footer .widget-section .widget-title {
  position: relative;
  margin-bottom: 39px;
}

.main-footer .widget-section .widget-title h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  padding-bottom: 20px;
}

.main-footer .widget-section .widget-title .decor {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 57px;
  height: 9px;
  background-repeat: no-repeat;
}

.main-footer .widget-section .contact-widget .widget-content .box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px;
}

.main-footer .widget-section .contact-widget .widget-content .box p {
  color: rgba(255, 255, 255, 0.6);
}

.main-footer .widget-section .contact-widget .widget-content .box {
  position: relative;
  margin-bottom: 21px;
}

.main-footer .widget-section .contact-widget .widget-content .box:last-child {
  margin-bottom: 0px;
}

.main-footer .widget-section .contact-widget .widget-content .box li {
  position: relative;
  display: block;
  padding-left: 30px;
  margin-bottom: 4px;
}

.main-footer
  .widget-section
  .contact-widget
  .widget-content
  .box
  li:last-child {
  margin-bottom: 0px;
}

.main-footer .widget-section .contact-widget .widget-content .box li i {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 16px;
  color: #fff;
}

.main-footer .widget-section .contact-widget .widget-content .box li a {
  color: rgba(255, 255, 255, 0.6);
}

.main-footer .widget-section .contact-widget .widget-content .box li a:hover {
  color: #fff;
}

.main-footer .widget-section .links-widget .widget-content ul li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.main-footer .widget-section .links-widget .widget-content ul li:last-child {
  margin-bottom: 0px;
}

.main-footer .widget-section .links-widget .widget-content ul li a {
  position: relative;
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
}

.main-footer .widget-section .links-widget .widget-content ul li a:hover {
  color: #fff;
  padding-left: 20px;
}

.main-footer .widget-section .links-widget .widget-content ul li a:before {
  position: absolute;
  content: '';
  width: 9px;
  height: 9px;
  left: 0px;
  top: 9px;
  border-radius: 50%;
  opacity: 0;
  transition: all 500ms ease;
}

.main-footer
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a:hover:before {
  opacity: 1;
}

.main-footer .widget-section .links-widget .widget-title {
  margin-bottom: 34px;
}

.main-footer
  .widget-section
  .links-widget
  .widget-content
  .column:first-child
  .links {
  margin-right: -10px;
}

.main-footer
  .widget-section
  .links-widget
  .widget-content
  .column:last-child
  .links {
  margin-left: 15px;
}

.main-footer .widget-section .about-widget .widget-content .box {
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
}

.main-footer .widget-section .about-widget .widget-content .box .logo {
  position: absolute;
  left: 0px;
  top: 6px;
  padding: 12px 25px 8px 0px;
}

.main-footer .widget-section .about-widget .widget-content .box .logo:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.1);
  width: 1px;
  height: 100%;
  top: 0px;
  right: 0px;
}

.main-footer .widget-section .about-widget .widget-content .box .text p {
  color: rgba(255, 255, 255, 0.6);
}

.main-footer .widget-section .about-widget .widget-title {
  margin-bottom: 36px;
}

.main-footer
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group {
  position: relative;
  margin: 0px;
}

.main-footer
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  input[type='email'] {
  position: relative;
  width: calc(100% - 190px);
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1e2332;
}

.main-footer
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  input:focus {
}

.main-footer
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px 42px;
}

.main-footer
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.main-footer .footer-bottom.style-one {
  position: relative;
  background: rgb(255, 255, 255);
  padding: 22px 0px;
}

.main-footer .footer-bottom.style-one .copyright p,
.main-footer .footer-bottom.style-one .copyright p a,
.main-footer .footer-bottom.style-one .footer-nav li a {
  color: #666666;
}

.footer-bottom .copyright p,
.footer-bottom .copyright p a,
.footer-bottom .footer-nav li a {
  color: rgba(255, 255, 255, 0.6);
}

.footer-bottom .copyright p a:hover,
.footer-bottom .footer-nav li a:hover {
  color: #fff;
}

.footer-bottom .footer-nav li {
  position: relative;
  display: inline-block;
  padding-right: 13px;
  margin-right: 6px;
}

.main-footer .footer-bottom.style-one .footer-nav li:before {
  background: #666;
}

.footer-bottom .footer-nav li:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.6);
  width: 3px;
  height: 1px;
  top: 14px;
  right: 0px;
}

.footer-bottom .footer-nav li:last-child:before {
  display: none;
}

.footer-bottom .footer-nav li:last-child {
  padding: 0px;
  margin: 0px;
}

/*** 

====================================================================
                          Home-AL
====================================================================

 ***/

/** banner-style-two **/

.banner-style-two {
  position: relative;
  padding: 95px 0px 0px 0px;
  overflow: hidden;
  background: #fff;
}

.banner-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 442px;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.banner-style-two .content-box {
  position: relative;
  margin-right: 90px;
  margin-top: 200px;
}

.banner-style-two .content-box h1 {
  position: relative;
  font-size: 72px;
  line-height: 85px;
  color: #040535;
  font-weight: 600;
  margin-bottom: 8px;
}

.banner-style-two .content-box p {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 42px;
}

.banner-style-two .image-box {
  position: relative;
  margin: 0px -50px 0px -50px;
}

.banner-style-two .image-box img {
  width: 100%;
}

.banner-style-two .image-box .image-1 {
  position: relative;
  top: 20px;
  -webkit-animation: service_hexagon_2 15s infinite linear;
  -moz-animation: service_hexagon_2 15s infinite linear;
  -o-animation: service_hexagon_2 15s infinite linear;
  animation: service_hexagon_2 15s infinite linear;
}

.banner-style-two .image-box .image-2 {
  position: absolute;
  left: -60px;
  bottom: 132px;
}

.banner-style-two .image-box .image-3 {
  position: absolute;
  left: 210px;
  bottom: 325px;
}

.banner-style-two .image-box .image-4 {
  position: absolute;
  top: 50px;
  right: 210px;
}

.banner-style-two .image-box .image-5 {
  position: absolute;
  top: 130px;
  right: -50px;
}

.banner-style-two .image-box .anim-icon .icon {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.banner-style-two .image-box .anim-icon .icon-4,
.banner-style-two .image-box .anim-icon .icon-7,
.banner-style-two .image-box .anim-icon .icon-3 {
  width: 15px;
  height: 15px;
}

.subscribe-us {
  position: relative;
}

.subscribe-us.home-boxed {
  background: #fff;
}

.subscribe-us .subscribe-inner {
  position: relative;
  margin-top: -50px;
}

.subscribe-us.home-boxed .subscribe-inner {
  top: -50px;
}

.subscribe-us .subscribe-inner .form-group {
  position: relative;
  margin: 0px;
  max-width: 615px;
  width: 100%;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.subscribe-us .subscribe-inner .form-group input[type='email'] {
  position: relative;
  max-width: 350px;
  width: 350px;
  height: 68px;
  background: #fff;
  font-size: 16px;
  padding: 10px 20px;
  transition: all 500ms ease;
}

.subscribe-us .subscribe-inner .form-group button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Josefin Sans', Sans-serif;
  font-weight: 600;
  color: #040535;
  background: transparent;
  padding: 20px 35px;
  cursor: pointer;
  transition: all 500ms ease;
}

.subscribe-us .subscribe-inner .form-group button:hover {
}

.subscribe-us .subscribe-inner .form-group button i {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.subscribe-us .subscribe-inner .form-group button:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 90px;
  left: -25px;
  top: -10px;
  transform: rotate(25deg);
  box-shadow: 5px 0 0 2px rgba(76, 207, 153, 0.5);
}

/** about-style-two **/

.about-style-two {
  position: relative;
  background: #fff;
  padding: 130px 0px 80px 0px;
}

#content_block_03 .content-box .text {
  position: relative;
  padding-bottom: 43px;
  margin-bottom: 39px;
  border-bottom: 1px solid #e5e5e5;
}

#content_block_03 .content-box .lower-box {
  position: relative;
  padding: 0px 0px 0px 85px;
}

#content_block_03 .content-box .lower-box .icon-box {
  position: absolute;
  left: 0px;
  top: -6px;
  width: 63px;
  height: 63px;
  line-height: 63px;
  text-align: center;
}

#content_block_03 .content-box .lower-box .icon-box i {
  position: relative;
  font-size: 30px;
  line-height: 63px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#content_block_03 .content-box .lower-box .icon-box .bg-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 63px;
  height: 63px;
  background-repeat: no-repeat;
}

#content_block_03 .content-box .lower-box h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  color: #040535;
  font-weight: 700;
  margin-bottom: 2px;
}

#content_block_03 .content-box .lower-box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', Sans-serif;
  font-weight: 600;
}

#content_block_03 .content-box .lower-box a:hover {
  text-decoration: underline;
}

#content_block_03 .content-box .lower-box a i {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

#content_block_03 .content-box .sec-title {
  margin-bottom: 32px;
}

#image_block_01 .image-box {
  position: relative;
  display: block;
  margin: 20px 55px 0px 0px;
}

#image_block_01 .image-box img {
  position: relative;
  width: 100%;
}

#image_block_01 .image-box .pattern-layer {
  position: absolute;
  left: -65px;
  top: -60px;
  width: 574px;
  height: 505px;
  background-repeat: no-repeat;
}

#content_block_03 .content-box {
  position: relative;
  z-index: 1;
}

/** service-style-two **/

.service-style-two {
  position: relative;
  background: #fff;
  padding: 125px 0px;
}

.service-style-two .sec-title {
  margin-bottom: 60px;
}

.service-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.service-style-two .image-layer {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.service-block-two .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  text-align: center;
  border: 1px solid #292d55;
  border-radius: 10px;
  padding-top: 146px;
  transition: all 500ms ease;
}

.service-block-two .inner-box:before {
  position: absolute;
  content: '';
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 0%;
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.service-block-two .inner-box:hover:before {
  height: 100%;
  top: 0px;
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.service-block-two .inner-box .text-layer {
  position: absolute;
  top: 30px;
  right: -8px;
}

.service-block-two .inner-box .text-layer h2 {
  position: relative;
  display: block;
  font-size: 100px;
  line-height: 100px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 300ms ease;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.05),
    1px -1px 0 rgba(255, 255, 255, 0.05), -1px 1px 0 rgba(255, 255, 255, 0.05),
    1px 1px 0 rgba(255, 255, 255, 0.05);
}

.service-block-two .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 80px;
  line-height: 80px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 26px;
}

.service-block-two .inner-box h4 {
  margin-bottom: 7px;
}

.service-block-two .inner-box h4,
.service-block-two .inner-box h4 a {
  color: #fff;
}

.service-block-two .inner-box h4 a:hover {
}

.service-block-two .inner-box p {
  color: #fff;
  margin-bottom: 33px;
  padding: 0px 30px;
}

.service-block-two .inner-box .link {
  position: relative;
  display: block;
  border-top: 1px solid #292d55;
  padding: 16px 30px;
}

.service-block-two .inner-box .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Josefin Sans', Sans-serif;
  color: #fff;
  font-weight: 600;
}

.service-block-two .inner-box .link a i {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.service-style-two .owl-nav {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 35px;
}

.nav-style-one .owl-nav .owl-prev {
  width: 55px;
  height: 30px;
  position: relative;
  display: inline-block;
  background: transparent;
  top: 0;
  left: 0;
  margin: 0px 10px;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-prev:before {
  left: auto;
  content: '';
  position: absolute;
  top: 17px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-bottom: 1px solid #9a9aab;
  border-left: 1px solid #9a9aab;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-prev:after {
  content: '';
  position: absolute;
  top: 23px;
  left: 14px;
  width: 40px;
  height: 1px;
  background: #424266;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-prev:hover:after {
  left: 3px;
}

.nav-style-one .owl-nav .owl-next {
  width: 55px;
  height: 30px;
  position: relative;
  display: inline-block;
  background: transparent;
  top: 0;
  left: 0;
  margin: 0px 10px;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-next:before {
  left: auto;
  content: '';
  position: absolute;
  top: 17px;
  right: 5px;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #9a9aab;
  border-right: 1px solid #9a9aab;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-next:after {
  content: '';
  position: absolute;
  top: 23px;
  right: 14px;
  width: 40px;
  height: 1px;
  background: #424266;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav .owl-next:hover:after {
  right: 3px;
}

.nav-style-one .owl-nav .owl-next:hover:after {
}

.nav-style-one .owl-nav .owl-next:hover:before {
}

.nav-style-one .owl-nav .owl-prev:hover:after {
}

.nav-style-one .owl-nav .owl-prev:hover:before {
}

/** case-style-two **/

.case-style-two {
  position: relative;
  padding: 125px 0px;
  background: #fff;
}

.case-style-two .top-inner {
  position: relative;
  margin-bottom: 40px;
}

.case-style-two .top-inner .btn-box {
  position: relative;
  margin-top: 60px;
}

.case-style-two .top-inner .btn-box .theme-btn {
  padding: 11px 38px;
}

.case-block-two .inner-box {
  position: relative;
  display: block;
}

.case-block-two .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 3px;
}

.case-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 3px;
}

.case-block-two .inner-box .image-box .client-box {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  transition: all 500ms ease;
}

.case-block-two .inner-box .image-box .client-box .client-logo {
  position: relative;
  display: inline-block;
  background: #fff;
  width: 72px;
  height: 72px;
  text-align: center;
  border-radius: 50%;
  padding: 1px; /* was 15px*/
  cursor: pointer;
}

.case-block-two .inner-box .image-box .client-box span {
  position: absolute;
  top: 16px;
  right: 70px;
  width: 128px;
  display: inline-block;
  background: #fff;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Josefin Sans', Sans-serif;
  padding: 7px;
  text-align: center;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.case-block-two .inner-box .image-box .client-box:hover span {
  right: 90px;
  opacity: 1;
  visibility: visible;
}

.case-block-two .inner-box .image-box .client-box span:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 12px;
  height: 12px;
  top: 14px;
  right: -5px;
  transform: rotate(45deg);
}

.case-block-two .inner-box .image-box .client-box .client-logo img {
  width: auto;
  border-radius: 50%;
}

.case-block-two .inner-box .image-box .link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.case-block-two .inner-box .image-box .link a {
  position: relative;
  display: inline-block;
  font-size: 35px;
  line-height: 35px;
  color: #fff;
  transform: scale(0, 0);
}

.case-block-two .inner-box:hover .image-box .link a {
  transform: scale(1, 1);
}

.case-block-two .inner-box .image-box .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.case-block-two .inner-box:hover .image-box .overlay-layer {
  transform: scale(1, 1);
}

.case-block-two .inner-box .lower-content {
  position: relative;
  padding-top: 22px;
}

.case-block-two .inner-box .lower-content .box {
  position: relative;
  padding-left: 70px;
}

.case-block-two .inner-box .lower-content .box .icon-box {
  position: absolute;
  left: 0px;
  top: 8px;
  font-size: 50px;
  line-height: 50px;
  color: #d0d0d0;
}

.case-block-two .inner-box .lower-content .box p {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 6px;
}

.case-style-two .owl-dots {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 33px;
}

.case-style-two .owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: transparent;
  background: #aeaeae;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 8px;
  transition: all 500ms ease;
}

.case-style-two .owl-theme .owl-dots .owl-dot span:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: all 500ms ease;
}

/** process-style-two **/

.process-style-two {
  position: relative;
  padding: 125px 0px 130px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.process-style-two:before {
  position: absolute;
  content: '';
  background: #f6f6f6;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  opacity: 0.85;
}

.process-style-two .sec-title {
  margin-bottom: 60px;
}

.work-block-two .inner-box {
  position: relative;
  display: block;
  text-align: center;
}

.work-block-two .inner-box .arrow-box {
  position: absolute;
  top: 32px;
  right: -104px;
  width: 194px;
  height: 56px;
  background-repeat: no-repeat;
}

.work-block-two .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 40px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 52px;
}

.work-block-two .inner-box .icon-box i {
  position: relative;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #fff, #fff 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: all 500ms ease;
}

.work-block-two .inner-box:hover .icon-box i {
}

.work-block-two .inner-box .icon-box:before {
  position: absolute;
  content: '';
  background: #f6f6f6;
  width: 76px;
  height: 76px;
  left: 2px;
  top: 2px;
  opacity: 0;
  transition: all 500ms ease;
  border-radius: 50%;
}

.work-block-two .inner-box:hover .icon-box:before {
  opacity: 1;
}

.work-block-two .inner-box .icon-box:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border: 2px dotted #f6f6f6;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  opacity: 0;
  transition: all 500ms ease;
}

.work-block-two .inner-box:hover .icon-box:after {
  opacity: 1;
}

.work-block-two .inner-box .icon-box .border-line {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  bottom: -58px;
  width: 4px;
  height: 32px;
  background-repeat: no-repeat;
}

.work-block-two .inner-box .count-box {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 65px;
}

.work-block-two .inner-box .count-box:before {
  position: absolute;
  content: '';
  border: 9px solid #fff;
  width: 58px;
  height: 58px;
  left: 1px;
  top: 1px;
  border-radius: 50%;
}

.work-block-two .inner-box .count-box span {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-family: 'Muli', Sans-serif;
  font-weight: 700;
  color: #fff;
  border-radius: 50%;
}

.work-block-two .inner-box .content-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 47px 30px 43px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.work-block-two .inner-box .content-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 70px;
  height: 70px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  top: -10px;
}

.work-block-two .inner-box .content-box h4 {
  margin-bottom: 12px;
}

.process-style-two .inner-content {
  position: relative;
}

.process-style-two .inner-content .line {
  position: absolute;
  left: 0px;
  top: 157px;
  width: 100%;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

.process-style-two .more-btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 60px;
}

.process-style-two .more-btn .theme-btn {
  padding: 11px 36px;
}

/** chooseus-section **/

.chooseus-section {
  position: relative;
  background: #fff;
  padding: 125px 0px 130px 0px;
}

.chooseus-section .sec-title {
  margin-bottom: 60px;
}

#video_block_01 .video-inner {
  position: relative;
  width: 100%;
  padding: 175px 0px 130px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

#video_block_01 .video-inner .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

#video_block_01 .video-inner .video-btn {
  position: relative;
  display: inline-block;
  width: 116px;
  height: 115px;
  line-height: 115px;
  text-align: center;
  margin-left: 40px;
  border-radius: 50%;
}

#video_block_01 .video-inner .video-btn .btn-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 116px;
  height: 115px;
  background-repeat: no-repeat;
}

#video_block_01 .video-inner .video-btn a {
  position: relative;
  display: inline-block;
  width: 116px;
  height: 115px;
  border-radius: 50%;
  font-size: 24px;
  z-index: 1;
}

#video_block_01 .video-inner .video-btn a i {
  position: relative;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#content_block_04 .content-box .single-item {
  position: relative;
}

#content_block_04 .content-box .single-item:first-child {
  padding-left: 190px;
  margin-bottom: 67px;
}

#content_block_04 .content-box .single-item:last-child {
  padding-right: 190px;
}

#content_block_04 .content-box .single-item .icon-box {
  position: absolute;
  top: 14px;
  width: 158px;
  height: 158px;
  line-height: 158px;
  text-align: center;
  font-size: 70px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

#content_block_04 .content-box .single-item .icon-box:before {
  position: absolute;
  content: '';
  background: #f6f6f6;
  width: 138px;
  height: 100%;
  left: 10px;
  border-radius: 10px;
}

#content_block_04 .content-box .single-item:first-child .icon-box:before {
  top: 10px;
}

#content_block_04 .content-box .single-item:last-child .icon-box:before {
  top: -10px;
}

#content_block_04 .content-box .single-item .icon-box i {
  position: relative;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#content_block_04 .content-box .single-item .icon-box .bg-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 158px;
  height: 158px;
  background-repeat: no-repeat;
}

#content_block_04 .content-box .single-item:first-child .icon-box {
  left: 0px;
}

#content_block_04 .content-box .single-item:last-child .icon-box {
  right: 0px;
}

#content_block_04 .content-box .single-item .box h4 {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 27px;
}

#content_block_04 .content-box .single-item .box h4:before {
  position: absolute;
  content: '';
  width: 55px;
  height: 2px;
  bottom: 0px;
}

#content_block_04 .content-box .single-item:first-child .box h4:before {
  left: 0px;
}

#content_block_04 .content-box .single-item:last-child .box h4:before {
  right: 0px;
}

#content_block_04 .content-box .single-item .box p {
  margin-bottom: 20px;
  line-height: 28px;
}

#content_block_04 .content-box .single-item .box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Josefin Sans', Sans-serif;
  font-weight: 600;
  color: #040535;
}

#content_block_04 .content-box .single-item .box a:hover {
}

#content_block_04 .content-box .single-item:first-child .box a i {
  margin-right: 10px;
}

#content_block_04 .content-box .single-item:last-child .box a i {
  margin-left: 10px;
}

/** testimonial-style-two **/

.testimonial-style-two {
  position: relative;
  background: #fff;
}

.testimonial-style-two .title-inner {
  position: relative;
  padding: 125px 0px 170px 0px;
}

.testimonial-style-two .title-inner .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-style-two .lower-content .testimonial-content .inner-box {
  position: relative;
  padding-left: 450px;
  min-height: 430px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .image-box {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  clip-path: polygon(0% 0%, 100% 0%, 78% 100%, 0% 100%, 0% 0%);
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .image-box
  img {
  width: 100%;
  border-radius: 5px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box {
  position: relative;
  padding: 93px 130px 92px 140px;
  border-radius: 10px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 17% 0%);
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .text {
  position: relative;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .text:before {
  position: absolute;
  content: '\f105';
  font-family: 'Flaticon';
  font-size: 200px;
  line-height: 150px;
  color: rgba(255, 255, 255, 0.1);
  top: 70px;
  right: -50px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  p {
  position: relative;
  font-size: 24px;
  font-family: 'Josefin Sans', Sans-serif;
  color: #fff;
  line-height: 42px;
  margin-bottom: 21px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .author-info {
  position: relative;
  padding-left: 22px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .author-info:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 2px;
  height: 50px;
  left: 0px;
  top: 4px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .author-info
  h4 {
  color: #fff;
  margin-bottom: 2px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .author-info
  .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  margin: 0px;
}

.testimonial-style-two
  .lower-content
  .testimonial-content
  .inner-box
  .content-box
  .icon-box {
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 100px;
  height: 86px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 3s infinite linear;
  animation: zoom-fade 3s infinite linear;
}

.testimonial-style-two .lower-content .inner-content {
  position: relative;
  margin-top: -130px;
}

.testimonial-style-two .owl-nav {
  position: absolute;
  top: -90px;
  right: 0px;
}

/** news-style-two **/

.news-section.style-two {
  position: relative;
  padding: 125px 0px;
}

.news-section.home-boxed {
  background: #fff;
}

.news-block-one .image-box a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  z-index: 1;
  opacity: 0;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.news-block-one .inner-box:hover .image-box a {
  opacity: 1;
}

.news-section.style-two .news-block-one .inner-box .lower-content .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  color: #040535;
  font-weight: 700;
}

.news-section.style-two
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover {
}

.news-section.style-two .news-block-one .inner-box .lower-content .link a i {
  font-size: 14px;
  margin-right: 10px;
}

/** clients-style-two **/

.clients-style-two {
  position: relative;
  padding: 125px 0px 230px 0px;
}

.clients-style-two .pattern-layer .pattern-1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.clients-style-two .pattern-layer .pattern-2 {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 450px;
  height: 515px;
  background-repeat: no-repeat;
}

.clients-style-two .logo-inner .logo-box li {
  position: relative;
  float: left;
}

.clients-style-two .logo-inner .logo-box-one li {
  width: 25%;
}

.clients-style-two .logo-inner .logo-box-two li {
  width: 33.333%;
}

.clients-style-two .logo-inner .logo-box li .image-box {
  position: relative;
  text-align: center;
}

.clients-style-two .logo-inner .logo-box li .image-box:before {
  position: absolute;
  content: '';
  background: #dbdbdb;
  width: 1px;
  height: 60px;
  top: -8px;
  right: 0px;
}

.clients-style-two .logo-inner .logo-box li:last-child .image-box:before {
  display: none;
}

.clients-style-two .logo-inner .logo-box li .image-box:hover img {
  opacity: 0.5;
  transform: scale(1.05);
}

.clients-style-two .logo-inner .logo-box-one {
  position: relative;
  display: block;
  padding-bottom: 27px;
  border-bottom: 1px solid #dbdbdb;
}

.clients-style-two .logo-inner .logo-box-two {
  position: relative;
  padding: 28px 130px 0px 130px;
}

/** info-section **/

.info-section {
  position: relative;
}

.info-section .inner-container {
  position: relative;
  width: 100%;
}

.info-section .inner-container .info-inner {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -105px;
  right: 0px;
  z-index: 1;
  margin: 0px 15px;
  border-radius: 10px;
  overflow: hidden;
}

.info-section .inner-container .info-inner .info-column {
  position: relative;
  padding: 0px 0px;
  background: #1a1b40;
}

.info-section .inner-container .info-inner .info-column:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  transition: all 500ms ease;
}

.info-section .inner-container .info-inner .info-column:hover:before {
  height: 100%;
  top: 0px;
}

.info-section .inner-container .info-inner .info-box {
  position: relative;
  padding: 35px 30px 30px 40px;
}

.info-section .inner-container .info-inner .info-box .hidden-icon {
  position: absolute;
  right: -10px;
  bottom: -10px;
  font-size: 120px;
  line-height: 120px;
  color: rgba(255, 255, 255, 0.1);
  transform: rotate(-45deg);
  opacity: 0;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.info-section .inner-container .info-inner .info-box:hover .hidden-icon {
  opacity: 1;
}

.info-section .inner-container .info-inner .info-column:first-child .info-box {
  padding-left: 60px;
}

.info-section .inner-container .info-inner .info-box .box {
  position: relative;
  padding-left: 73px;
  margin-bottom: 18px;
}

.info-section .inner-container .info-inner .info-box .box .icon-box {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 45px;
  line-height: 45px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.info-section .inner-container .info-inner .info-box .box h4 {
  color: #fff;
  font-weight: 500;
}

.info-section .inner-container .info-inner .info-box .box span {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
}

.info-section .inner-container .info-inner .info-box .text p {
  color: rgba(255, 255, 255, 0.6);
}

.info-section .inner-container .info-inner .info-box .text p a {
  color: rgba(255, 255, 255, 0.6);
}

.info-section .inner-container .info-inner .info-box .text p a:hover {
  text-decoration: underline;
  color: #fff;
}

/** footer-style-two **/

.footer-style-two {
  position: relative;
}

.footer-style-two .footer-top {
  position: relative;
  padding: 180px 0px 90px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-style-two .widget-section .footer-widget .widget-title {
  position: relative;
  display: block;
  margin-bottom: 24px;
}

.footer-style-two .widget-section .footer-widget .widget-title h3 {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
}

.footer-style-two .widget-section .links-widget .widget-content .links li {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-two .widget-section .links-widget .widget-content .links li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Muli', sans-serif;
  color: #fff;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li
  a:hover {
  padding-left: 27px;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li
  a:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  left: 0px;
  top: 11px;
  border-radius: 50%;
  opacity: 0;
  transition: all 300ms ease;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li
  a:after {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  left: 8px;
  top: 10px;
  border-radius: 50%;
  opacity: 0;
  transition: all 500ms ease;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li
  a:hover:before,
.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .links
  li
  a:hover:after {
  opacity: 1;
}

.footer-style-two
  .widget-section
  .links-widget
  .widget-content
  .column:last-child
  .links {
  margin-left: -50px;
}

.footer-style-two .widget-section .post-widget .widget-content .post {
  position: relative;
  padding-left: 75px;
  margin-bottom: 26px;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post:last-child {
  margin-bottom: 0px;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  .post-date {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 55px;
  height: 55px;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0px;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  .post-date
  p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  .post-date
  span {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  .file-box
  p {
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  .file-box
  i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.footer-style-two .widget-section .post-widget .widget-content .post .file-box {
  position: relative;
  margin-bottom: 9px;
}

.footer-style-two .widget-section .post-widget .widget-content .post h5 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #fff;
}

.footer-style-two .widget-section .post-widget .widget-content .post h5 a {
  color: #fff;
}

.footer-style-two
  .widget-section
  .post-widget
  .widget-content
  .post
  h5
  a:hover {
}

.footer-style-two .widget-section .gallery-widget .widget-content .list li {
  position: relative;
  float: left;
  width: 85px;
  height: 85px;
  margin: 0px 5px 10px 5px;
}

.footer-style-two
  .widget-section
  .gallery-widget
  .widget-content
  .list
  li
  .image-box {
  position: relative;
  overflow: hidden;
}

.footer-style-two
  .widget-section
  .gallery-widget
  .widget-content
  .list
  li
  .image-box
  img {
  width: 100%;
  transition: all 500ms ease;
}

.footer-style-two
  .widget-section
  .gallery-widget
  .widget-content
  .list
  li:hover
  .image-box
  img {
  opacity: 0.1;
}

.footer-style-two
  .widget-section
  .gallery-widget
  .widget-content
  .list
  li
  .image-box
  a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.footer-style-two
  .widget-section
  .gallery-widget
  .widget-content
  .list
  li:hover
  .image-box
  a {
  opacity: 1;
}

.footer-style-two .widget-section .gallery-widget .widget-content .list {
  position: relative;
  margin: 0px -5px;
}

.footer-style-two .widget-section .gallery-widget .widget-title {
  margin-bottom: 31px;
}

.footer-style-two .widget-section .post-widget .widget-title {
  margin-bottom: 27px;
}

.footer-style-two .footer-bottom .copyright {
  position: relative;
  display: inline-block;
}

.footer-style-two .footer-bottom .footer-logo {
  position: relative;
  display: inline-block;
  margin-left: 210px;
}

.footer-style-two .footer-bottom {
  position: relative;
  padding: 30px 0px;
}

.footer-style-two .footer-bottom .footer-nav {
  margin-top: 8px;
}

/*** 

====================================================================
                          Home-ML
====================================================================

 ***/

/** banner-style-three **/

.banner-style-three {
  position: relative;
  padding: 425px 0px 60px 0px;
}

.banner-style-three .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 1002px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-style-three .content-box h1 {
  position: relative;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  margin-bottom: 13px;
}

.banner-style-three .content-box {
  position: relative;
  margin-right: -10px;
}

.banner-style-three .content-box p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 32px;
}

.banner-style-three .content-box .btn-box .theme-btn.style-six {
  padding: 12px 42px;
  margin-left: 15px;
}

/** technology-section **/

.technology-section {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.technology-section .inner-content {
  position: relative;
  margin: 0px 15px;
}

.technology-section .technology-block {
  padding: 0px 0px;
}

.technology-section
  .technology-block:nth-child(2n + 1)
  .technology-block-one
  .inner-box {
  background: #f6f6f6;
}

.technology-block-one .inner-box {
  position: relative;
  display: block;
  background: #f9f9f9;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 30px 43px 40px;
  transition: all 500ms ease;
}

.technology-block-one .inner-box:hover {
  margin: -5px 0px;
  padding: 55px 30px 48px 40px;
}

.technology-block-one .inner-box:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scale(0.9) translateY(20px);
  -ms-transform: scale(0.9) translateY(20px);
  transform: scale(0.9) translateY(20px);
  transition: all 500ms ease;
}

.technology-block-one .inner-box:hover:before {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.technology-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 60px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  background: transparent;
  margin-bottom: 16px;
  transition: all 500ms ease;
}

.technology-block-one .inner-box:hover .icon-box {
  font-size: 50px;
}

.technology-block-one .inner-box .icon-box:before {
  position: absolute;
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: 0px;
  right: -9px;
  transition: all 500ms ease;
  -webkit-animation: zoom-fade 2s infinite linear;
  animation: zoom-fade 2s infinite linear;
}

.technology-block-one .inner-box:hover .icon-box:before {
  opacity: 0;
}

.technology-block-one .inner-box:hover .icon-box {
  background: #fff;
}

.technology-block-one .inner-box .icon-box i {
  position: relative;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.technology-block-one .inner-box h4 {
  margin-bottom: 12px;
  transition: all 500ms ease;
}

.technology-block-one .inner-box:hover h4,
.technology-block-one .inner-box:hover h4 a,
.technology-block-one .inner-box:hover p {
  color: #fff;
}

.technology-block-one .inner-box p {
  transition: all 500ms ease;
}

.technology-block-one .inner-box .pattern-layer {
  position: absolute;
  top: -70px;
  right: -70px;
  width: 216px;
  height: 216px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 500ms ease;
}

.technology-block-one .inner-box:hover .pattern-layer {
  opacity: 1;
}

/** about-style-three **/

.about-style-three {
  position: relative;
  padding: 70px 0px 250px 0px;
}

#image_block_02 .image-box {
  position: relative;
  display: block;
  margin-left: -270px;
  padding-right: 100px;
}

#image_block_02 .image-box img {
  width: 100%;
}

#image_block_02 .image-box .image-2 {
  position: absolute;
  right: 30px;
  bottom: -125px;
  border-radius: 5px;
  box-shadow: 0 10px 50px 10px #ededf1;
}

#image_block_02 .image-box .image-2 img {
  border-radius: 5px;
}

#content_block_05 .content-box .text {
  position: relative;
  margin-bottom: 33px;
}

#content_block_05 .content-box .text p {
  margin-bottom: 25px;
}

#content_block_05 .content-box .text p:last-child {
  margin-bottom: 0px;
}

#content_block_05 .content-box .sec-title {
  margin-bottom: 32px;
}

#content_block_05 .content-box .btn-box .theme-btn.style-six {
  padding: 12px 45px;
}

.about-style-three #content_block_05 .content-box {
  margin-top: 43px;
}

/** chooseus-style-two **/

.chooseus-style-two {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.chooseus-style-two .bg-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.chooseus-style-two .title-inner .sec-title {
  position: relative;
  width: 50%;
  padding-right: 30px;
}

.chooseus-style-two .title-inner .text {
  position: relative;
  width: 50%;
  margin-top: 42px;
  padding-left: 30px;
}

.chooseus-style-two .title-inner .text p {
  color: #fff;
}

.chooseus-style-two .single-item .inner-box h4 {
  color: #fff;
  padding-bottom: 12px;
  margin-bottom: 28px;
}

.chooseus-style-two .single-item .inner-box h4:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 55px;
  height: 2px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.chooseus-style-two .single-item .inner-box:hover h4:before {
  background: #fff;
}

.chooseus-style-two .single-item .inner-box .box {
  position: relative;
  padding-left: 73px;
  min-height: 52px;
}

.chooseus-style-two .single-item .inner-box .box .icon-box {
  position: absolute;
  left: 0px;
  top: 2px;
  font-size: 55px;
  line-height: 55px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 500ms ease;
}

.chooseus-style-two .single-item .inner-box:hover .box .icon-box {
  transform: rotateY(360deg);
}

.chooseus-style-two .single-item .inner-box .box p {
  color: #fff;
}

/** team-section **/

.team-section {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.team-block-one .inner-box {
  position: relative;
  display: block;
  padding-right: 24px;
}

.team-block-one .inner-box .image-box {
  position: relative;
  display: block;
  border-radius: 5px;
}

.team-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box img {
  opacity: 0.5;
}

.team-block-one .inner-box .image-box .contact-box {
  position: absolute;
  right: -24px;
  bottom: 25px;
  width: 50px;
  height: 110px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.team-block-one .inner-box .image-box .contact-box:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  top: 0px;
  right: 0px;
  border-radius: 30px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .contact-box:before {
  width: 100%;
  left: 0px;
}

.team-block-one .inner-box .image-box .contact-box .email {
  position: absolute;
  top: 0px;
  right: 0px;
}

.team-block-one .inner-box .image-box .contact-box .email i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #aeaeae;
  text-align: center;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .contact-box .email i {
  color: #fff;
}

.team-block-one .inner-box .image-box .contact-box .email:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  height: 1px;
  width: 34px;
  left: 8px;
  bottom: 0px;
}

.team-block-one .inner-box .image-box .contact-box .email span {
  position: absolute;
  display: inline-block;
  right: 50px;
  top: 20px;
  font-size: 14px;
  line-height: 24px;
  background: #fff;
  border-radius: 3px;
  padding: 0px 12.5px;
  color: #666;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.team-block-one .inner-box .image-box .contact-box .email:hover span {
  opacity: 1;
  visibility: visible;
  right: 65px;
}

.team-block-one .inner-box .image-box .contact-box .email span:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 12px;
  height: 12px;
  top: 6px;
  right: -3px;
  transform: rotate(45deg);
}

.team-block-one .inner-box .image-box .contact-box .social-links {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.team-block-one .inner-box .image-box .contact-box .social-links .share {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #aeaeae;
  text-align: center;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .contact-box .social-links .share {
  color: #fff;
}

.team-block-one .inner-box .image-box .contact-box .social-links .list {
  position: absolute;
  top: 6px;
  right: 43px;
  width: 130px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.team-block-one .inner-box .image-box .contact-box .social-links:hover .list {
  visibility: visible;
  opacity: 1;
  right: 58px;
}

.team-block-one .inner-box .image-box .contact-box .social-links .list li {
  position: relative;
  display: inline-block;
  margin-left: 6px;
}

.team-block-one
  .inner-box
  .image-box
  .contact-box
  .social-links
  .list
  li:first-child {
  margin-left: 0px;
}

.team-block-one .inner-box .image-box .contact-box .social-links .list li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #8d8d8d;
  background: #fff;
  border-radius: 50%;
  text-align: center;
}

.team-block-one
  .inner-box
  .image-box
  .contact-box
  .social-links
  .list
  li
  a:hover {
  color: #fff;
}

.team-block-one .inner-box .lower-content {
  padding-top: 26px;
}

.team-block-one .inner-box .lower-content h4 {
  margin-bottom: 0px;
}

.team-block-one .inner-box .lower-content .designation {
  position: relative;
  display: block;
  line-height: 20px;
}

.team-section .more-btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 36px;
}

/** case-style-three **/

.case-style-three {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.case-style-three .case-block-two .inner-box {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.case-style-three .case-block-two .inner-box .image-box {
  border-radius: 0px;
}

.case-style-three .case-block-two .inner-box .image-box img {
  border-radius: 0px;
}

.case-style-three .case-block-two .inner-box .lower-content .box {
  margin-bottom: 15px;
}

.case-style-three .case-block-two .inner-box .lower-content {
  padding: 32px 30px 16px 30px;
}

.case-style-three .case-block-two .inner-box .lower-content .text {
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.case-style-three .case-block-two .inner-box .lower-content .link {
  position: relative;
}

.case-style-three .case-block-two .inner-box .lower-content .link i {
  position: absolute;
  left: 0px;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0;
  transition: all 500ms ease;
}

.case-style-three .case-block-two .inner-box .lower-content .link a:hover i {
  opacity: 1;
}

.case-style-three .case-block-two .inner-box .lower-content .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
}

.case-style-three .case-block-two .inner-box .lower-content .link a:hover {
  padding-left: 22px;
}

/** video-faq **/

.video-faq {
  position: relative;
  padding: 130px 0px;
  width: 100%;
  margin: 0 auto;
}

.video-faq .video-inner {
  position: relative;
  max-width: 770px;
  width: 100%;
  margin-bottom: 125px;
}

.video-faq .video-inner .video-box {
  position: relative;
  width: 100%;
  padding: 170px 0px;
  background-size: cover;
  text-align: center;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.video-faq .video-inner .video-content a {
  position: relative;
  display: inline-block;
  font-size: 70px;
  color: #fff;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  margin-bottom: 0px;
  border-radius: 50%;
}

.video-faq .video-inner .video-content a:after,
.video-faq .video-inner .video-content a:before {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.video-faq .video-inner .video-content a:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.video-faq .video-inner .video-content h3 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  max-width: 225px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: #260556;
  text-transform: uppercase;
}

.video-faq .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 1085px;
  background-repeat: no-repeat;
  background-size: cover;
}

#content_block_06 .content-box .sec-title {
  margin-bottom: 32px;
}

#content_block_06 .content-box .text {
  position: relative;
  margin-bottom: 33px;
}

#content_block_07 .accordion-box .block {
  position: relative;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}

#content_block_07 .accordion-box .block .acc-btn {
  position: relative;
  display: block;
  padding: 20px 60px 18px 30px;
  cursor: pointer;
  background: #fff;
  transition: all 500ms ease;
}

#content_block_07 .accordion-box .block .acc-btn h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin: 0px;
  transition: all 500ms ease;
}

#content_block_07 .accordion-box .block .acc-btn.active h5 {
  color: #fff;
}

#content_block_07 .accordion-box .block:last-child {
  margin-bottom: 0px;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

#content_block_07 .accordion-box .block .acc-btn .icon-outer {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 14px;
  color: #b8b8b8;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
  z-index: 1;
}

#content_block_07 .accordion-box .block .acc-btn.active .icon-outer {
  color: #fff;
}

#content_block_07 .accordion-box .block .acc-btn.active .icon-outer i:before {
  position: relative;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  margin: 0px;
  content: '\f00d';
}

#content_block_07 .accordion-box .block .acc-content .text {
  position: relative;
  padding: 27px 45px 28px 30px;
}

.video-faq #content_block_07 .accordion-box {
  margin-top: 6px;
}

/** pricing-section **/

.pricing-section {
  position: relative;
}

.pricing-section .title-inner {
  position: relative;
  padding: 125px 0px 360px 0px;
}

.pricing-section .lower-content .inner-container {
  position: relative;
  margin-top: -330px;
}

.pricing-block-one .pricing-table {
  position: relative;
  display: block;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 10px 40px 5px #ededf1;
}

.pricing-block-one .pricing-table .table-header {
  position: relative;
  background: #f6f6f6;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  padding: 36px 30px 33px 30px;
}

.pricing-block-one.active-block .pricing-table .table-header {
}

.pricing-block-one .pricing-table .table-header .header-upper h3 {
  position: relative;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 3px;
}

.pricing-block-one.active-block .pricing-table .table-header .header-upper h3,
.pricing-block-one.active-block .pricing-table .table-header .header-upper p,
.pricing-block-one.active-block .pricing-table .table-header .header-lower h1,
.pricing-block-one.active-block .pricing-table .table-header .header-lower p {
  color: #fff;
}

.pricing-block-one .pricing-table .table-header .header-upper p {
  line-height: 24px;
}

.pricing-block-one .pricing-table .table-header .header-upper {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing-block-one.active-block .pricing-table .table-header .header-upper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.pricing-block-one .pricing-table .table-header .header-lower h1 {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 65px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  margin-bottom: 2px;
}

.pricing-block:last-child
  .pricing-block-one
  .pricing-table
  .table-header
  .header-lower
  h1 {
}

.pricing-block-one .pricing-table .table-header .header-lower h1 span {
  position: absolute;
  left: -15px;
  top: -12px;
  font-size: 20px;
  font-weight: 500;
}

.pricing-block-one .pricing-table .table-header .header-lower p {
  font-weight: 600;
}

.pricing-block-one .pricing-table .table-content {
  position: relative;
  padding: 18px 30px 17px 30px;
}

.pricing-block-one .pricing-table .table-content ul li {
  position: relative;
  display: block;
  font-size: 16px;
  font-family: 'Muli', sans-serif;
  color: #666;
  padding: 13px 0px 14px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing-block-one .pricing-table .table-content ul li:last-child {
  border-bottom: none;
}

.pricing-block-one .pricing-table .table-footer {
  position: relative;
  padding: 0px 30px 40px 30px;
}

.pricing-block-one .pricing-table .table-footer .btn-style-eight {
  display: block;
  width: 100%;
}

.pricing-block-one .pricing-table .table-footer .btn-style-eight i {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  color: #fff;
  opacity: 0;
  transition: all 500ms ease;
}

.pricing-block-one .pricing-table .table-footer .btn-style-eight:hover i {
  opacity: 1;
}

.pricing-block-one.active-block
  .pricing-table
  .table-footer
  .btn-style-eight:before {
  opacity: 0;
}

.pricing-block-one.active-block
  .pricing-table
  .table-footer
  .btn-style-eight
  i {
  opacity: 1;
}

.pricing-block-one.active-block .pricing-table .table-footer .btn-style-eight {
  color: #fff;
}

/** news-style-three **/

.news-section.style-three .news-block-one .inner-box .lower-content .link {
  position: relative;
}

.news-section.style-three .news-block-one .inner-box .lower-content .link i {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.news-section.style-three
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover
  i {
  opacity: 1;
}

.news-section.style-three
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover {
  padding-left: 22px;
}

.news-section.style-three
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover {
}

.news-section.style-three .sec-title {
  width: 50%;
}

.news-section.style-three .btn-box {
  width: 50%;
  text-align: right;
}

/** testimonial-style-three **/

.testimonial-style-three {
  position: relative;
  padding-bottom: 80px;
}

.testimonial-style-three .inner-content {
  position: relative;
  margin-right: -40px;
  padding-top: 75px;
}

.testimonial-style-three .inner-content .bx-viewport {
  min-height: 500px !important;
}

.testimonial-style-three .inner-content .sec-title {
  position: absolute;
  left: 0px;
  top: 0px;
  padding-left: 400px;
}

.testimonial-style-three .pattern-layer {
  position: absolute;
  left: -25px;
  top: -380px;
  width: 708px;
  height: 1160px;
  background-repeat: no-repeat;
  z-index: -1;
}

.testimonial-style-three .slider-pager {
  position: relative;
  margin-right: 25px;
  margin-top: 0px;
}

.testimonial-style-three .slider-pager .thumb-box {
  position: relative;
  min-height: 440px;
  width: 100%;
  max-width: 360px;
}

.testimonial-style-three .slider-pager .thumb-box li {
  position: absolute;
  display: inline-block;
  width: 164px;
  height: 164px;
  border-radius: 50%;
}

.testimonial-style-three .slider-pager .thumb-box li:first-child {
  top: 0px;
  left: 0px;
}

.testimonial-style-three .slider-pager .thumb-box li:nth-child(2) {
  top: 58%;
  right: 0px;
  transform: translateY(-50%);
}

.testimonial-style-three .slider-pager .thumb-box li:last-child {
  bottom: 0px;
  left: 0px;
}

.testimonial-style-three .slider-pager .thumb-box li img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-style-three .slider-pager .thumb-box li .thumb {
  position: relative;
  display: inline-block;
  width: 100%;
  background: transparent;
  border-radius: 50%;
  padding: 7px;
  z-index: 1;
  transition: all 500ms ease;
}

.testimonial-style-three .slider-pager .thumb-box li a.active .thumb:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 160px;
  height: 160px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  z-index: -1;
}

.testimonial-style-three .testimonial-content {
  position: relative;
  border-bottom-right-radius: 50px;
  margin: 145px 50px 0px 0px;
}

.testimonial-style-three .testimonial-content .image-box img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-style-three .testimonial-content .content-box {
  position: relative;
  background: #fff;
  box-shadow: 0 0px 30px 10px #ededf1;
  padding: 41px 50px 50px 155px;
  border-bottom-right-radius: 50px;
}

.testimonial-style-three .testimonial-content .content-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 20px;
  top: 20px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 10px 30px 10px #ededf1;
  z-index: -1;
}

.testimonial-style-three .testimonial-content .content-box p {
  font-size: 20px;
  line-height: 38px;
  color: #7f7f7f;
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 22px;
}

.testimonial-style-three .testimonial-content .content-box .text {
  position: relative;
}

.testimonial-style-three .testimonial-content .content-box .text:before {
  position: absolute;
  content: '\f105';
  font-family: 'Flaticon';
  font-size: 160px;
  line-height: 150px;
  color: #f6f6f6;
  left: -105px;
  top: -8px;
}

.testimonial-style-three .testimonial-content .content-box .author-info h4 {
  margin-bottom: 4px;
}

.testimonial-style-three
  .testimonial-content
  .content-box
  .author-info
  .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
}

.testimonial-style-three .testimonial-content .content-box .icon-box {
  position: absolute;
  right: 50px;
  bottom: 45px;
  display: inline-block;
  background: #fff;
  width: 72px;
  height: 72px;
  text-align: center;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.testimonial-style-three .slider-pager .elipse-box .elipse {
  position: absolute;
  border-radius: 50%;
}

.testimonial-style-three .slider-pager .elipse-box .elipse-1 {
  width: 30px;
  height: 30px;
  top: 105px;
  right: 115px;
  -webkit-animation: zoom-fade 2s infinite linear;
  animation: zoom-fade 2s infinite linear;
}

.testimonial-style-three .slider-pager .elipse-box .elipse-2 {
  width: 50px;
  height: 50px;
  top: 205px;
  left: 35px;
  -webkit-animation: zoom-fade 3s infinite linear;
  animation: zoom-fade 3s infinite linear;
}

/** footer-style-three **/

.footer-style-three {
  position: relative;
  overflow: hidden;
}

.footer-style-three .footer-top {
  position: relative;
  background: #1a1f34;
  padding: 100px 0px;
}

.footer-style-three .footer-top .pattern-layer .pattern-1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-style-three .footer-top .pattern-layer .pattern-2 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 230px;
  height: 106px;
  background-repeat: no-repeat;
}

.footer-style-three .footer-bottom {
  position: relative;
  background: #181c2a;
  padding: 22px 0px;
}

.footer-style-three .footer-top .widget-section .widget-title {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.footer-style-three .footer-top .widget-section .widget-title h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
}

.footer-style-three
  .footer-top
  .widget-section
  .about-widget
  .widget-content
  .text {
  position: relative;
  margin-bottom: 23px;
}

.footer-style-three
  .footer-top
  .widget-section
  .about-widget
  .widget-content
  .text
  p {
  color: #aaadb7;
}

.footer-style-three
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.footer-style-three
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-three
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Muli', sans-serif;
  color: #aaadb7;
}

.footer-style-three
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a:hover {
  color: #fff;
}

.footer-style-three .footer-top .widget-section .links-widget {
  margin-left: 30px;
}

.footer-style-three
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  ul
  li {
  position: relative;
  display: block;
  font-size: 16px;
  color: #aaadb7;
  margin-bottom: 15px;
}

.footer-style-three
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  ul
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-three
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  ul
  li
  span {
  position: relative;
  display: block;
  font-weight: 700;
  color: #fff;
}

.footer-style-three
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  ul
  li
  a {
  color: #aaadb7;
}

.footer-style-three
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  ul
  li
  a:hover {
  color: #fff;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .subscribe-form {
  position: relative;
  margin-bottom: 30px;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .subscribe-form
  .form-group {
  position: relative;
  margin: 0px;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .subscribe-form
  .form-group
  input[type='email'] {
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 3px solid #fff;
  border-radius: 30px;
  padding: 10px 25px;
  font-size: 16px;
  margin-bottom: 15px;
  transition: all 500ms ease;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .subscribe-form
  .form-group
  input:focus {
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .subscribe-form
  .form-group
  button {
  width: 100%;
  padding: 10px 30px;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li:last-child {
  margin-right: 0px;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li
  a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #9197a9;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li
  a:hover {
  color: #fff;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li
  a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-content
  .social-links
  li
  a:hover:before {
  transform: scale(1, 1);
}

.footer-style-three
  .footer-top
  .widget-section
  .subscribe-widget
  .widget-title {
  margin-bottom: 30px;
}

/*** 

====================================================================
                          Home-BI
====================================================================

 ***/

/** banner-style-four **/

.banner-style-four {
  position: relative;
  padding: 333px 0px 100px 0px;
}

.banner-style-four .content-box h2 {
  position: relative;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 25px;
}

.banner-style-four .content-box h1 {
  position: relative;
  font-size: 130px;
  line-height: 130px;
  font-weight: 700;
  margin-bottom: 0px;
}

.banner-style-four .content-box p {
  margin-bottom: 41px;
  line-height: 30px;
}

.banner-style-four .pattern-layer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 895px;
  height: 1435px;
  background-repeat: no-repeat;
}

.banner-style-four .image-layer {
  position: absolute;
  top: 210px;
  right: 0px;
}

/** service-style-three **/

.service-style-three {
  position: relative;
  padding: 150px 0px 125px 0px;
}

.service-block-three .inner-box {
  position: relative;
  display: block;
  background: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 48px 30px 40px 30px;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}

.service-block-three .inner-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: calc(100% - 20px);
  height: 100%;
  left: 10px;
  top: 0px;
  border-radius: 5px;
  z-index: -1;
  transition: all 500ms ease;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.1);
}

.service-block-three .inner-box:hover:before {
  top: 10px;
}

.service-block-three .inner-box h3 {
  position: relative;
  display: block;
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}

.service-block-three .inner-box h3 a {
  display: inline-block;
  color: #1d165c;
}

.service-block-three .inner-box h3 a:hover {
}

.service-block-three .inner-box .image-box {
  position: relative;
  min-height: 185px;
  margin-bottom: 10px;
}

.service-block-three .inner-box .image-box img {
  width: auto;
  transition: all 500ms ease;
}

.service-block-three .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.service-block-three .inner-box p {
  margin-bottom: 17px;
}

.service-block-three .inner-box .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  color: #1d165c;
}

.service-block-three .inner-box .link a i {
  position: relative;
  top: 1px;
  margin: 0px 8px;
}

.service-block-three .inner-box .link a:hover {
}

/** technology-style-two **/

.technology-style-two {
  position: relative;
}

.technology-style-two .upper-box .image-box {
  position: relative;
  display: block;
  text-align: center;
  padding: 0px 200px;
}

.technology-style-two .upper-box .image-box img {
  width: 100%;
}

.technology-style-two .upper-box .image-box .image {
  border-radius: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.technology-style-two .upper-box .image-box .image-2 {
  position: absolute;
  left: 0px;
  bottom: -80px;
  border-radius: 50px;
}

.technology-style-two .upper-box .image-box .image-3 {
  position: absolute;
  right: 0px;
  bottom: -80px;
  width: 300px;
  height: 300px;
  background: #fff;
  padding: 15px;
  border-radius: 50%;
}

.technology-style-two .upper-box .image-box .image-3 img {
  border-radius: 50%;
}

.technology-style-two .upper-box {
  position: relative;
}

.technology-style-two .upper-box .upper-inner {
  position: relative;
  z-index: 1;
  margin-bottom: -180px;
}

.technology-style-two .lower-box {
  position: relative;
  padding: 360px 0px 100px 0px;
}

.technology-block-two .inner-box {
  position: relative;
  display: block;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 45px 25px 40px 40px;
  border-radius: 3px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.technology-block-two .inner-box .border-box {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  animation: slide 60s linear infinite;
  -webkit-animation: slide 60s linear infinite;
}

.technology-block-two .inner-box .border-box .border {
  position: absolute;
  width: 30px;
  height: 1px;
  border: none !important;
  background: #fff;
  transition: all 1500ms ease;
}

.technology-block-two .inner-box .border-box .border-1 {
  top: 0px;
  left: 40px;
}

.technology-block-two .inner-box:hover .border-box .border-1 {
  left: 88% !important;
}

.technology-block-two .inner-box .border-box .border-2 {
  bottom: 0px;
  right: 40px;
}

.technology-block-two .inner-box:hover .border-box .border-2 {
  right: 88% !important;
}

.technology-block-two .inner-box .image-box {
  position: absolute;
  top: 12px;
  right: 0px;
}

.technology-block-two .inner-box .inner {
  position: relative;
  padding-right: 250px;
}

.technology-block-two .inner-box .inner h3 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 6px;
}

.technology-block-two .inner-box .inner p {
  color: #fff;
  margin-bottom: 16px;
}

.technology-block-two .inner-box .inner a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
  font-weight: 600;
}

.technology-block-two .inner-box .inner a:hover {
  padding-left: 17px;
}

.technology-block-two .inner-box .inner a:before {
  position: absolute;
  content: '\f104';
  font-size: 16px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  color: #fff;
  left: 0px;
  top: 0px;
  transition: all 500ms ease;
  opacity: 0;
}

.technology-block-two .inner-box .inner a:hover:before {
  opacity: 1;
}

.technology-block-two .inner-box .inner a i {
  position: relative;
  top: 1px;
  margin-left: 8px;
}

/** about-style-four **/

.about-style-four {
  position: relative;
  padding: 170px 0px 130px 0px;
}

.about-style-four .pattern-layer {
  position: absolute;
  left: 0px;
  top: -300px;
  width: 644px;
  height: 1022px;
  z-index: -1;
  background-repeat: no-repeat;
}

#image_block_03 .image-box {
  position: relative;
  display: block;
  padding-right: 160px;
  margin-right: 40px;
}

#image_block_03 .image-box .image-1 {
  position: relative;
  border-radius: 30px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

#image_block_03 .image-box .image img {
  width: 100%;
}

#image_block_03 .image-box .image-2 {
  position: absolute;
  border-radius: 5px;
  top: -40px;
  right: 0px;
  border: 1px solid #dee0e9;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

#image_block_03 .image-box .image-3 {
  position: absolute;
  left: -190px;
  bottom: -20px;
  border-radius: 5px;
  border: 1px solid #dee0e9;
  z-index: 1;
  box-shadow: 0 10px 30px 5px #e0e4f2;
}

#image_block_03 .image-box .image-3:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 35px;
  top: 35px;
  border-radius: 5px;
  z-index: -1;
}

#image_block_03 .image-box .pattern-layer {
  position: absolute;
  left: -120px;
  top: -90px;
  right: 0px;
  width: 623px;
  height: 620px;
  background-repeat: no-repeat;
}

#content_block_08 .content-box .list-item li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  padding-left: 20px;
  margin-bottom: 12px;
}

#content_block_08 .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

#content_block_08 .content-box .list-item li span {
  position: absolute;
  left: 0px;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#content_block_08 .content-box .list-item li span:before {
  position: absolute;
  content: '';
  width: 9px;
  height: 9px;
  left: -2px;
  top: -2px;
  border-radius: 50%;
}

#content_block_08 .content-box .list-item li span:after {
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  left: -4px;
  top: -4px;
  border-radius: 50%;
}

#content_block_08 .content-box .sec-title {
  margin-bottom: 32px;
}

#content_block_08 .content-box .list-item {
  position: relative;
  margin-bottom: 34px;
}

#content_block_08 .content-box .text {
  margin-bottom: 25px;
}

#content_block_08 .content-box .btn-box {
  margin-bottom: 49px;
}

#content_block_08 .content-box .lower-text h6 {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #1d165c;
  padding-left: 15px;
  z-index: 1;
}

#content_block_08 .content-box .lower-text h6 i {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 15px;
  color: #2b2566;
}

#content_block_08 .content-box .lower-text h6 a {
}

#content_block_08 .content-box .lower-text h6 a:hover {
  text-decoration: underline;
}

/** chooseus-style-three **/

.chooseus-style-three {
  position: relative;
  padding-top: 40px;
}

.chooseus-style-three .inner-box .sec-title {
  margin-bottom: 52px;
}

.chooseus-style-three .inner-box .single-item {
  position: relative;
  margin-bottom: 56px;
}

.chooseus-style-three .inner-box .single-item h3 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  padding-bottom: 12px;
  margin-bottom: 19px;
}

.chooseus-style-three .inner-box .single-item h3:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 55px;
  height: 2px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.chooseus-style-three .inner-box .single-item:hover h3:before {
  opacity: 0;
}

.chooseus-style-three .inner-box .single-item h3:after {
  position: absolute;
  content: '';
  width: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.chooseus-style-three .inner-box .single-item:hover h3:after {
  width: 55px;
  opacity: 1;
}

.chooseus-style-three .inner-box .single-item .box {
  position: relative;
  padding: 7px 0px 0px 75px;
}

.chooseus-style-three .inner-box .single-item .box .icon-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 36px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 20px 50px 5px rgba(53, 48, 212, 0.4);
}

.chooseus-style-three .inner-box .single-item .box .icon-box:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  left: -60px;
  top: -60px;
  opacity: 0;
  border-radius: 50%;
  transition: all 500ms ease;
}

.chooseus-style-three .inner-box .single-item:hover .box .icon-box:before {
  opacity: 1;
  left: -20px;
  top: -20px;
}

.chooseus-style-three
  .inner-column:last-child
  .inner-box
  .single-item:first-child
  .box
  .icon-box {
  box-shadow: 0 20px 50px 5px rgba(115, 2, 234, 0.3);
}

.chooseus-style-three
  .inner-column:last-child
  .inner-box
  .single-item:last-child
  .box
  .icon-box {
  box-shadow: 0 20px 50px 5px rgba(61, 176, 226, 0.4);
}

.chooseus-style-three .inner-box .single-item .box p {
  line-height: 28px;
}

.chooseus-style-three .image-layer {
  position: absolute;
  top: -180px;
  right: 0px;
}

.chooseus-style-three .pattern-layer {
  position: absolute;
  top: -400px;
  right: 0px;
  width: 644px;
  height: 1022px;
  background-repeat: no-repeat;
}

.chooseus-style-three .inner-container {
  position: relative;
  padding-bottom: 55px;
  border-bottom: 1px solid #e5e5e5;
}

/** funfact-style-two **/

.funfact-style-two {
  position: relative;
  padding: 95px 0px 125px 0px;
}

.counter-block-two .inner-box {
  position: relative;
  padding: 0px 0px 0px 120px;
  min-height: 56px;
}

.counter-block-two .inner-box .count-outer {
  position: absolute;
  left: 0px;
  top: 7px;
  font-size: 60px;
  line-height: 60px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
}

.counter-block-two .inner-box h4 {
  line-height: 28px;
  margin: 0px;
}

/** skills-style-two **/

.skills-style-two {
  position: relative;
  padding: 25px 0px 100px 0px;
}

.skills-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1406px;
  height: 1387px;
  background-repeat: no-repeat;
}

.skills-style-two
  #content_block_02
  .content-box
  .inner-box
  .progress-box
  .icon-box {
  font-weight: 700;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.skills-style-two
  #content_block_02
  .content-box
  .inner-box
  .progress-box
  .bar-inner {
  height: 3px;
}

.skills-style-two #content_block_02 .content-box .inner-box .progress-box .bar {
  height: 3px;
}

.skills-style-two
  #content_block_02
  .content-box
  .inner-box
  .progress-box
  .bar-inner:before {
  border: none;
  height: 13px;
  width: 3px;
  border-radius: 0px;
}

.skills-style-two
  #content_block_02
  .content-box
  .inner-box
  .progress-box
  .bar-inner:after {
  position: absolute;
  content: '';
  border: 3px dashed #f6f6f6;
  width: 100%;
  height: 3px;
  left: 0px;
  top: 0px;
}

.skills-style-two #content_block_02 .content-box .sec-title {
  margin-bottom: 33px;
}

.skills-style-two #content_block_02 .content-box {
  margin-top: 100px;
}

.skills-style-two .image-box {
  position: relative;
  display: block;
  margin-right: -400px;
}

.skills-style-two .image-box img {
  position: relative;
  width: 100%;
}

.skills-style-two .image-bg {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 708px;
  height: 634px;
  background-repeat: no-repeat;
}

/** testimonial-style-four **/

.testimonial-style-four {
  position: relative;
  padding: 120px 0px 130px 0px;
}

.testimonial-style-four .inner-container {
  position: relative;
  margin: 0px -15px;
}

.testimonial-style-four .testimonial-content .inner-box {
  position: relative;
  display: block;
  margin: 0px 15px;
}

.testimonial-style-four .testimonial-content .inner-box .upper-box {
  position: relative;
  display: block;
  overflow: hidden;
  background: #fff;
  padding: 50px 30px 46px 30px;
  margin: 45px 0px;
  box-shadow: 0 10px 40px 15px #ededf1;
  border-radius: 5px;
}

.testimonial-style-four .testimonial-content .inner-box .upper-box:before {
  position: absolute;
  content: '\f105';
  font-family: 'Flaticon';
  font-size: 200px;
  line-height: 150px;
  color: #f6f6f6;
  right: -5px;
  bottom: -30px;
  opacity: 0;
  transition: all 500ms ease;
}

.testimonial-style-four
  .testimonial-content
  .inner-box:hover
  .upper-box:before {
  opacity: 1;
  bottom: -15px;
}

.testimonial-style-four .testimonial-content .inner-box .upper-box .logo-box {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}

.testimonial-style-four .testimonial-content .inner-box .upper-box p {
  line-height: 28px;
  margin-bottom: 18px;
}

.testimonial-style-four .testimonial-content .inner-box .upper-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 20px;
}

.testimonial-style-four .testimonial-content .inner-box .author-info {
  position: relative;
  padding: 13px 0px 7px 90px;
  margin-left: 17px;
}

.testimonial-style-four .testimonial-content .inner-box .author-info:after {
  position: absolute;
  content: '';
  background: #fff;
  width: 50px;
  height: 50px;
  left: 10px;
  top: -80px;
  transform: rotate(-45deg);
}

.testimonial-style-four
  .testimonial-content
  .inner-box
  .author-info
  .image-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
}

.testimonial-style-four
  .testimonial-content
  .inner-box:hover
  .author-info
  .image-box {
  padding: 2px;
}

.testimonial-style-four
  .testimonial-content
  .inner-box
  .author-info
  .image-box
  img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-style-four .testimonial-content .inner-box .author-info h4 {
  margin-bottom: 0px;
  line-height: 24px;
}

.testimonial-style-four .owl-nav {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 40px;
}

.testimonial-style-four .owl-nav .owl-prev,
.testimonial-style-four .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #1d165c;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.testimonial-style-four .owl-nav .owl-prev:hover,
.testimonial-style-four .owl-nav .owl-next:hover {
  color: #fff;
}

.testimonial-style-four .owl-nav .owl-prev:before,
.testimonial-style-four .owl-nav .owl-next:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  left: -1px;
  top: -1px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.testimonial-style-four .owl-nav .owl-prev:hover:before,
.testimonial-style-four .owl-nav .owl-next:hover:before {
  transform: scale(1, 1);
}

/** subscribe-section **/

.subscribe-section {
  position: relative;
  padding: 130px 0px;
}

.subscribe-section .sec-title {
  margin-bottom: 52px;
}

.subscribe-section .pattern-layer .pattern-1 {
  position: absolute;
  right: 0px;
  bottom: 40px;
  width: 648px;
  height: 400px;
  background-repeat: no-repeat;
}

.subscribe-section .pattern-layer .pattern-2 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 812px;
  height: 598px;
  background-repeat: no-repeat;
}

.subscribe-section .image-layer {
  position: absolute;
  top: 50px;
  right: 90px;
}

.subscribe-section .inner-box .link-box li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin: 0px 28px;
}

.subscribe-section .inner-box .link-box li:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.3);
  width: 1px;
  height: 30px;
  top: -2px;
  right: -32px;
}

.subscribe-section .inner-box .link-box li:last-child:before {
  display: none;
}

.subscribe-section .inner-box .link-box li a {
  color: #fff;
}

.subscribe-section .inner-box .link-box li a:hover {
  text-decoration: underline;
}

.subscribe-section .inner-box .link-box li a i {
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.subscribe-section .inner-box .link-box {
  position: relative;
  margin-bottom: 41px;
}

.subscribe-section .inner-box {
  position: relative;
  z-index: 1;
}

.subscribe-section .inner-box .subscribe-form {
  position: relative;
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
}

.subscribe-section .inner-box .subscribe-form .form-group {
  position: relative;
  margin-bottom: 22px;
}

.subscribe-section .inner-box .subscribe-form .form-group:last-child {
  margin: 0px;
}

.subscribe-section .inner-box .subscribe-form .form-group input[type='email'] {
  position: relative;
  width: 100%;
  height: 66px;
  background: #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 100px 10px 20px;
  border: 3px solid #fff;
  transition: all 500ms ease;
}

.subscribe-section .inner-box .subscribe-form .form-group button {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 5px;
  padding: 10px;
  width: 52px;
  height: 52px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
}

.subscribe-section
  .inner-box
  .subscribe-form
  .form-group
  .custom-controls-stacked {
  position: relative;
  display: inline-block;
  padding-left: 25px;
}

.subscribe-section
  .inner-box
  .subscribe-form
  .form-group
  .custom-controls-stacked
  .description {
  position: relative;
  display: inline-block;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}

.custom-control.material-checkbox {
  --color: #fff;
  padding: 0px;
  margin: 0px;
}

.custom-control.material-checkbox .material-control-input {
  display: none;
}

.custom-control.material-checkbox .material-control-indicator {
  display: inline-block;
  position: absolute;
  top: 8px;
  left: -25px;
  width: 15px;
  height: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

.custom-control.material-checkbox
  .material-control-input:checked
  ~ .material-control-indicator {
  border-color: var(--color);
  -webkit-transform: rotateZ(45deg) translate(1px, -5px);
  transform: rotateZ(45deg) translate(1px, -5px);
  width: 10px;
  border-top: 0px solid #fff;
  border-left: 0px solid #fff;
}

/** news-style-four **/

.news-section.style-four {
  position: relative;
  padding: 125px 0px;
}

.news-section.style-four .news-block-one .inner-box .lower-content .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  font-weight: 700;
}

.news-section.style-four
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover {
  padding-left: 16px;
}

.news-section.style-four
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:before {
  position: absolute;
  content: '\f104';
  font-size: 16px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  left: 0px;
  top: 0px;
  transition: all 500ms ease;
  opacity: 0;
}

.news-section.style-four
  .news-block-one
  .inner-box
  .lower-content
  .link
  a:hover:before {
  opacity: 1;
}

.news-section.style-four .news-block-one .inner-box .lower-content .link a i {
  position: relative;
  top: 1px;
  margin-left: 8px;
}

/** contact-section **/

#content_block_09 .content-box {
  position: relative;
}

#content_block_09 .content-box #contact-form {
  position: relative;
  display: block;
  background: #fff;
  padding: 56px 40px 60px 40px;
  border-radius: 5px;
  box-shadow: 0 0px 50px rgba(2, 13, 49, 0.2);
}

#content_block_09 .content-box #contact-form:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: calc(100% - 40px);
  left: 20px;
  top: 20px;
  z-index: -1;
  border-radius: 5px;
  box-shadow: 0 0px 50px rgba(2, 13, 49, 0.2);
}

#content_block_09 .content-box #contact-form .form-group {
  position: relative;
  margin-bottom: 26px;
}

#content_block_09 .content-box #contact-form .form-group:last-child {
  margin-bottom: 0px;
}

#content_block_09 .content-box #contact-form .form-group label {
  position: relative;
  display: block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  font-weight: 600;
  margin-bottom: 13px;
}

#content_block_09 .content-box #contact-form .form-group label i {
  font-size: 14px;
  color: #7f7f7f;
  margin-right: 10px;
}

#content_block_09 .content-box #contact-form .form-group input[type='text'],
#content_block_09 .content-box #contact-form .form-group input[type='email'],
#content_block_09 .content-box #contact-form .form-group textarea {
  position: relative;
  width: 100%;
  height: 55px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 16px;
  font-style: italic;
  transition: all 500ms ease;
}

#content_block_09 .content-box #contact-form .form-group input:focus,
#content_block_09 .content-box #contact-form .form-group textarea:focus {
}

#content_block_09 .content-box #contact-form .form-group textarea {
  display: block;
  height: 120px;
  resize: none;
  margin-bottom: 4px;
}

#content_block_09 .content-box #contact-form .form-group button {
  display: block;
  width: 100%;
}

.contact-section .image-box {
  position: relative;
  display: block;
  margin: -40px -330px 0px 60px;
}

.contact-section .image-box img {
  width: 100%;
}

/** footer-style-four **/

.footer-style-four {
  position: relative;
  background-color: #020d31;
}

.footer-style-four .pattern-layer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 1326px;
  z-index: -2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.footer-style-four .footer-top {
  position: relative;
  padding: 125px 0px 100px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-style-four .footer-top .widget-section .logo-widget .footer-logo {
  position: relative;
  display: inline-block;
  margin-bottom: 23px;
}

.footer-style-four .footer-top .widget-section .logo-widget .copyright {
  position: relative;
  margin-bottom: 23px;
}

.footer-style-four .footer-top .widget-section .logo-widget .copyright p,
.footer-style-four .footer-top .widget-section .logo-widget .copyright p a {
  color: #aaadb7;
}

.footer-style-four
  .footer-top
  .widget-section
  .logo-widget
  .copyright
  p
  a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer-style-four .footer-top .widget-section .logo-widget .social-links li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
}

.footer-style-four
  .footer-top
  .widget-section
  .logo-widget
  .social-links
  li:last-child {
  margin-right: 0px;
}

.footer-style-four .footer-top .widget-section .logo-widget .social-links li a {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  color: #9197a9;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  text-align: center;
  z-index: 1;
}

.footer-style-four
  .footer-top
  .widget-section
  .logo-widget
  .social-links
  li
  a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.footer-style-four
  .footer-top
  .widget-section
  .logo-widget
  .social-links
  li
  a:hover:before {
  transform: scale(1, 1);
}

.footer-style-four
  .footer-top
  .widget-section
  .logo-widget
  .social-links
  li
  a:hover {
  color: #fff;
}

.footer-style-four .footer-top .widget-section .widget-title {
  position: relative;
  display: block;
  margin-bottom: 24px;
}

.footer-style-four .footer-top .widget-section .widget-title h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
}

.footer-style-four
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  .info-list
  li {
  position: relative;
  display: block;
  padding-left: 27px;
  color: #9197a9;
  margin-bottom: 16px;
}

.footer-style-four
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  .info-list
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-four
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  .info-list
  li
  a {
  position: relative;
  display: inline-block;
  color: #9197a9;
}

.footer-style-four
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  .info-list
  li
  a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer-style-four
  .footer-top
  .widget-section
  .contact-widget
  .widget-content
  .info-list
  li
  i {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;
  font-size: 18px;
}

.footer-style-four
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.footer-style-four
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-four
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a {
  position: relative;
  display: inline-block;
  color: #9197a9;
  padding-left: 20px;
}

.footer-style-four
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  i {
  position: absolute;
  left: 0px;
  top: 5px;
}

.footer-style-four
  .footer-top
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a:hover {
  color: #fff;
}

.footer-style-four .footer-bottom {
  position: relative;
  padding: 22px 0px;
}

/*** 

====================================================================
                          Home-UA
====================================================================

 ***/

/** banner-style-five **/

.banner-style-five {
  position: relative;
  overflow: hidden;
  padding: 400px 0px 235px 0px;
  background: #fff;
}

.banner-style-five .content-box {
  position: relative;
  z-index: 2;
}

.banner-style-five .content-box h1 {
  position: relative;
  font-size: 80px;
  line-height: 85px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.banner-style-five .content-box p {
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 53px;
}

.banner-style-five .content-box a {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
  padding: 0px 0px 0px 90px;
}

.banner-style-five .content-box a i {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 70px;
}

.banner-style-five .content-box a span {
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
}

.banner-style-five .pattern-layer .pattern {
  position: absolute;
}

.banner-style-five .pattern-layer .pattern-1 {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 960px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-style-five .pattern-layer .pattern-2 {
  left: 0px;
  top: 0px;
  width: 550px;
  height: 246px;
  background-repeat: no-repeat;
}

.banner-style-five .pattern-layer .pattern-3 {
  right: 90px;
  bottom: 0px;
  width: 537px;
  height: 268px;
  background-repeat: no-repeat;
}

.banner-style-five .pattern-layer .pattern-4 {
  right: 475px;
  bottom: 0px;
  width: 1265px;
  height: 697px;
  background-repeat: no-repeat;
}

.banner-style-five .pattern-layer .pattern-5 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 1377px;
  height: 438px;
  background-repeat: no-repeat;
}

.banner-style-five .image-layer {
  position: absolute;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0px;
  top: 0px;
  right: 0px;
}

.banner-style-five .image-layer .image-1 {
  position: absolute;
  left: 230px;
  bottom: -30px;
}

.banner-style-five .image-layer .image-2 {
  position: absolute;
  left: 580px;
  bottom: -10px;
}

.banner-style-five .anim-icon {
  z-index: 1;
}

.banner-style-five .anim-icon {
  position: absolute;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0px;
  top: 0px;
  right: 0px;
}

.banner-style-five .anim-icon .icon-1,
.banner-style-five .anim-icon .icon-2 {
  width: 28px;
  height: 37px;
}

.banner-style-five .anim-icon .icon.bg-color {
  border-radius: 50%;
}

.banner-style-five .anim-icon .icon-3 {
  width: 20px;
  height: 20px;
}

.banner-style-five .anim-icon .icon-4 {
  width: 20px;
  height: 20px;
  right: inherit;
  left: 600px;
}

.banner-style-five .anim-icon .icon-5 {
  width: 50px;
  height: 50px;
  right: inherit;
  left: 680px;
}

.banner-style-five .anim-icon .icon-6 {
  width: 28px;
  height: 37px;
  right: inherit;
  left: 650px;
}

.banner-style-five .anim-icon .icon-7 {
  width: 50px;
  height: 50px;
  right: 290px;
}

.banner-style-five .anim-icon .icon-8 {
  width: 30px;
  height: 30px;
  right: 650px;
}

.banner-style-five .anim-icon .icon-9 {
  width: 28px;
  height: 37px;
  right: 530px;
}

.banner-style-five .anim-icon .icon-10 {
  width: 100%;
  height: 105px;
  left: 0px;
  top: 185px;
  background-repeat: no-repeat;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}

.banner-style-five .anim-icon .icon-11 {
  width: 100%;
  height: 97px;
  left: 50%;
  top: 205px;
  background-repeat: no-repeat;
  animation: slide 100s linear infinite;
  -webkit-animation: slide 100s linear infinite;
}

/** welcome-section **/

.welcome-section {
  position: relative;
  padding: 125px 0px 90px 0px;
}

.welcome-section .sec-title {
  margin-bottom: 47px;
}

.welcome-section .welcome-block {
  position: relative;
}

.welcome-section .welcome-block:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 260px;
  top: 12px;
  right: -4px;
}

.welcome-section .welcome-block:last-child:before {
  display: none;
}

.welcome-block-one .inner-box {
  position: relative;
  display: block;
  text-align: center;
  padding: 0px 30px;
  transition: all 500ms ease;
}

.welcome-block-one .inner-box:hover {
  transform: translateY(-30px);
}

.welcome-block-one .inner-box .image-box {
  position: relative;
  width: 200px;
  height: 221px;
  margin: 0 auto;
  margin-bottom: 29px;
}

.welcome-block-one .inner-box .image-box:before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  box-shadow: 0 40px 150px 70px rgba(0, 0, 0, 0.4);
  transition: all 500ms ease;
}

.welcome-block-one .inner-box .image-box .bg-pattern {
  position: absolute;
  left: -4px;
  top: 12px;
  right: 0px;
  width: 200px;
  height: 221px;
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.welcome-block-one .inner-box .image-box .overlay-pattern {
  position: absolute;
  left: -4px;
  top: 12px;
  right: 0px;
  width: 200px;
  height: 221px;
  transform: translateY(30px);
  background-repeat: no-repeat;
  transition: all 500ms ease;
  opacity: 0;
}

.welcome-block-one .inner-box:hover .image-box .overlay-pattern {
  opacity: 1;
  transform: translateY(0px);
}

.welcome-block-one .inner-box .image-box .image img {
  position: relative;
  width: auto;
  z-index: 1;
}

h3 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

h3 a {
  display: inline-block;
  color: #1d165c;
}

.welcome-block-one .inner-box .lower-content h3 {
  margin-bottom: 7px;
}

.welcome-block-one .inner-box .lower-content h3 a:hover {
}

.welcome-block-one .inner-box .lower-content .text {
  position: relative;
  opacity: 0;
  transform: translateY(15px);
  transition: all 500ms ease;
}

.welcome-block-one .inner-box:hover .lower-content .text {
  opacity: 1;
  transform: translateY(0px);
}

/** about-style-five **/

.about-style-five {
  position: relative;
  padding: 120px 0px;
}

.about-style-five .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 10px;
  width: 100%;
  height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.about-style-five #content_block_10 .content-box {
  margin-top: 6px;
}

#content_block_10 .content-box .text {
  position: relative;
  margin-bottom: 43px;
}

#content_block_10 .content-box .two-column {
  position: relative;
  margin-bottom: 42px;
}

#content_block_10 .content-box .two-column .single-item .icon-box {
  position: relative;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 26px;
}

#content_block_10 .content-box .two-column .single-item h3 {
  margin-bottom: 11px;
}

#content_block_10 .content-box .two-column .single-item p {
  line-height: 28px;
}

#content_block_10 .content-box .sec-title {
  margin-bottom: 31px;
}

#image_block_04 .image-box {
  position: relative;
  display: block;
  margin: 0px 18px;
  z-index: 1;
}

#image_block_04 .image-box .pattern-bg {
  position: absolute;
  left: -20px;
  top: 30px;
  right: 0px;
  width: 570px;
  height: 570px;
  background-repeat: no-repeat;
  z-index: -1;
}

#image_block_04 .image-box .image img {
  position: relative;
  width: 100%;
}

.about-style-five .icon-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
}

.about-style-five .icon-layer .icon {
  position: absolute;
}

.about-style-five .icon-layer .icon-1 {
  top: -220px;
  right: 150px;
}

.about-style-five .icon-layer .icon-2 {
  top: -140px;
  right: 45px;
}

.about-style-five .icon-layer .icon-3 {
  top: -70px;
  right: 150px;
}

/** service-style-four **/

.service-style-four {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.service-style-four .inner-content {
  position: relative;
  margin: 0px -15px;
}

.service-block-four .inner-box {
  position: relative;
  display: block;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
  padding: 30px 30px 40px 30px;
  margin: 30px 15px 50px 15px;
  box-shadow: 0 10px 30px 10px #ededf1;
}

.service-block-four .inner-box .pattern-box .bg-pattern {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 213px;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.service-block-four .inner-box .pattern-box .overlay-pattern {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 213px;
  opacity: 0;
  transform: translateY(-30px);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.service-block-four .inner-box:hover .pattern-box .overlay-pattern {
  opacity: 1;
  transform: translateY(0px);
}

.service-block-four .inner-box .icon-box .icon {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 1;
}

.service-block-four .inner-box .icon-box .icon-1 {
  left: 30px;
  top: 30px;
  width: 15px;
  height: 15px;
}

.service-block-four .inner-box .icon-box .icon-2 {
  left: 47px;
  top: 48px;
  width: 7px;
  height: 7px;
}

.service-block-four .inner-box .icon-box .icon-3 {
  right: 20px;
  top: 120px;
  width: 13px;
  height: 13px;
}

.service-block-four .inner-box .image-box {
  position: relative;
  width: 225px;
  height: 215px;
  margin: 0 auto;
  margin-bottom: 27px;
}

.service-block-four .inner-box .image-box img {
  position: relative;
  margin: 0px;
}

.service-block-four .inner-box h3 {
  margin-bottom: 12px;
}

.service-block-four .inner-box p {
  margin-bottom: 16px;
}

.service-block-four .inner-box .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  color: #1d165c;
}

.service-block-four .inner-box .link a:hover {
  padding-left: 23px;
}

.service-block-four .inner-box .link a i {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0px;
  top: -1px;
  opacity: 0;
  transition: all 500ms ease;
}

.service-block-four .inner-box .link a:hover i {
  opacity: 1;
}

.service-style-four .owl-nav {
  position: relative;
  display: block;
  text-align: center;
}

.service-style-four .owl-nav .owl-prev,
.service-style-four .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #1d165c;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.service-style-four .owl-nav .owl-prev:hover,
.service-style-four .owl-nav .owl-next:hover {
  color: #fff;
}

.service-style-four .owl-nav .owl-prev:before,
.service-style-four .owl-nav .owl-next:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  left: -1px;
  top: -1px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.service-style-four .owl-nav .owl-prev:hover:before,
.service-style-four .owl-nav .owl-next:hover:before {
  transform: scale(1, 1);
}

.service-style-four .anim-icon .icon-1 {
  width: 40px;
  height: 40px;
  left: 60px;
  bottom: 25px;
}

.service-style-four .anim-icon .icon-2 {
  width: 30px;
  height: 30px;
  left: 70px;
  bottom: 130px;
}

.service-style-four .anim-icon .icon-3 {
  width: 20px;
  height: 20px;
  left: 75px;
  bottom: 240px;
}

.service-style-four .anim-icon .icon-4 {
  width: 40px;
  height: 40px;
  right: 150px;
  bottom: 220px;
}

.service-style-four .anim-icon .icon-5 {
  width: 20px;
  height: 20px;
  right: 200px;
  bottom: 500px;
}

.service-style-four .anim-icon .icon-6 {
  width: 15px;
  height: 15px;
  right: 250px;
  top: 50%;
}

.service-style-four .anim-icon .icon-7 {
  width: 20px;
  height: 20px;
  bottom: 95px;
  right: 290px;
}

.service-style-four .anim-icon .icon-8 {
  width: 20px;
  height: 20px;
  bottom: 20px;
  right: 450px;
}

.service-style-four .anim-icon .icon-9 {
  width: 40px;
  height: 40px;
  bottom: 150px;
  right: 560px;
}

.service-style-four .anim-icon .icon-10 {
  left: 33px;
  width: 40px;
  height: 40px;
}

.service-style-four .anim-icon .icon-11 {
  left: 335px;
  width: 20px;
  height: 20px;
}

.service-style-four .anim-icon .icon-12 {
  left: 380px;
  width: 20px;
  height: 20px;
}

.service-style-four .anim-icon .icon-13 {
  left: 580px;
  width: 20px;
  height: 20px;
}

.service-style-four .anim-icon .icon-14 {
  left: 580px;
  width: 20px;
  height: 20px;
}

.service-style-four .anim-icon .icon-15 {
  left: 720px;
  width: 20px;
  height: 20px;
}

.service-style-four .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

/** porcess-style-three **/

.porcess-style-three {
  position: relative;
  padding-top: 125px;
  width: 100%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.porcess-style-three .sec-title {
  margin-bottom: 60px;
}

.work-block-three .inner-box {
  position: relative;
  display: block;
  text-align: center;
}

.work-block-three .inner-box .arrow-box {
  position: absolute;
  top: 32px;
  right: -104px;
  width: 194px;
  height: 56px;
  background-repeat: no-repeat;
}

.work-block-three .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 40px;
  width: 80px;
  height: 80px;
  border: 2px dotted #fff;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 52px;
  transition: all 500ms ease;
}

.work-block-three .inner-box .icon-box:before {
  position: absolute;
  content: '';
  height: 32px;
  border: 1px dashed #fff;
  left: 36px;
  bottom: -45px;
}

.work-block-three .inner-box:hover .icon-box {
  background: #fff;
}

.work-block-three .inner-box .icon-box i {
  position: relative;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #fff, #fff 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: all 500ms ease;
}

.work-block-three .inner-box:hover .icon-box i {
}

.work-block-three .inner-box .count-box {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 65px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 500ms ease;
}

.work-block-three .inner-box:hover .count-box {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
}

.work-block-three .inner-box .count-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 40px;
  height: 40px;
  left: 9px;
  top: 9px;
  border-radius: 50%;
  transition: all 500ms ease;
}

.work-block-three .inner-box:hover .count-box:before {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
}

.work-block-three .inner-box .count-box span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-family: 'Muli', Sans-serif;
  font-weight: 700;
  color: #1d165c;
}

.work-block-three .inner-box .content-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 49px 30px 46px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.work-block-three .inner-box .content-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 70px;
  height: 70px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  top: -20px;
}

.work-block-three .inner-box .content-box h3 {
  margin-bottom: 7px;
}

.porcess-style-three .inner-content {
  position: relative;
}

.porcess-style-three .inner-content .line {
  position: absolute;
  left: 0px;
  top: 157px;
  width: 100%;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

.porcess-style-three .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 860px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

/** subscribe-style-two **/

.subscribe-section.style-two {
  background: #fff;
}

.subscribe-section.style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: -100px;
  right: 0px;
  width: 100%;
  height: 706px;
  background-size: cover;
  background-repeat: no-repeat;
}

.subscribe-section.style-two .inner-box .link-box li a {
  color: #1d165c;
}

.subscribe-section.style-two .inner-box .link-box li a:hover {
}

.subscribe-section.style-two .inner-box .link-box li a i {
}

.subscribe-section.style-two
  .inner-box
  .subscribe-form
  .form-group
  input[type='email'] {
  border-radius: 30px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.subscribe-section.style-two
  .inner-box
  .subscribe-form
  .form-group
  input:focus {
}

.subscribe-section.style-two .inner-box .subscribe-form .form-group button {
  border-radius: 50%;
}

.subscribe-section.style-two .inner-box .link-box li:before {
  background: #d6d6d6;
}

.subscribe-section.style-two
  .inner-box
  .subscribe-form
  .form-group
  .custom-controls-stacked
  .description {
  color: #636363;
}

.subscribe-section.style-two
  .custom-control.material-checkbox
  .material-control-indicator {
  border-color: #cdcdcf;
}

/** case-style-four **/

.case-style-four {
  position: relative;
  padding: 125px 0px;
}

.case-style-four .case-block-two .inner-box .image-box {
  border-radius: 10px;
}

.case-style-four .case-block-two .inner-box .image-box .overlay-layer {
  opacity: 0.9;
}

.case-style-four .case-block-two .inner-box .lower-content {
  padding-top: 29px;
}

.case-style-four .case-block-two .inner-box .lower-content h3 {
  font-size: 24px;
}

.case-style-four .case-block-two .inner-box .lower-content .box p {
  margin-bottom: 11px;
}

.case-style-four .owl-nav {
  position: absolute;
  top: -110px;
  right: -5px;
}

.case-style-four .owl-nav .owl-prev,
.case-style-four .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #1d165c;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.case-style-four .owl-nav .owl-prev:hover,
.case-style-four .owl-nav .owl-next:hover {
  color: #fff;
}

.case-style-four .owl-nav .owl-prev:before,
.case-style-four .owl-nav .owl-next:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  left: -1px;
  top: -1px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.case-style-four .owl-nav .owl-prev:hover:before,
.case-style-four .owl-nav .owl-next:hover:before {
  transform: scale(1, 1);
}

/** team-style-two **/

.team-style-two {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.team-block-two {
  position: relative;
  display: block;
  padding-right: 24px;
}

.team-block-two .inner-box {
  position: relative;
  display: block;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 30px 50px 30px 50px;
  box-shadow: 0 0px 50px rgba(0, 0, 0, 0.1);
}

.team-block-two .inner-box:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 10px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover:before {
  height: 100%;
  top: 0px;
}

.team-block-two .inner-box .image-box {
  position: relative;
  display: block;
  width: 140px;
  height: 140px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.team-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.team-block-two .inner-box .image-box .contact-box {
  position: absolute;
  right: -84px;
  bottom: -58px;
  width: 50px;
  height: 110px;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.team-block-two .inner-box .image-box .contact-box:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  top: 0px;
  right: 0px;
  border-radius: 30px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .contact-box:before {
  width: 100%;
  left: 0px;
}

.team-block-two .inner-box .image-box .contact-box .email {
  position: absolute;
  top: 0px;
  right: 0px;
}

.team-block-two .inner-box .image-box .contact-box .email i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #aeaeae, #aeaeae 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .contact-box .email i {
}

.team-block-two .inner-box .image-box .contact-box .email:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  height: 1px;
  width: 34px;
  left: 8px;
  bottom: 0px;
}

.team-block-two .inner-box .image-box .contact-box .email span {
  position: absolute;
  display: inline-block;
  right: 50px;
  top: 20px;
  font-size: 14px;
  line-height: 24px;
  background: #fff;
  border-radius: 3px;
  padding: 0px 12.5px;
  color: #666;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.team-block-two .inner-box .image-box .contact-box .email:hover span {
  opacity: 1;
  visibility: visible;
  right: 65px;
}

.team-block-two .inner-box .image-box .contact-box .email span:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 12px;
  height: 12px;
  top: 6px;
  right: -3px;
  transform: rotate(45deg);
}

.team-block-two .inner-box .image-box .contact-box .social-links {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.team-block-two .inner-box .image-box .contact-box .social-links .share {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #aeaeae, #aeaeae 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .contact-box .social-links .share {
}

.team-block-two .inner-box .image-box .contact-box .social-links .list {
  position: absolute;
  top: 6px;
  right: 43px;
  width: 130px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 500ms ease;
}

.team-block-two .inner-box .image-box .contact-box .social-links:hover .list {
  visibility: visible;
  opacity: 1;
  right: 58px;
}

.team-block-two .inner-box .image-box .contact-box .social-links .list li {
  position: relative;
  display: inline-block;
  margin-left: 6px;
}

.team-block-two
  .inner-box
  .image-box
  .contact-box
  .social-links
  .list
  li:first-child {
  margin-left: 0px;
}

.team-block-two .inner-box .image-box .contact-box .social-links .list li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #8d8d8d;
  background: #fff;
  border-radius: 50%;
  text-align: center;
}

.team-block-two
  .inner-box
  .image-box
  .contact-box
  .social-links
  .list
  li
  a:hover {
  color: #fff;
}

.team-block-two .inner-box .lower-content {
  padding-top: 13px;
}

.team-block-two .inner-box .lower-content h4 {
  margin-bottom: 0px;
  font-weight: 600;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .lower-content h4,
.team-block-two .inner-box:hover .lower-content h4 a,
.team-block-two .inner-box:hover .lower-content .designation {
  color: #fff;
}

.team-block-two .inner-box .lower-content .designation {
  position: relative;
  display: block;
  line-height: 20px;
  transition: all 500ms ease;
}

.team-style-two .more-btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 50px;
}

.team-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 10px;
  right: 0px;
  width: 100%;
  height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

/** news-style-five **/

.news-section.style-five {
  padding: 125px 0px;
}

.news-section.style-five .owl-nav {
  position: absolute;
  top: -110px;
  right: -5px;
}

.news-section.style-five .owl-nav .owl-prev,
.news-section.style-five .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #1d165c;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.news-section.style-five .owl-nav .owl-prev:hover,
.news-section.style-five .owl-nav .owl-next:hover {
  color: #fff;
}

.news-section.style-five .owl-nav .owl-prev:before,
.news-section.style-five .owl-nav .owl-next:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  left: -1px;
  top: -1px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.news-section.style-five .owl-nav .owl-prev:hover:before,
.news-section.style-five .owl-nav .owl-next:hover:before {
  transform: scale(1, 1);
}

.news-section.style-five .news-block-one .lower-content .link a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  color: #1d165c;
}

.news-section.style-five .news-block-one .lower-content .link a:hover {
  padding-left: 23px;
}

.news-section.style-five .news-block-one .lower-content .link a i {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0px;
  top: -1px;
  opacity: 0;
  transition: all 500ms ease;
}

.news-section.style-five .news-block-one .lower-content .link a:hover i {
  opacity: 1;
}

/** award-section **/

.award-section {
  position: relative;
  padding: 125px 0px;
}

.award-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 906px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.award-section .single-item {
  position: relative;
  display: block;
  background: #fff;
  text-align: center;
  padding: 57px 30px 33px 30px;
  border-radius: 10px;
  margin-top: 10px;
  transition: all 500ms ease;
}

.award-section .single-item:hover {
  transform: translateY(-10px);
}

.award-section .single-item .icon-box {
  position: relative;
  display: inline-block;
  margin-bottom: 39px;
}

.award-section .single-item .icon-box i {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  font-size: 35px;
  color: #fff;
  z-index: 1;
  box-shadow: 0 0 0 8px #ffc0a7;
}

.award-section .owl-item:nth-child(2n + 1) .single-item .icon-box i {
  box-shadow: 0 0 0 8px #b7beef;
}

.award-section .owl-item:nth-child(3n + 1) .single-item .icon-box i {
  box-shadow: 0 0 0 8px #fdd59d;
}

.award-section .owl-item:nth-child(4n + 1) .single-item .icon-box i {
  box-shadow: 0 0 0 8px #8ae7df;
}

.award-section .single-item .icon-box:after {
  position: absolute;
  content: '';
  background: #ffe7de;
  width: 104px;
  height: 104px;
  left: -17px;
  top: -17px;
  border-radius: 50%;
}

.award-section .owl-item:nth-child(2n + 1) .single-item .icon-box:after {
  background: #e4e7ff;
}

.award-section .owl-item:nth-child(3n + 1) .single-item .icon-box:after {
  background: #ffe8c9;
}

.award-section .owl-item:nth-child(4n + 1) .single-item .icon-box:after {
  background: #bef2ee;
}

.award-section .single-item .icon-box:before {
  position: absolute;
  content: '';
  top: -20px;
  right: -60px;
  width: 70px;
  height: 82px;
  background-repeat: no-repeat;
}

.award-section .single-item h4 {
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0px;
}

.award-section .owl-dots {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 50px;
}

.award-section .owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50px;
  background: transparent;
  margin: 0px 8px;
  cursor: pointer;
  transition: all 500ms ease;
}

.award-section .owl-theme .owl-dots .owl-dot.active span,
.award-section .owl-theme .owl-dots .owl-dot span:hover {
  background: #fff;
}

.award-section .image-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.award-section .image-layer .image-1 {
  position: absolute;
  left: 50px;
  top: 80px;
  -webkit-animation: service_hexagon_2 15s infinite linear;
  -moz-animation: service_hexagon_2 15s infinite linear;
  -o-animation: service_hexagon_2 15s infinite linear;
  animation: service_hexagon_2 15s infinite linear;
}

.award-section .image-layer .image-2 {
  position: absolute;
  right: 0px;
  top: 50px;
}

/** contact-style-two **/

.contact-section.style-two {
  position: relative;
  padding: 125px 0px 100px 0px;
}

.contact-section.style-two #content_block_09 .content-box #contact-form:before {
  display: none;
}

.contact-section.style-two
  #content_block_09
  .content-box
  #contact-form
  .form-group
  textarea {
  height: 215px;
}

.contact-section.style-two #content_block_09 .content-box #contact-form {
  padding: 46px 50px 50px 50px;
}

.contact-section.style-two .image-layer {
  position: absolute;
  left: -8px;
  bottom: 195px;
}

/** footer-style-five **/

.footer-style-five {
  position: relative;
}

.footer-style-five .pattern-layer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 761px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  background-size: cover;
}

.footer-style-five .footer-top .widget-section .widget-title {
  position: relative;
  margin-bottom: 22px;
}

.footer-style-five .footer-top .widget-section .widget-title h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
}

.footer-style-five .footer-top {
  position: relative;
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-style-five .footer-top .links-widget .widget-content ul li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.footer-style-five .footer-top .links-widget .widget-content ul li:last-child {
  margin-bottom: 0px;
}

.footer-style-five .footer-top .links-widget .widget-content ul li a {
  position: relative;
  display: inline-block;
  color: #a1a4ae;
  padding-left: 20px;
}

.footer-style-five .footer-top .links-widget .widget-content ul li i {
  position: absolute;
  left: 0px;
  top: 5px;
}

.footer-style-five .footer-top .links-widget .widget-content ul li a:hover {
  color: #fff;
}

.footer-style-five .footer-bottom {
  position: relative;
  padding: 22px 0px;
}

.footer-style-five .footer-top .twitter-widget .widget-content .text p {
  color: #a1a4ae;
}

.footer-style-five .footer-top .twitter-widget .widget-content .text p a:hover {
  color: #fff;
}

.footer-style-five .footer-top .twitter-widget .widget-content .text {
  position: relative;
  margin-bottom: 20px;
}

.footer-style-five .footer-top .twitter-widget .widget-content .box {
  position: relative;
  padding: 0px 0px 0px 40px;
}

.footer-style-five .footer-top .twitter-widget .widget-content .box i {
  position: absolute;
  left: 0px;
  top: 11px;
  font-size: 26px;
  line-height: 26px;
}

.footer-style-five .footer-top .twitter-widget .widget-content .box h5 {
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0px;
}

.footer-style-five .footer-top .twitter-widget .widget-content .box span {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #a1a4ae;
}

.footer-style-five .footer-top .twitter-widget {
  margin-right: 30px;
}

.footer-style-five .footer-top .contact-widget .widget-content .info-list li {
  position: relative;
  display: block;
  padding-left: 27px;
  color: #a1a4ae;
  margin-bottom: 16px;
}

.footer-style-five
  .footer-top
  .contact-widget
  .widget-content
  .info-list
  li:last-child {
  margin-bottom: 0px;
}

.footer-style-five .footer-top .contact-widget .widget-content .info-list li i {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;
  font-size: 18px;
}

.footer-style-five .footer-top .contact-widget .widget-content .info-list li a {
  position: relative;
  display: inline-block;
  color: #a1a4ae;
}

.footer-style-five
  .footer-top
  .contact-widget
  .widget-content
  .info-list
  li
  a:hover {
  color: #fff;
  text-decoration: underline;
}

/** Boxed-Layout **/

.switcher .switch_btn button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  background: transparent;
  cursor: pointer;
  transition: all 500ms ease;
}

.switcher .switch_btn {
  position: absolute;
  right: -50px;
  z-index: -1;
}

.switcher {
  position: fixed;
  top: 400px;
  z-index: 999;
  left: -180px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.switcher .switch_menu {
  background: #aaa;
  position: relative;
  padding: 25px 15px 15px 15px;
  text-align: center;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
}

.switcher .switch_menu h5 {
  position: relative;
  display: block;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.switcher.switcher-show {
  left: 0;
}

.switcher .switch_menu .switch_body .box {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 10px 0px;
}

.switcher .switch_menu .switch_body .box .layout-image {
  width: 150px;
  height: 100px;
  background: #fff;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}

.main_page.active_boxlayout {
  max-width: 1550px;
  width: 100%;
  min-width: 300px;
  margin: 0 auto;
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-position: center;
  overflow-x: hidden;
}

/*** 

====================================================================
                          Home-RTL
====================================================================

 ***/

.main-header.home_rtl .language .lang-dropdown li a {
  text-align: right;
}

.search-popup.home_rtl .search-form fieldset input[type='search'] {
  text-align: right;
}

.main-header.home_rtl .main-menu .navigation > li {
  float: right;
}

.main-header.home_rtl .main-menu .navigation > li:last-child {
  margin-right: 25px !important;
  margin-left: 0px;
}

.main-header.home_rtl .main-menu .navigation > li:first-child {
  margin-left: 25px !important;
  margin-right: 0px;
}

.main-header.home_rtl .main-menu .navigation > li > ul > li > a {
  text-align: right;
}

.main-header.home_rtl .main-menu .navigation li.dropdown .megamenu li {
  text-align: right;
}

.main-header.home_rtl .main-menu .navigation > li > ul > li > ul > li > a {
  text-align: right;
}

.main-header.home_rtl .main-menu .navigation > li > ul > li.dropdown > a:after {
  right: inherit;
  left: 0px;
  content: '\f104';
}

.main-header.home_rtl .main-menu .navigation > li > ul > li > a i,
.main-header.home_rtl .main-menu .navigation > li > ul > li > ul > li > a i {
  display: none !important;
}

.home_rtl.mobile-menu .contact-info,
.home_rtl.mobile-menu .social-links {
  text-align: right;
}

.banner-section.home_rtl .content-box {
  text-align: right;
}

.about-section.home_rtl #content_block_01 .content-box {
  text-align: right;
}

.about-section.home_rtl #content_block_01 .content-box .sec-title {
  text-align: right !important;
}

.about-section.home_rtl
  #content_block_01
  .content-box
  .sec-title.text-left
  .decor {
  float: right;
}

.about-section.home_rtl #content_block_01 .content-box .list-item li {
  padding-left: 0px;
  padding-right: 20px;
}

.about-section.home_rtl #content_block_01 .content-box .list-item li span {
  left: inherit;
  right: 0px;
}

.industries-section.home_rtl .sec-title {
  text-align: right !important;
}

.industries-section.home_rtl .sec-title .decor {
  float: right;
}

.industries-section.home_rtl .content-box .btn-box {
  text-align: right;
}

.industries-section.home_rtl .inner-box span {
  text-align: right;
}

.industries-section.home_rtl .inner-box h4 {
  text-align: right;
}

.chooseus-section.home_rtl #content_block_04 .content-box .single-item {
  text-align: right;
}

.chooseus-section.home_rtl
  #content_block_04
  .content-box
  .single-item:first-child
  .box
  h4:before {
  left: inherit;
  right: 0px;
}

.testimonial-section.home_rtl .sec-title {
  text-align: right !important;
}

.testimonial-section.home_rtl .testimonial-content .inner-box {
  text-align: right;
}

.testimonial-section.home_rtl .owl-nav {
  bottom: 0px;
}

.case-section.home_rtl .case-block-one .inner-box .lower-content {
  text-align: right;
}

.fun-fact.home_rtl .counter-block-one .inner-box {
  text-align: right;
  padding: 0px 85px 0px 0px;
}

.fun-fact.home_rtl .counter-block-one .inner-box .icon-box {
  left: inherit;
  right: 0px;
}

.news-section.home_rtl .news-block-one .inner-box .lower-content {
  text-align: right;
}

.news-section.home_rtl .news-block-one .inner-box .lower-content .title-box {
  padding-left: 0px;
  padding-right: 75px;
}

.news-section.home_rtl
  .news-block-one
  .inner-box
  .lower-content
  .title-box
  .post-date {
  left: inherit;
  right: 0px;
}

.main-footer.home_rtl {
  text-align: right;
}

.main-footer.home_rtl .widget-section .widget-title .decor {
  left: inherit;
  right: 0px;
}

.main-footer.home_rtl .widget-section .contact-widget .widget-content .box li {
  padding-left: 0px;
  padding-right: 30px;
}

.main-footer.home_rtl
  .widget-section
  .contact-widget
  .widget-content
  .box
  li
  i {
  left: inherit;
  right: 0px;
}

.main-footer.home_rtl
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a:hover {
  padding-left: 0px;
  padding-right: 20px;
}

.main-footer.home_rtl
  .widget-section
  .links-widget
  .widget-content
  ul
  li
  a:before {
  left: inherit;
  right: 0px;
}

.main-footer.home_rtl
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  button {
  right: inherit;
  left: 0px;
}

.main-footer.home_rtl
  .widget-section
  .about-widget
  .widget-content
  .subscribe-box
  .form-group
  input[type='email'] {
  text-align: right;
}

/*** 

====================================================================
                          About-Page
====================================================================

***/

/** page-title **/

.page-title {
  position: relative;
  overflow: hidden;
  padding: 210px 0px 110px 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-title.style-two {
  background-color: #110b44;
}

.page-title.style-two .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 420px;
  background-repeat: repeat-x;
  animation: slide 100s linear infinite;
  -webkit-animation: slide 100s linear infinite;
}

.page-title.style-two .pattern-layer .pattern-2 {
  position: absolute;
  right: 0px;
  bottom: -100px;
  width: 860px;
  height: 350px;
  background-repeat: no-repeat;
}

.page-title:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  opacity: 0.9;
}

.page-title.style-two:before {
  display: none;
}

.page-title .content-box h1 {
  position: relative;
  font-size: 48px;
  line-height: 60px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}

.page-title .content-box .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  margin: 0px 3px;
}

.page-title .content-box .bread-crumb li span {
  font-size: 13px;
}

.page-title .content-box .bread-crumb li:last-child {
  padding: 0px;
  margin: 0px;
}

.page-title .content-box .bread-crumb li a {
  color: #fff;
}

.page-title .content-box .bread-crumb li a:hover {
  text-decoration: underline;
}

/** about-style-six **/

.about-style-six {
  position: relative;
  padding: 125px 0px 130px 0px;
}

#image_block_05 .image-box {
  position: relative;
  display: block;
  min-height: 480px;
  margin-top: 6px;
}

#image_block_05 .image-box .image {
  position: absolute;
  border-radius: 5px;
}

#image_block_05 .image-box .image img {
  width: 100%;
  border-radius: 5px;
}

#image_block_05 .image-box .image-1 {
  left: 0px;
  bottom: 0px;
}

#image_block_05 .image-box .image-2 {
  top: 40px;
  right: 0px;
}

#image_block_05 .image-box .image-3 {
  left: 129px;
  top: 0px;
}

#image_block_05 .image-box .pattern-layer {
  position: absolute;
  right: 30px;
  bottom: 7px;
  width: 201px;
  height: 121px;
  background-repeat: no-repeat;
  z-index: -2;
}

#image_block_05 .image-box .elipse {
  position: absolute;
  right: 222px;
  bottom: 82px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

#image_block_05 .image-box .text {
  position: absolute;
  display: inline-block;
  right: 0px;
  bottom: 35px;
  padding-left: 92px;
}

#image_block_05 .image-box .text span {
  position: absolute;
  left: 0px;
  top: -6px;
  font-size: 72px;
  line-height: 75px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
}

#image_block_05 .image-box .text h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
  font-weight: 500;
}

.about-style-six #content_block_11 .content-box {
  margin-left: 100px;
}

#content_block_11 .content-box p {
  margin-bottom: 15px;
}

#content_block_11 .content-box .text p:last-child {
  margin-bottom: 0px;
}

#content_block_11 .content-box .text {
  position: relative;
  margin-bottom: 33px;
}

#content_block_11 .content-box .video-btn {
  position: relative;
  padding: 9px 0px 9px 90px;
}

#content_block_11 .content-box .video-btn a {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 70px;
  line-height: 70px;
  z-index: 1;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#content_block_11 .content-box .video-btn h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
  font-weight: 600;
}

#content_block_11 .content-box .sec-title {
  margin-bottom: 33px;
}

/** history-section **/

.history-section {
  position: relative;
  padding: 290px 0px 120px 0px;
}

.history-section .inner-box .single-item {
  position: relative;
  float: left;
  width: 25%;
}

.history-section .inner-box .single-item .inner {
  position: relative;
  text-align: center;
  padding: 0px 25px;
}

.history-section .inner-box .single-item .inner .content-box {
  position: relative;
  margin-bottom: 86px;
}

.history-section .inner-box .single-item:nth-child(2) .inner .content-box,
.history-section .inner-box .single-item:last-child .inner .content-box {
  margin-bottom: 0px;
}

.history-section .inner-box .single-item .inner .content-box h4 {
  margin-bottom: 2px;
}

.history-section .inner-box .single-item .inner .year span {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #fff;
  border: 2px solid #fff;
  font-size: 18px;
  font-weight: 700;
  color: #1d165c;
  border-radius: 50%;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}

.history-section .inner-box .single-item .inner:hover .year span {
}

.history-section .inner-box .single-item .inner .year span:before {
  position: absolute;
  content: '';
  background: #cfcfcf;
  width: 1px;
  height: 40px;
  left: 50px;
}

.history-section .inner-box .single-item:first-child .inner .year span:before,
.history-section .inner-box .single-item:nth-child(3) .inner .year span:before {
  top: -52px;
}

.history-section .inner-box .single-item:last-child .inner .year span:before,
.history-section .inner-box .single-item:nth-child(2) .inner .year span:before {
  bottom: -52px;
}

.history-section .inner-box .single-item:last-child .inner .year,
.history-section .inner-box .single-item:nth-child(2) .inner .year {
  margin-bottom: 89px;
}

.history-section .inner-box .single-item .inner .year span:after {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 46px;
}

.history-section .inner-box .single-item:first-child .inner .year span:after,
.history-section .inner-box .single-item:nth-child(3) .inner .year span:after {
  top: -65px;
}

.history-section .inner-box .single-item:last-child .inner .year span:after,
.history-section .inner-box .single-item:nth-child(2) .inner .year span:after {
  bottom: -65px;
}

.history-section .inner-box .single-item:first-child {
  padding-top: 45px;
}

.history-section .inner-box .single-item:nth-child(3) {
  margin-top: -266px;
}

.history-section .inner-box .single-item:last-child {
  margin-top: -310px;
}

.history-section .sec-title {
  max-width: 450px;
  width: 100%;
  margin-bottom: 110px;
}

.history-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: -435px;
  right: 0px;
  width: 100%;
  height: 1430px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.history-section .image-layer {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.history-section .inner-box {
  position: relative;
}

.history-section .arrow-box {
  position: absolute;
  left: 40px;
  bottom: 60px;
  right: 0px;
  width: 100%;
  height: 849px;
  background-repeat: no-repeat;
  background-position: center;
}

/** chooseus-style-four **/

.chooseus-style-four {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.chooseus-style-four .image-box {
  position: relative;
  display: block;
  padding-right: 200px;
  margin-top: 6px;
}

.chooseus-style-four .image-box img {
  width: 100%;
  border-radius: 5px;
}

.chooseus-style-four .image-box .image {
  border-radius: 5px;
}

.chooseus-style-four .image-box .image-2 {
  position: absolute;
  right: 30px;
  bottom: -50px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

#content_block_12 .content-box .inner-box .single-item {
  position: relative;
  margin-bottom: 37px;
}

#content_block_12 .content-box .inner-box .single-item:last-child {
  margin-bottom: 0px;
}

#content_block_12 .content-box .inner-box .single-item h4 {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 27px;
}

#content_block_12 .content-box .inner-box .single-item h4:before {
  position: absolute;
  content: '';
  border: 1px solid #e5e5e5;
  width: 55px;
  height: 2px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

#content_block_12 .content-box .inner-box .single-item:hover h4:before {
}

#content_block_12 .content-box .inner-box .single-item .box {
  position: relative;
  padding-left: 73px;
}

#content_block_12 .content-box .inner-box .single-item .box .icon-box {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 55px;
  line-height: 55px;
  color: transparent !important;
  background: -webkit-linear-gradient(-90deg, #aeaeae, #aeaeae 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: all 500ms ease;
}

#content_block_12 .content-box .inner-box .single-item:hover .box .icon-box {
  transform: rotateY(360deg);
}

#content_block_12 .content-box .inner-box .single-item .box p {
  line-height: 28px;
}

#content_block_12 .content-box .sec-title {
  margin-bottom: 46px;
}

.team-section.alternate-2 .team-block-one .inner-box:hover .image-box img {
  opacity: 1;
}

.team-section.alternate-2
  .team-block-one
  .inner-box
  .image-box
  .contact-box:before {
  display: none;
}

.team-section.alternate-2
  .team-block-one
  .inner-box:hover
  .image-box
  .contact-box
  .email
  i,
.team-section.alternate-2
  .team-block-one
  .inner-box:hover
  .image-box
  .contact-box
  .social-links
  .share {
  color: #aeaeae;
}

.team-section.alternate-2
  .team-block-one
  .inner-box
  .image-box
  .contact-box
  .email
  i:hover,
.team-section.alternate-2
  .team-block-one
  .inner-box
  .image-box
  .contact-box
  .social-links
  .share:hover {
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-section.alternate-2 .more-btn {
  margin-top: 49px;
}

/*** 

====================================================================
                          Contact-Page
====================================================================

***/

.info-section.alternate-2 {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.info-section.alternate-2 .inner-container .info-inner {
  position: relative;
  top: 0px;
  border-radius: 5px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.info-section.alternate-2 .inner-container .info-inner .info-box .box h4 {
  color: #1d165c;
}

.info-section.alternate-2 .inner-container .info-inner .info-box .box span {
  color: #666666;
}

.info-section.alternate-2 .inner-container .info-inner .info-box .text p,
.info-section.alternate-2 .inner-container .info-inner .info-box .text p a {
  color: #1d165c;
}

.info-section.alternate-2 .inner-container .info-inner .info-box .hidden-icon {
  color: #f3f3f3;
}

.info-section.alternate-2 .inner-container .info-inner .info-column {
  background: #fff;
  overflow: hidden;
  transition: all 500ms ease;
}

.info-section.alternate-2 .inner-container .info-inner .info-column:hover {
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.contact-section.alternate-2 {
  position: relative;
  padding-bottom: 130px;
}

.contact-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: -225px;
  right: 0px;
  width: 100%;
  height: 940px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

#content_block_09 .form-group .theme-btn.style-one {
  padding: 12px 30px;
}

.google-map-section {
  position: relative;
}

.google-map-section .map-column .google-map-area {
  position: relative;
  display: block;
}

.google-map-section .map-column .google-map-area #contact-google-map {
  position: relative;
  display: block;
  width: 100%;
  height: 500px;
}

/*** 

====================================================================
                          Service-Page
====================================================================

***/

/** service-section-five **/

.service-section-five {
  position: relative;
  padding: 125px 0px 100px 0px;
}

.service-block-five {
  position: relative;
  margin-bottom: 30px;
}

.service-block-five .inner-box {
  position: relative;
  display: block;
  background: #fff;
  overflow: hidden;
  border-radius: 5px;
  padding: 50px 30px 30px 50px;
  box-shadow: 0 5px 40px 10px #eaeaef;
}

.service-block-five .inner-box:before {
  content: '';
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  bottom: -30px;
  right: 8px;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.service-block-five .inner-box:hover:before {
  opacity: 1;
  transform: scale(5);
  transition-delay: 0.15s;
}

.service-block-five .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 65px;
  line-height: 65px;
  margin-bottom: 23px;
  color: transparent !important;
  background: -webkit-linear-gradient(-90deg, #aeaeae, #aeaeae 100%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.service-block-five .inner-box h3 {
  font-size: 24px;
  margin-bottom: 25px;
}

.service-block-five .inner-box .list-item li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  padding-left: 23px;
  margin-bottom: 12px;
}

.service-block-five .inner-box .list-item li span {
  position: absolute;
  left: 0px;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.service-block-five .inner-box .list-item li span:before {
  position: absolute;
  content: '';
  width: 9px;
  height: 9px;
  left: -2px;
  top: -2px;
  border-radius: 50%;
}

.service-block-five .inner-box .list-item li span:after {
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  left: -4px;
  top: -4px;
  border-radius: 50%;
}

.service-block-five .inner-box .image-box {
  position: absolute;
  top: 30px;
  right: -200px;
}

.service-block-five .inner-box .link {
  position: absolute;
  right: 25px;
  bottom: 40px;
}

.service-block-five .inner-box .link a {
  position: relative;
  display: inline-block;
  color: #fff;
  z-index: 2;
  transform: scale(0);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-block-five .inner-box .link .btn-style-four:before {
  background: #fff;
}

.service-block-five .inner-box .link a:hover {
  color: #fff !important;
}

.service-block-five .inner-box:hover .link a {
  transform: scale(1);
  transition-delay: 0.15s;
}

.process-style-two.alternate-2 {
  position: relative;
  padding-top: 0px;
}

.process-style-two.alternate-2:before {
  display: none;
}

.process-style-two.alternate-2 .pattern-layer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 1430px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

/** industries-style-two **/

.industries-style-two {
  position: relative;
  background: #6129ac;
  padding-top: 125px;
}

.industries-style-two .bg-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 814px;
  background-repeat: no-repeat;
  background-position: left center;
}

.service-tab .p-tab {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
}

.service-tab .p-tab.active-tab {
  position: relative;
  visibility: visible;
  opacity: 1;
  z-index: 5;
}

.service-tab .p-tabs-content {
  position: relative;
  display: block;
}

.service-tab .p-tab.active-tab .content-box {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.service-tab .p-tab .content-box {
  position: relative;
  overflow: hidden;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.industries-style-two .tabs-content-inner .content-box .box {
  position: relative;
  padding-left: 94px;
  margin-bottom: 24px;
}

.industries-style-two .tabs-content-inner .content-box .box .icon-box {
  position: absolute;
  left: 0px;
  top: 16px;
  font-size: 65px;
  line-height: 65px;
  color: #fff;
}

.industries-style-two .tabs-content-inner .content-box .box span {
  position: relative;
  font-size: 13px;
  display: block;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.industries-style-two .tabs-content-inner .content-box .box h4 {
  color: #fff;
  line-height: 28px;
  margin: 0px;
}

.industries-style-two .tabs-content-inner .content-box .text {
  position: relative;
  margin-bottom: 43px;
  padding-right: 50px;
}

.industries-style-two .tabs-content-inner .content-box .text p {
  color: #fff;
}

.industries-style-two .tabs-content-inner .content-box .btn-box .theme-btn {
  padding: 9px 21px;
}

.industries-style-two .video-box {
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.industries-style-two .video-box a {
  position: relative;
  display: inline-block;
  font-size: 100px;
  line-height: 100px;
  color: #fff;
}

.industries-style-two .video-box a:hover {
}

.industries-style-two .owl-nav {
  display: none !important;
}

.industries-style-two .owl-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.industries-style-two .owl-theme .owl-dots .owl-dot {
  display: block;
  margin: 10px 0px;
}

.industries-style-two .owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.industries-style-two .owl-theme .owl-dots .owl-dot.active span,
.industries-style-two .owl-theme .owl-dots .owl-dot span:hover {
  border-color: #fff;
}

.industries-style-two .tab-btn-box {
  position: relative;
  display: block;
  margin-top: 80px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.industries-style-two .tab-btn-box .service-tab-btns li {
  position: relative;
  float: left;
  width: 25%;
  padding: 40px 30px 45px 30px;
  cursor: pointer;
  transition: all 500ms ease;
}

.industries-style-two .tab-btn-box .service-tab-btns li:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 2px;
  left: 0px;
  top: -2px;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.industries-style-two .tab-btn-box .service-tab-btns li.active-btn:before,
.industries-style-two .tab-btn-box .service-tab-btns li:hover:before {
  transform: scale(1, 1);
}

.industries-style-two .tab-btn-box .service-tab-btns li .btn-inner {
  position: relative;
  padding-left: 70px;
}

.industries-style-two .tab-btn-box .service-tab-btns li .btn-inner:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 50px;
  top: 0px;
  left: -30px;
}

.industries-style-two
  .tab-btn-box
  .service-tab-btns
  li:first-child
  .btn-inner:before {
  display: none;
}

.industries-style-two .tab-btn-box .service-tab-btns li .btn-inner .icon-box {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 50px;
  line-height: 50px;
  color: #fff;
}

.industries-style-two .tab-btn-box .service-tab-btns li .btn-inner h4 {
  color: #fff;
  line-height: 28px;
  margin: 0px;
}

/** award-style-two **/

.award-style-two {
  position: relative;
  padding: 80px 0px;
}

.award-style-two .content-box {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 67px;
}

.award-style-two .content-box:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 350px;
  top: -17px;
  right: -30px;
}

.award-style-two .content-box h3 {
  font-size: 24px;
  margin-bottom: 31px;
}

.award-style-two .content-box .award-image {
  position: relative;
  display: block;
  margin-bottom: 21px;
}

.award-style-two .testimonial-content {
  position: relative;
  margin: 50px 100px;
}

.award-style-two .testimonial-content .inner-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 48px 30px 45px 50px;
  border-radius: 5px;
  box-shadow: 0 5px 40px 10px #eaeaef;
}

.award-style-two .testimonial-content .inner-box:before {
  position: absolute;
  content: '\f11f';
  font-family: 'Flaticon';
  font-size: 130px;
  line-height: 130px;
  right: 50px;
  bottom: 25px;
  color: #f6f6f6;
}

.award-style-two .testimonial-content .inner-box .rating {
  position: relative;
  margin-bottom: 17px;
}

.award-style-two .testimonial-content .inner-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 20px;
}

.award-style-two .testimonial-content .inner-box p {
  font-size: 20px;
  font-family: 'Josefin Sans', sans-serif;
  line-height: 36px;
  margin-bottom: 50px;
}

.award-style-two .testimonial-content .inner-box .author-info {
  position: relative;
  padding-left: 80px;
}

.award-style-two .testimonial-content .inner-box .author-info .image-box {
  position: absolute;
  left: -20px;
  top: -13px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.award-style-two .testimonial-content .inner-box .author-info .image-box img {
  width: 100%;
  border-radius: 50%;
}

.award-style-two .testimonial-content .inner-box .author-info h4 {
  line-height: 28px;
  margin-bottom: 5px;
}

.award-style-two .testimonial-content .inner-box .author-info .designation {
  position: relative;
  display: block;
  line-height: 24px;
}

.award-style-two .owl-nav {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.award-style-two .owl-nav .owl-prev,
.award-style-two .owl-nav .owl-next {
  position: relative;
  font-size: 20px;
  color: #dddddd;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  margin: 10px 0px;
  transition: all 500ms ease;
}

.award-style-two .owl-nav .owl-prev:hover,
.award-style-two .owl-nav .owl-next:hover {
  color: #fff;
}

/** service-details **/

.service-details {
  position: relative;
  padding: 130px 0px;
}

.service-details-content .inner-box .two-column {
  position: relative;
  display: block;
  margin-bottom: 50px;
}

.service-details-content .inner-box .two-column .left-column .title-box h2 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 50px;
}

.service-details-content
  .inner-box
  .two-column
  .left-column
  .title-box
  .btn-style-four {
  border-radius: 3px;
  padding: 9px 15px 9px 35px;
}

.service-details-content
  .inner-box
  .two-column
  .left-column
  .title-box
  .btn-style-four:before {
  left: 18px;
  top: 18px;
}

.service-details-content .inner-box .two-column .right-column .text p {
  margin-bottom: 23px;
  line-height: 28px;
}

.service-details-content
  .inner-box
  .two-column
  .right-column
  .text
  p:last-child {
  margin-bottom: 0px;
}

.service-details-content .inner-box .single-image {
  position: relative;
  display: block;
  margin-bottom: 67px;
  border-radius: 5px;
}

.service-details-content .inner-box .single-image img {
  width: 100%;
  border-radius: 5px;
}

.service-details-content .inner-box .carousel-box .carousel-inner {
  position: relative;
  margin: 0px -15px;
}

.service-details-content .inner-box .carousel-box .single-item {
  position: relative;
  display: block;
  background: #fff;
  box-shadow: 0 0px 30px 5px #eaeaef;
  margin: 30px 15px;
  border-radius: 5px;
}

.service-details-content .inner-box .carousel-box .single-item .box {
  position: relative;
  padding: 21px 30px 23px 100px;
  border-bottom: 5px solid #f6f6f6;
}

.service-details-content .inner-box .carousel-box .single-item .box .icon-box {
  position: absolute;
  left: 30px;
  top: 24px;
  font-size: 50px;
  line-height: 50px;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-details-content .inner-box .carousel-box .single-item .box span {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.service-details-content .inner-box .carousel-box .single-item .inner {
  position: relative;
  padding: 22px 30px 21px 30px;
}

.service-details-content .inner-box .carousel-box .single-item .inner p {
  margin-bottom: 16px;
}

.service-details-content .inner-box .carousel-box .top-box {
  position: relative;
  margin-bottom: 12px;
}

.service-details-content .inner-box h3 {
  position: relative;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 9px;
  font-weight: 600;
}

.service-details-content .inner-box .carousel-box .top-box p {
  line-height: 28px;
}

.service-details-content .inner-box .carousel-box .owl-dots {
  position: relative;
  display: block;
  text-align: center;
  margin-top: -9px;
}

.service-details-content .inner-box .carousel-box .owl-dots .owl-dot span {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #aeaeae;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.service-details-content
  .inner-box
  .carousel-box
  .owl-dots
  .owl-dot.active
  span,
.service-details-content
  .inner-box
  .carousel-box
  .owl-dots
  .owl-dot
  span:hover {
}

.service-details-content .inner-box .carousel-box {
  position: relative;
  margin-bottom: 60px;
}

.service-details-content .inner-box .technology-box .content-box p {
  line-height: 28px;
  margin-bottom: 24px;
  padding-right: 20px;
}

.service-details-content .inner-box .technology-box .content-box .list-item li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  padding-left: 23px;
  margin-bottom: 12px;
}

.service-details-content
  .inner-box
  .technology-box
  .content-box
  .list-item
  li
  span {
  position: absolute;
  left: 0px;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.service-details-content
  .inner-box
  .technology-box
  .content-box
  .list-item
  li
  span:before {
  position: absolute;
  content: '';
  width: 9px;
  height: 9px;
  left: -2px;
  top: -2px;
  border-radius: 50%;
}

.service-details-content
  .inner-box
  .technology-box
  .content-box
  .list-item
  li
  span:after {
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  left: -4px;
  top: -4px;
  border-radius: 50%;
}

.service-details-content .inner-box .technology-box .icon-box {
  position: relative;
  display: block;
  min-height: 320px;
  margin-top: 13px;
}

.service-details-content .inner-box .technology-box .icon-box .icon {
  position: absolute;
  width: 170px;
  height: 170px;
  text-align: center;
  border-radius: 5px;
  padding: 33px 15px 12px 15px;
}

.service-details-content .inner-box .technology-box .icon-box .icon-1 {
  left: 0px;
  top: 0px;
  z-index: 1;
  box-shadow: 0 20px 40px rgba(97, 41, 172, 0.5);
}

.service-details-content .inner-box .technology-box .icon-box .icon-2 {
  right: 0px;
  top: 20px;
  z-index: 1;
  box-shadow: 0 20px 40px rgba(253, 48, 122, 0.5);
}

.service-details-content .inner-box .technology-box .icon-box .icon-3 {
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  box-shadow: 0 20px 40px rgba(251, 81, 85, 0.5);
}

.service-details-content .inner-box .technology-box .icon-box .icon i {
  position: relative;
  font-size: 65px;
  line-height: 65px;
  color: #fff;
  margin-bottom: 35px;
}

.service-details-content .inner-box .technology-box .icon-box .icon h4 {
  color: #fff;
}

.service-details-content .inner-box .technology-box {
  position: relative;
  margin-bottom: 66px;
}

.service-details-content .inner-box .application-box .inner {
  position: relative;
  margin: 0px -15px;
}

.service-details-content .inner-box .application-box .single-item {
  position: relative;
  float: left;
  width: 170px;
  margin: 0px 15px;
}

.service-details-content .inner-box .application-box p {
  margin-bottom: 32px;
  line-height: 28px;
}

.service-details-content .inner-box .application-box .single-item .box {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 158px;
  border: 1px solid #e5e5e5;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  padding: 31px 15px 20px 15px;
  transition: all 500ms ease;
}

.service-details-content .inner-box .application-box .single-item .box:hover {
  box-shadow: 0 20px 40px rgba(251, 81, 85, 0.4);
}

.service-details-content .inner-box .application-box .single-item .box:before {
  position: absolute;
  content: '';
  left: -1px;
  top: -1px;
  width: 170px;
  height: 0px;
  border-radius: 3px;
  transition: all 500ms ease;
}

.service-details-content
  .inner-box
  .application-box
  .single-item
  .box:hover:before {
  height: 158px;
}

.service-details-content .inner-box .application-box .single-item .box:after {
  position: absolute;
  content: '';
  background: #f6f6f6;
  width: 150px;
  height: 50px;
  left: 9px;
  bottom: -11px;
  right: 0px;
  border-radius: 5px;
  z-index: -1;
  transition: all 500ms ease;
}

.service-details-content .inner-box .application-box .single-item .box i {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 60px;
  color: #aeaeae;
  margin-bottom: 15px;
  transition: all 500ms ease;
}

.service-details-content .inner-box .application-box .single-item .box h4 {
  transition: all 500ms ease;
  font-weight: 600;
}

.service-details-content .inner-box .application-box .single-item .box:hover i,
.service-details-content
  .inner-box
  .application-box
  .single-item
  .box:hover
  h4 {
  color: #fff;
}

.service-details-content .inner-box .application-box {
  position: relative;
  margin-bottom: 77px;
}

.service-details-content .inner-box .recent-case h3 {
  margin-bottom: 28px;
}

.contact-section.alternate-3 {
  position: relative;
  padding: 125px 0px 130px 0px;
}

.contact-section.alternate-3 .pattern-layer {
  top: 0px;
}

.service-sidebar {
  position: relative;
  margin-left: 30px;
}

.service-sidebar .sidebar-categories {
  position: relative;
  display: block;
  background: #fff;
  box-shadow: 0 5px 40px 10px #eaeaef;
  margin-bottom: 50px;
}

.service-sidebar .sidebar-categories li {
  position: relative;
  display: block;
  padding: 12px 30px 12px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.service-sidebar .sidebar-categories li:last-child {
  border-bottom: none;
}

.service-sidebar .sidebar-categories li a h5 {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  transition: all 500ms ease;
}

.service-sidebar .sidebar-categories li a {
  position: relative;
  display: block;
  padding: 13px 10px 9px 30px;
}

.service-sidebar .sidebar-categories li a i {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.service-sidebar .sidebar-categories li a.active i,
.service-sidebar .sidebar-categories li a:hover i {
  opacity: 1;
}

.service-sidebar .sidebar-categories li a:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  top: 0px;
  right: 0px;
  transition: all 500ms ease;
}

.service-sidebar .sidebar-categories li a.active:before,
.service-sidebar .sidebar-categories li a:hover:before {
  width: 100%;
  left: 0px;
}

.service-sidebar .sidebar-categories li a.active h5,
.service-sidebar .sidebar-categories li a:hover h5 {
  color: #fff;
}

.service-sidebar .sidebar-file .download-option {
  position: relative;
  border: 1px solid #e5e5e5;
  box-shadow: 0 30px 30px 5px #eaeaef;
  margin-bottom: 50px;
}

.service-sidebar .sidebar-file .download-option li {
  position: relative;
  display: block;
  border-bottom: 1px solid #e5e5e5;
  padding: 17px 80px 13px 20px;
}

.service-sidebar .sidebar-file .download-option li:last-child {
  border-bottom: none;
}

.service-sidebar .sidebar-file .download-option li .icon-box {
  position: absolute;
  top: 13px;
  right: 9px;
  padding-left: 10px;
}

.service-sidebar .sidebar-file .download-option li .icon-box:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 37px;
  top: 5px;
  left: 0px;
}

.service-sidebar .sidebar-file .download-option li .icon-box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #b5b5b5;
  padding: 10px;
  z-index: 1;
}

.service-sidebar .sidebar-file .download-option li .icon-box a:hover {
}

.service-sidebar .sidebar-file .download-option li .box {
  position: relative;
  padding-left: 48px;
}

.service-sidebar .sidebar-file .download-option li .box .image {
  position: absolute;
  left: 0px;
  top: 3px;
}

.service-sidebar .sidebar-file .download-option li .box h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.service-sidebar .sidebar-file .download-option li .box span {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 20px;
}

.service-sidebar .sidebar-award {
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 38px 20px 32px 20px;
  margin-bottom: 50px;
}

.service-sidebar .sidebar-award h3 {
  font-size: 24px;
  margin-bottom: 31px;
}

.service-sidebar .sidebar-award .award-image {
  position: relative;
  margin-bottom: 21px;
}

.service-sidebar .sidebar-testimonial {
  position: relative;
  display: block;
  border: 1px solid #e5e5e5;
  padding: 39px 40px;
  border-radius: 3px;
}

.service-sidebar .sidebar-testimonial .content-box .upper-box {
  position: relative;
  padding-bottom: 17px;
  border-bottom: 1px solid #e5e5e5;
}

.service-sidebar .sidebar-testimonial .content-box .upper-box .logo-image {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
}

.service-sidebar .sidebar-testimonial .content-box .upper-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 20px;
}

.service-sidebar
  .sidebar-testimonial
  .content-box
  .upper-box
  .rating-box
  .rating {
  position: relative;
  display: inline-block;
  margin-right: 7px;
  padding-right: 16px;
}

.service-sidebar
  .sidebar-testimonial
  .content-box
  .upper-box
  .rating-box
  .rating:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 20px;
  top: 4px;
  right: 0px;
}

.service-sidebar .sidebar-testimonial .content-box .upper-box .rating-box span {
  position: relative;
  display: inline-block;
  font-size: 16px;
}

.service-sidebar .sidebar-testimonial .content-box .text {
  position: relative;
  padding: 22px 0px 17px 0px;
}

.service-sidebar .sidebar-testimonial .content-box .text p {
  font-size: 20px;
  line-height: 36px;
  font-family: 'Josefin Sans', sans-serif;
}

.service-sidebar .sidebar-testimonial .content-box .author-info {
  position: relative;
  padding: 12px 0px 8px 90px;
}

.service-sidebar .sidebar-testimonial .content-box .author-info .author-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.service-sidebar
  .sidebar-testimonial
  .content-box
  .author-info
  .author-image
  img {
  width: 100%;
  border-radius: 50%;
}

.service-sidebar .sidebar-testimonial .content-box .author-info h4 {
  line-height: 26px;
  margin-bottom: 0px;
}

.service-sidebar .sidebar-testimonial .content-box .author-info .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
}

/*** 

====================================================================
                          Project-Page
====================================================================

***/

/** project-two-column **/

.project-two-column {
  position: relative;
  padding: 130px 0px 80px 0px;
}

.project-page .case-block-two {
  margin-bottom: 43px;
}

.project-page .case-block-two .inner-box .lower-content .box h3 {
  font-size: 24px;
  line-height: 32px;
}

.project-page .case-block-two .inner-box .lower-content .box h3 a:hover {
}

.project-two-column .case-block-two .inner-box .lower-content {
  padding-top: 29px;
}

.project-two-column .case-block-two .inner-box .lower-content .box p {
  margin-bottom: 10px;
}

/** project-three-column **/

.project-three-column {
  position: relative;
  padding: 140px 0px 130px 0px;
}

.project-page .filters {
  position: relative;
  margin-bottom: 53px;
}

.project-page .filter-btns li {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #1d165c;
  margin-right: 32px;
  transition: all 500ms ease;
  cursor: pointer;
}

.project-page .filter-btns li:last-child {
  margin-right: 0px;
}

.project-page .filter-btns li.active,
.project-page .filter-btns li:hover {
}

.project-page .filter-btns li span {
  position: absolute;
  top: -20px;
  right: -23px;
  font-size: 14px;
  opacity: 0;
  transition: all 500ms ease;
}

.project-page .filter-btns li.active span,
.project-page .filter-btns li:hover span {
  opacity: 1;
}

.project-three-column .pagination-wrapper {
  position: relative;
  display: block;
  border-top: 1px solid #e5e5e5;
  padding-top: 50px;
  margin-top: 10px;
}

.project-page.case-style-three
  .case-block-two
  .inner-box
  .lower-content
  .link
  a:hover {
  padding-left: 0px;
  color: #1d165c !important;
}

.project-page.case-style-three
  .case-block-two
  .inner-box
  .lower-content
  .link
  a:hover:before {
  opacity: 0;
}

.project-page.case-style-three
  .case-block-two
  .inner-box
  .lower-content
  .link
  .btn-style-four
  span {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  background: #fff;
}

.project-page.case-style-three
  .case-block-two
  .inner-box
  .lower-content
  .link
  .btn-style-four:before {
  top: 8px;
}

.project-page.case-style-three
  .case-block-two
  .inner-box
  .lower-content
  .link
  .btn-style-four:hover
  span {
  color: #fff;
}

.case-studies .case-block-two {
  margin-bottom: 40px;
}

.case-studies {
  padding: 140px 0px 130px 0px;
}

.case-studies .pagination-wrapper {
  position: relative;
  display: block;
  margin-top: 20px;
}

.page-title .content-box .icon-box {
  position: relative;
  display: inline-block;
  background: #fff;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 8px;
}

.page-title .content-box h3 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 28px;
}

.page-title .content-box h2 {
  position: relative;
  font-size: 36px;
  line-height: 46px;
  color: #fff;
  font-weight: 700;
}

/** project-details **/

.project-details {
  position: relative;
}

.project-details .project-details-content {
  padding: 125px 0px;
}

.project-details-content .data-box .title-box span {
  position: relative;
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
}

.project-details-content h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 19px;
}

.project-details-content .data-box .title-box {
  position: relative;
  margin-bottom: 50px;
}

.project-details-content .data-box .intro-box .single-item {
  position: relative;
  display: block;
  margin-bottom: 43px;
}

.project-details-content .data-box .intro-box .single-item:last-child {
  margin-bottom: 0px;
}

.project-details-content .data-box .intro-box .single-item .right-column {
  position: relative;
  float: left;
  max-width: 460px;
  width: 100%;
  margin-top: -5px;
}

.project-details-content .data-box .intro-box .single-item .left-column {
  position: relative;
  float: left;
  max-width: 300px;
  width: 100%;
  padding-left: 42px;
}

.project-details-content .data-box .intro-box .single-item .left-column:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 60px;
  top: -7px;
  right: 60px;
}

.project-details-content
  .data-box
  .intro-box
  .single-item
  .left-column
  .icon-box {
  position: absolute;
  left: 0px;
  top: 10px;
  font-size: 24px;
}

.project-details-content .data-box .intro-box .single-item .left-column span {
  position: relative;
  display: block;
  font-size: 13px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px;
}

.project-details-content .data-box .intro-box {
  position: relative;
  margin-bottom: 55px;
}

.project-details-content .single-image {
  position: relative;
  display: block;
  margin-bottom: 55px;
}

.project-details-content .processing-system .title-box h3 {
  margin-bottom: 10px;
}

.project-details-content .single-image img {
  width: 100%;
}

.project-details-content .processing-system .tab-btn-box {
  position: relative;
  display: block;
  margin-bottom: 67px;
  border-top: 1px solid #e5e5e5;
}

.project-details-content .processing-system .tab-btn-box:before {
  position: absolute;
  content: '';
  background: #fff;
  border: 1px solid #e5e5e5;
  width: 9px;
  height: 9px;
  left: 0px;
  top: -5px;
  border-radius: 50%;
}

.project-details-content .processing-system .tab-btn-box:after {
  position: absolute;
  content: '';
  background: #fff;
  border: 1px solid #e5e5e5;
  width: 9px;
  height: 9px;
  right: 0px;
  top: -5px;
  border-radius: 50%;
}

.project-details-content .processing-system .tab-btn-box .tab-btns {
  position: absolute;
  left: 0px;
  top: -27px;
  width: 100%;
}

.project-details-content .processing-system .tab-btn-box .tab-btns li {
  position: relative;
  float: left;
  width: 25%;
  text-align: center;
}

.project-details-content .processing-system .tab-btn-box .tab-btns li span {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  background: #fff;
  padding: 14px 31.5px;
  cursor: pointer;
  transition: all 500ms ease;
  border-radius: 5px;
  box-shadow: 10px 5px 40px 10px #eaeaef;
}

.project-details-content
  .processing-system
  .tab-btn-box
  .tab-btns
  li.active-btn
  span,
.project-details-content
  .processing-system
  .tab-btn-box
  .tab-btns
  li
  span:hover {
  color: #fff;
}

.project-details-content
  .processing-system
  .tab-btn-box
  .tab-btns
  li
  span:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 15px;
  height: 15px;
  top: 19px;
  right: -6px;
  transform: rotate(45deg);
  transition: all 500ms ease;
}

.project-details-content
  .processing-system
  .tab-btn-box
  .tab-btns
  li.active-btn
  span:before,
.project-details-content
  .processing-system
  .tab-btn-box
  .tab-btns
  li
  span:hover:before {
}

.project-details-content .processing-system .title-box {
  position: relative;
  margin-bottom: 60px;
}

.project-details-content .processing-system .tabs-content .content-box .text {
  position: relative;
  padding: 0px 0px 0px 300px;
}

.project-details-content
  .processing-system
  .tabs-content
  .content-box
  .image-box {
  position: relative;
  display: block;
  margin-bottom: 67px;
  margin-right: 70px;
}

.project-details-content
  .processing-system
  .tabs-content
  .content-box
  .image-box
  img {
  width: 100%;
}

.project-details-content
  .processing-system
  .tabs-content
  .content-box
  .text
  h3 {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.project-details-content .processing-system {
  position: relative;
  padding-bottom: 53px;
  margin-bottom: 55px;
  border-bottom: 1px solid #e5e5e5;
}

.project-details-content .result-box h3 {
  margin-bottom: 10px;
}

.project-sidebar {
  position: relative;
  margin-top: -60px;
  margin-left: 30px;
}

.project-sidebar .info-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 30px 40px 30px 40px;
  border-radius: 5px;
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
}

.project-sidebar .info-box:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 3px;
  right: 0px;
  border-radius: 5px;
  z-index: -1;
}

.project-sidebar .info-box .single-item {
  position: relative;
  display: block;
  padding: 26px 0px 23px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.project-sidebar .info-box .single-item:last-child {
  border-bottom: none;
}

.project-sidebar .info-box .single-item .box {
  position: relative;
  padding-left: 20px;
  margin-bottom: 7px;
}

.project-sidebar .info-box .single-item .box .icon-box {
  position: absolute;
  left: 0px;
  top: -4px;
  font-size: 14px;
  line-height: 24px;
  color: #555;
}

.project-sidebar .info-box .single-item .box span {
  position: relative;
  display: block;
  font-size: 13px;
  font-family: 'Josefin Sans', sans-serif;
  color: #1d165c;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px;
}

.project-sidebar .info-box .single-item .text a {
  color: #666;
}

.project-sidebar .info-box .single-item .text a:hover {
}

.nav-btn-box {
  position: relative;
  padding: 100px 0px;
  background: #f6f6f6;
}

.nav-btn-box .btn-inner .prev-btn,
.nav-btn-box .btn-inner .next-btn {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.nav-btn-box .btn-inner .prev-btn h4,
.nav-btn-box .btn-inner .next-btn h4 {
  font-weight: 600;
}

.nav-btn-box .btn-inner .box {
  position: relative;
}

.nav-btn-box .btn-inner .box .icon-box {
  position: absolute;
  top: -1px;
  background: #fff;
  width: 72px;
  height: 72px;
  text-align: center;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 0px 30px 10px #eaeaef;
}

.nav-btn-box .btn-inner .btn-left .box .icon-box {
  left: 0px;
}

.nav-btn-box .btn-inner .btn-right .box .icon-box {
  right: 0px;
}

.nav-btn-box .btn-inner .btn-left .box {
  padding-left: 90px;
}

.nav-btn-box .btn-inner .box h3 {
  font-weight: 600;
}

.nav-btn-box .btn-inner .btn-right .box {
  padding-right: 90px;
}

.nav-btn-box .btn-inner .box span {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.nav-btn-box .btn-inner {
  position: relative;
}

.nav-btn-box .btn-inner:before {
  position: absolute;
  content: '';
  background: #d5d5d5;
  width: 1px;
  height: 117px;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
}

/*** 

====================================================================
                          Blog-Page
====================================================================

***/

/** blog-classic-grid **/

.blog-classic-grid {
  position: relative;
  padding: 130px 0px;
}

.blog-classic-grid .news-block-one {
  margin-bottom: 40px;
}

.blog-classic-grid .news-block-one .btn-style-four {
  padding-left: 73px;
}

.blog-classic-grid .news-block-one .btn-style-four:before {
  background: #1d165c;
  height: 1px;
  width: 54px;
  top: 13px;
}

.blog-classic-grid .pagination-wrapper {
  position: relative;
  display: block;
  margin-top: 20px;
}

.sidebar-page-container {
  position: relative;
  padding: 130px 0px;
}

.blog-classic-sidebar .news-block-one {
  margin-bottom: 40px;
}

.blog-classic-sidebar .news-block-one .lower-content .btn-style-four span {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  background: #fff;
}

.blog-classic-sidebar .news-block-one .lower-content .btn-style-four:before {
  top: 8px;
}

.blog-classic-sidebar .news-block-one .lower-content .btn-style-four:hover {
  padding-left: 0px;
  color: #1d165c !important;
}

.blog-classic-sidebar
  .news-block-one
  .lower-content
  .btn-style-four:hover:before {
  opacity: 0;
}

.blog-classic-sidebar
  .news-block-one
  .lower-content
  .btn-style-four:hover
  span {
  color: #fff;
}

.sidebar-page-container .sidebar .widget-title {
  position: relative;
  margin-bottom: 20px;
}

.sidebar-page-container .sidebar .widget-title h3 {
  font-weight: 600;
}

.sidebar-page-container .sidebar .sidebar-search .form-group {
  position: relative;
  margin: 0px;
}

.sidebar-page-container
  .sidebar
  .sidebar-search
  .form-group
  input[type='search'] {
  position: relative;
  width: calc(100% - 100px);
  height: 54px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;
  transition: all 500ms ease;
}

.sidebar-page-container .sidebar .sidebar-search .form-group input:focus {
}

.sidebar-page-container .sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 55px;
}

.sidebar-page-container .sidebar .sidebar-search .form-group button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px 20px;
}

.sidebar-page-container .sidebar .sidebar-categories .categories-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li:last-child {
  margin-bottom: 0px;
}

.sidebar-page-container .sidebar .sidebar-categories .categories-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Muli', sans-serif;
  color: #666;
  padding-left: 28px;
}

.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li
  a:hover,
.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li
  a.active {
  color: #1d165c;
}

.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li
  a:before {
  position: absolute;
  content: '';
  background: transparent;
  border: 1px solid #d1d1d1;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  left: 0px;
  top: 10px;
  transition: all 500ms ease;
}

.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li
  a:hover:before,
.sidebar-page-container
  .sidebar
  .sidebar-categories
  .categories-list
  li
  a.active:before {
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post {
  position: relative;
  padding: 0px 0px 0px 75px;
  padding-bottom: 30px;
  margin-bottom: 36px;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 54px;
  height: 54px;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}

.sidebar-page-container
  .sidebar
  .sidebar-post
  .post-inner
  .post
  .post-date
  span {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box {
  position: relative;
  margin-bottom: 9px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  margin-right: 10px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box p {
  position: relative;
  display: inline-block;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #1d165c;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5 a {
  display: inline-block;
  color: #1d165c;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5 a:hover {
}

.sidebar-page-container .sidebar .sidebar-post {
  margin-bottom: 19px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list {
  position: relative;
  margin: 0px -5px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li {
  position: relative;
  float: left;
  margin: 0px 5px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li:first-child {
  width: 140px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li:last-child {
  width: 220px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li
  .image {
  position: relative;
  float: left;
  border-radius: 3px;
  margin-bottom: 10px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li:last-child
  .image:last-child {
  margin-left: 10px;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li
  .image
  img {
  width: 100%;
  border-radius: 3px;
  transition: all 500ms ease;
}

.sidebar-page-container
  .sidebar
  .sidebar-instagram
  .widget-content
  .image-list
  li
  .image:hover
  img {
  opacity: 0.2;
}

.sidebar-page-container .sidebar .sidebar-instagram .widget-title {
  margin-bottom: 30px;
}

.sidebar-page-container .sidebar .sidebar-instagram {
  margin-bottom: 47px;
}

.sidebar-page-container .sidebar .sidebar-subscribe .widget-content p {
  margin-bottom: 23px;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group {
  position: relative;
  display: block;
  margin: 0px;
  height: 54px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  input[type='email'] {
  position: relative;
  max-width: 235px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-style: italic;
  padding: 10px 20px 10px 38px;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  i {
  position: absolute;
  left: 19px;
  top: 20px;
  font-size: 14px;
  z-index: 1;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  button
  i {
  position: relative;
  left: 0px;
  top: 0px;
  margin-right: 8px;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  button {
  position: absolute;
  top: 14px;
  right: 18px;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  color: #1d165c;
  background: transparent;
  cursor: pointer;
  transition: all 500ms ease;
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  input:focus
  + button,
.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  button:hover {
}

.sidebar-page-container
  .sidebar
  .sidebar-subscribe
  .subscribe-form
  .form-group
  button:before {
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 54px;
  left: -20px;
  top: -15px;
}

.sidebar-page-container .sidebar .sidebar-subscribe .widget-title {
  margin-bottom: 12px;
}

.sidebar-page-container .sidebar .sidebar-tags .tags-list li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 15px;
}

.sidebar-page-container .sidebar .sidebar-tags .tags-list li a {
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  font-family: 'Muli', sans-serif;
}

.sidebar-page-container .sidebar .sidebar-tags .tags-list li a span {
  font-size: 24px;
}

.sidebar-page-container .sidebar .sidebar-tags .tags-list li a:hover {
}

.sidebar-page-container .sidebar .sidebar-tags .widget-title {
  margin-bottom: 25px;
}

.sidebar-page-container .sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}

.blog-classic-sidebar {
  padding: 130px 0px 90px 0px;
}

.blog-modern-sidebar .news-block-one {
  margin-bottom: 70px;
}

.blog-modern-sidebar .blog-modern-content {
  position: relative;
  margin-right: 30px;
}

.blog-modern-sidebar .news-block-one .inner-box .lower-content {
  padding: 36px 30px 0px 40px;
}

.blog-modern-sidebar
  .news-block-one
  .inner-box
  .lower-content
  .title-box
  .post-date {
  top: 4px;
}

.blog-modern-sidebar .news-block-one .inner-box .lower-content .title-box h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.blog-modern-sidebar .news-block-one .inner-box .lower-content .file-box {
  margin-bottom: 5px;
}

.blog-modern-sidebar .news-block-one .inner-box .lower-content .title-box {
  margin-bottom: 18px;
}

.news-block-two .inner-box {
  position: relative;
  margin-bottom: 30px;
}

.news-block-two .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
}

.news-block-two .inner-box .image-box:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  top: 0px;
  right: 0px;
  opacity: 0.9;
  z-index: 1;
  transition: all 500ms ease;
}

.news-block-two .inner-box:hover .image-box:before {
  width: 100%;
  left: 0px;
}

.news-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.news-block-two .image-box a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  z-index: 1;
  opacity: 0;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.news-block-two .inner-box:hover .image-box a {
  opacity: 1;
}

.news-block-two .image-box .btn-box {
  position: absolute;
  left: 40px;
  bottom: 10px;
}

.news-block-two .image-box .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Josefin Sans', sans-serif;
  background: #fff;
  font-weight: 600;
  color: #1d165c;
  border-radius: 5px;
  padding: 11.5px 24px 11.5px 21px;
}

.news-block-two .image-box .btn-box a span {
  position: relative;
  display: inline-block;
  font-size: 25px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  margin-left: 12px;
}

.blog-masonry {
  position: relative;
  padding: 130px 0px;
}

.blog-masonry .news-block-one .lower-content .btn-style-four span {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  background: #fff;
}

.blog-masonry .news-block-one .lower-content .btn-style-four:before {
  top: 8px;
}

.blog-masonry .news-block-one .lower-content .btn-style-four:hover {
  padding-left: 0px;
  color: #1d165c !important;
}

.blog-masonry .news-block-one .lower-content .btn-style-four:hover:before {
  opacity: 0;
}

.blog-masonry .news-block-one .lower-content .btn-style-four:hover span {
  color: #fff;
}

.blog-masonry .news-block-one {
  margin-bottom: 50px;
}

.news-block-three .lower-content {
  position: relative;
  background: #fff;
  padding: 30px 30px !important;
  border-radius: 5px;
  box-shadow: 0 0px 40px 10px #eaeaef;
}

.blog-masonry .more-btn {
  position: relative;
  display: block;
  text-align: center;
}

.blog-masonry .more-btn .theme-btn.style-three {
  padding: 7px 20px 7px 35px;
}

.blog-masonry .more-btn .theme-btn.style-three:after {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  left: 20px;
  top: 20px;
  transition: all 500ms ease;
}

.blog-masonry .more-btn .theme-btn.style-three:hover:after {
  background: #fff;
}

.page-title .content-box .file-box {
  position: relative;
  display: block;
  margin-bottom: 23px;
  margin-top: 29px;
}

.page-title .content-box .file-box i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}

.page-title .content-box .file-box p {
  color: #fff;
  display: inline-block;
  font-weight: 700;
}

.page-title .content-box .info-box {
  position: relative;
  display: block;
  margin-top: 36px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.page-title .content-box .info-box li {
  position: relative;
  float: left;
  width: 33.333%;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  padding-top: 26px;
  padding-bottom: 28px;
  color: #fff;
  font-weight: 500;
}

.page-title .content-box .info-box li:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 60px;
  top: 0px;
  right: 0px;
}

.page-title .content-box .info-box li:last-child:before {
  display: none;
}

.page-title .content-box .info-box li span {
  font-size: 17px;
  font-weight: 600;
}

.page-title .content-box .info-box li i {
  margin-right: 10px;
}

.blog-details-content {
  position: relative;
  margin-right: 30px;
}

.blog-details-content .inner-box .text p {
  margin-bottom: 25px;
}

.blog-details-content .inner-box .text p:last-child {
  margin-bottom: 0px;
}

.blog-details-content .inner-box .text {
  position: relative;
  display: block;
  margin-bottom: 43px;
}

.blog-details-content .inner-box .image-box {
  position: relative;
  display: block;
  margin-bottom: 42px;
  border-radius: 5px;
}

.blog-details-content .inner-box .image-box img {
  width: 100%;
  border-radius: 5px;
}

.blog-details-content .inner-box h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 19px;
}

.blog-details-content .inner-box .text blockquote {
  position: relative;
  display: block;
  border-radius: 5px;
  text-align: center;
  padding: 47px 45px 42px 50px;
  margin: 43px 0px 42px 0px;
}

.blog-details-content .inner-box .text blockquote h4 {
  color: #fff;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 22px;
}

.blog-details-content .inner-box .text blockquote span {
  position: relative;
  display: block;
  font-size: 14px;
  color: #fff;
}

.blog-details-content .inner-box .text blockquote i {
  position: absolute;
  left: 30px;
  top: 25px;
  font-size: 100px;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.05);
}

.blog-details-content .inner-box .evaluation-box .image-box {
  position: relative;
  display: block;
  border-radius: 5px;
  box-shadow: 0 0px 40px 10px #eaeaef;
  margin-bottom: 0px;
}

.blog-details-content .inner-box .evaluation-box .image-box img {
  width: 100%;
  border-radius: 5px;
}

.blog-details-content .inner-box .evaluation-box h3 {
  margin-bottom: 38px;
}

.blog-details-content .inner-box .evaluation-box {
  position: relative;
  padding-top: 7px;
  margin-bottom: 57px;
}

.blog-details-content
  .inner-box
  .accordion-box
  .block
  .acc-btn.active
  .icon-outer {
}

.blog-details-content .inner-box .accordion-box .block .acc-btn .icon-outer {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 14px;
  color: #b8b8b8;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
  z-index: 1;
}

.blog-details-content
  .inner-box
  .accordion-box
  .block
  .acc-btn.active
  .icon-outer
  i:before {
  position: relative;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  margin: 0px;
  content: '\f00d';
}

.blog-details-content .inner-box .accordion-box .accordion {
  position: relative;
  margin-bottom: 20px;
}

.blog-details-content .inner-box .accordion-box .accordion:last-child {
  margin-bottom: 0px;
}

.blog-details-content .inner-box .accordion-box .block .acc-btn h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin: 0px;
  cursor: pointer;
  box-shadow: 0 0px 40px 10px #eaeaef;
  padding: 20px 60px 18px 30px;
  transition: all 500ms ease;
}

.blog-details-content .inner-box .accordion-box .acc-content {
  padding: 33px 15px 14px 30px;
}

.blog-details-content .inner-box .accordion-block .title-inner {
  position: relative;
  margin-bottom: 34px;
}

.blog-details-content .inner-box {
  position: relative;
  margin-bottom: 54px;
}

.blog-details-content .post-share-option .post-tags {
  position: relative;
  display: block;
  padding-bottom: 45px;
  border-bottom: 1px solid #e5e5e5;
}

.blog-details-content .post-share-option .post-tags .tags-list li {
  position: relative;
  display: inline-block;
  font-size: 16px;
}

.blog-details-content .post-share-option .post-tags .tags-list li a {
  font-size: 16px;
  display: inline-block;
  position: relative;
  color: #666;
}

.blog-details-content .post-share-option .post-tags .tags-list li a:hover {
}

.blog-details-content .post-share-option .post-tags .tags-list li i {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  font-size: 14px;
}

.blog-details-content .post-share-option .post-tags .tags-list li h5 {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
}

.blog-details-content .post-share-option .post-social {
  position: relative;
  display: block;
  padding: 39px 0px 40px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.blog-details-content .post-share-option .post-social h5 {
  font-size: 18px;
  border-left-width: 400;
  margin-bottom: 24px;
}

.blog-details-content .post-share-option .post-social .social-list li {
  position: relative;
  float: left;
  margin-right: 10px;
}

.blog-details-content
  .post-share-option
  .post-social
  .social-list
  li:last-child {
  margin-right: 0px;
}

.blog-details-content .post-share-option .post-social .social-list li a {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 44px;
  line-height: 48px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
}

.blog-details-content .post-share-option .post-social .social-list li a i {
  margin-right: 10px;
}

.blog-details-content .post-share-option .post-social .social-list li a:hover {
}

.blog-details-content .post-share-option {
  position: relative;
  margin-bottom: 79px;
}

.blog-details-content .author-box .inner .share {
  position: relative;
  display: inline-block;
}

.blog-details-content .author-box .inner .share .share-btn {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: #1d165c;
  font-weight: 600;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  z-index: 1;
}

.blog-details-content .author-box .inner .share .share-btn:hover {
  color: #fff;
}

.blog-details-content .author-box .inner .share .social-links {
  position: absolute;
  right: -115px;
  bottom: 10px;
  width: 115px;
  opacity: 0;
  visibility: hidden;
  padding-left: 15px;
  transform: translateX(15px);
  transition: all 500ms ease;
}

.blog-details-content .author-box .inner .share:hover .social-links {
  opacity: 1;
  transform: translateX(0px);
  visibility: visible;
}

.blog-details-content .author-box .inner .share .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.blog-details-content .author-box .inner .share .social-links li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #666;
}

.blog-details-content .author-box {
  position: relative;
  margin-bottom: 77px;
}

.blog-details-content .author-box .author-image {
  position: absolute;
  left: 0px;
  top: 3px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.blog-details-content .author-box .author-image img {
  width: 100%;
  border-radius: 50%;
}

.blog-details-content .author-box .inner {
  position: relative;
  padding-left: 140px;
}

.blog-details-content .author-box .inner .author-info h4 {
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 24px;
}

.blog-details-content .author-box .inner .author-info p a {
  color: #666;
}

.blog-details-content .author-box .inner .author-info p a:hover {
}

.blog-details-content .author-box .inner .author-info {
  position: relative;
  margin-bottom: 15px;
}

.blog-details-content .author-box .inner .text {
  position: relative;
  margin-bottom: 23px;
}

.blog-details-content .group-title {
  position: relative;
  margin-bottom: 25px;
}

.blog-details-content .group-title h3 {
  font-size: 24px;
  font-weight: 600;
}

.blog-details-content .comments-area {
  position: relative;
  margin-bottom: 71px;
}

.blog-details-content .comments-area .comment-box .comment {
  position: relative;
  padding-left: 100px;
  margin-bottom: 40px;
}

.blog-details-content .comments-area .comment-box .comment:last-child {
  margin-bottom: 0px;
}

.blog-details-content .comments-area .comment-box .comment .author-thumb {
  position: absolute;
  left: 0px;
  top: 6px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.blog-details-content .comments-area .comment-box .comment .author-thumb img {
  width: 100%;
  border-radius: 50%;
}

.blog-details-content .comments-area .comment-box .comment .comment-info h4 {
  display: inline-block;
  font-weight: 600;
}

.blog-details-content .comments-area .comment-box .comment .comment-info span {
  position: relative;
  display: inline-block;
  color: #1d165c;
}

.blog-details-content .comments-area .comment-box .comment .comment-info {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.blog-details-content
  .comments-area
  .comment-box
  .comment
  .comment-inner
  .text {
  position: relative;
  margin-bottom: 18px;
}

.blog-details-content
  .comments-area
  .comment-box
  .comment
  .comment-inner
  .replay-btn
  a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  color: #1d165c;
}

.blog-details-content
  .comments-area
  .comment-box
  .comment
  .comment-inner
  .replay-btn
  a:hover {
}

.blog-details-content .comments-form-area .form-group {
  position: relative;
  margin-bottom: 20px;
}

.blog-details-content .comments-form-area .form-group:last-child {
  margin-bottom: 0px;
  margin-top: 10px;
}

.blog-details-content .comments-form-area .form-group input[type='text'],
.blog-details-content .comments-form-area .form-group input[type='email'],
.blog-details-content .comments-form-area .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 54px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  font-style: italic;
  padding: 10px 20px;
  transition: all 500ms ease;
}

.blog-details-content .comments-form-area .form-group textarea {
  height: 140px;
  resize: none;
}

.blog-details-content .comments-form-area .form-group input:focus,
.blog-details-content .comments-form-area .form-group textarea:focus {
}

.blog-details-content .comments-form-area .form-group .theme-btn.style-three {
  padding: 10px 26px 10px 43px;
}

.blog-details-content
  .comments-form-area
  .form-group
  .theme-btn.style-three:after {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 22px;
  left: 27px;
  transition: all 500ms ease;
}

.blog-details-content
  .comments-form-area
  .form-group
  .theme-btn.style-three:hover:after {
  background: #fff;
}

.blog-details-content .comments-form-area .group-title {
  margin-bottom: 31px;
}

.nav-btn-box .box .post-date {
  position: absolute;
  top: 4px;
  width: 54px;
  height: 54px;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0px;
}

.nav-btn-box .btn-left .box .post-date {
  left: 0px;
}

.nav-btn-box .btn-right .box .post-date {
  right: 0px;
}

.nav-btn-box .box .post-date p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
}

.nav-btn-box .box .post-date span {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
}

.nav-btn-box .btn-inner .box .file-box {
  position: relative;
  margin-bottom: 4px;
}

.nav-btn-box .btn-inner .box .file-box i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.nav-btn-box .btn-inner .box .file-box p {
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.nav-btn-box.style-two .btn-inner .prev-btn,
.nav-btn-box.style-two .btn-inner .next-btn {
  margin-bottom: 16px;
}

.nav-btn-box.style-two .btn-inner .btn-left .box {
  padding-left: 75px;
}

.nav-btn-box.style-two .btn-inner .btn-right .box {
  padding-right: 75px;
}

/*** 

====================================================================
                          Error-Page
====================================================================

***/

.error-section {
  position: fixed;
  background: #fff;
  padding: 130px 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.error-section .inner-box .image-box {
  position: relative;
  display: block;
  margin-bottom: 44px;
  padding: 0px 135px;
}

.error-section .inner-box .image-box img {
  width: 100%;
}

.error-section .inner-box h2 {
  position: relative;
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 7px;
}

.error-section .inner-box p {
  font-size: 24px;
  line-height: 35px;
  color: #1d165c;
  margin-bottom: 31px;
}

.error-section .inner-box .theme-btn {
  padding: 9px 35px;
}

.error-section .pattern-box .pattern {
  position: absolute;
  background-repeat: no-repeat;
}

.error-section .pattern-box .pattern-1 {
  left: 0px;
  top: 0px;
  width: 470px;
  height: 300px;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.error-section .pattern-box .pattern-2 {
  left: 0px;
  bottom: 0px;
  width: 450px;
  height: 450px;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.error-section .pattern-box .pattern-3 {
  right: 0px;
  top: 0px;
  width: 468px;
  height: 457px;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.error-section .pattern-box .pattern-4 {
  right: 0px;
  bottom: 0px;
  width: 537px;
  height: 359px;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

/*** 

====================================================================
                          Coming-Soon-Page
====================================================================

***/

.coming-soon {
  position: fixed;
  background: #fff;
  padding: 130px 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.coming-soon .image-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.coming-soon .pattern-layer {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.coming-soon .inner-box {
  position: relative;
  float: right;
  width: 50%;
  height: 100%;
}

.coming-soon .inner-box .content-box {
  position: relative;
  display: block;
  text-align: center;
  padding: 0px 100px;
}

.coming-soon .inner-box .content-box .upper-box .logo-box {
  margin-bottom: 36px;
}

.coming-soon .inner-box .content-box .upper-box h2 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}

.coming-soon .inner-box .content-box .upper-box p {
  color: #fff;
}

.coming-soon .inner-box .content-box .upper-box {
  position: relative;
  margin-bottom: 43px;
}

.coming-soon .timer {
  position: relative;
  text-align: center;
  padding: 31px 0px 33px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 50px;
}

.coming-soon .cs-countdown .count-col {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0px 50px;
}

.coming-soon .cs-countdown .count-col:before {
  position: absolute;
  content: ':';
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  top: 28px;
  right: 0px;
}

.coming-soon .cs-countdown .count-col:last-child:before {
  display: none;
}

.coming-soon .cs-countdown .count-col span {
  position: relative;
  display: block;
  font-size: 60px;
  color: #fff;
  line-height: 60px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 9px;
}

.coming-soon .cs-countdown .count-col h6 {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Muli', sans-serif;
}

.coming-soon .inner-box .content-box .lower-box .subscribe-form .form-group {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

.coming-soon
  .inner-box
  .content-box
  .lower-box
  .subscribe-form
  .form-group
  input[type='email'] {
  position: relative;
  width: calc(100% - 195px);
  height: 54px;
  background: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  float: left;
}

.coming-soon
  .inner-box
  .content-box
  .lower-box
  .subscribe-form
  .form-group
  button {
  position: relative;
  float: right;
  margin-left: 5px;
  padding: 12px 43px;
}

.coming-soon .inner-box .content-box .lower-box .subscribe-form {
  position: relative;
  margin-bottom: 50px;
}

.coming-soon .inner-box .content-box .lower-box .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 8px;
}

.coming-soon .inner-box .content-box .lower-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}

.coming-soon .inner-box .content-box .lower-box .social-links li a:hover {
  background: #fff;
}

/*** 

====================================================================
                          Faq-Page
====================================================================

***/

.faq-page-section {
  position: relative;
  padding: 130px 0px;
}

.faq-page-section .accordion-box .block .acc-btn.active .icon-outer {
}

.faq-page-section .accordion-box .block .acc-btn .icon-outer {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 14px;
  color: #b8b8b8;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
  z-index: 1;
}

.faq-page-section .accordion-box .block .acc-btn.active .icon-outer i:before {
  position: relative;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  margin: 0px;
  content: '\f00d';
}

.faq-page-section .accordion-box .accordion {
  position: relative;
  margin-bottom: 20px;
}

.faq-page-section .accordion-box .accordion:last-child {
  margin-bottom: 0px;
}

.faq-page-section .accordion-box .block .acc-btn h5 {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin: 0px;
  cursor: pointer;
  box-shadow: 0 0px 40px 10px #eaeaef;
  padding: 20px 60px 18px 30px;
  transition: all 500ms ease;
}

.faq-page-section .accordion-box .acc-content {
  padding: 33px 15px 14px 30px;
}

.faq-page-section .accordion-block .title-inner {
  position: relative;
  margin-bottom: 34px;
}

.faq-page-section .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 65px;
  line-height: 65px;
  margin-bottom: 31px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.faq-page-section .inner-box span {
  position: relative;
  display: block;
  font-size: 12px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.faq-page-section .inner-box h3 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin: 0px;
}

.faq-page-section .content-box-one {
  position: relative;
  padding-bottom: 100px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 100px;
}

.team-page-section {
  position: relative;
  padding: 130px 0px 80px 0px;
}

.team-page-section .team-block-one {
  margin-bottom: 50px;
}
