.banner-section {
  padding-top: 20px !important;
}

.serviceImageSize {
  max-height: '434px !Important';
  max-width: '310px !Important';
}

.case-block-two .inner-box .image-box .client-box .client-logo {
  position: relative;
  display: inline-block;
  background: #fff;
  width: 72px;
  height: 72px;
  text-align: center;
  border-radius: 50%;
  padding: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.img .customHeaderRoundImg {
  max-width: 150px !important;
  max-height: 250px !important;
  border-radius: 50% !important;
}
