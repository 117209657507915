
/** image-bg css for Detox **/


.award-section .single-item .icon-box:before{
	background: url(../images/icons/shape-1.png);
}

.award-section .owl-item:nth-child(2n+1) .single-item .icon-box:before{
	background: url(../images/icons/shape-2.png);
}

.award-section .owl-item:nth-child(3n+1) .single-item .icon-box:before{
	background: url(../images/icons/shape-3.png);
}

.award-section .owl-item:nth-child(4n+1) .single-item .icon-box:before{
	background: url(../images/icons/shape-4.png);
}